<template>
  <button @click="$emit('on-button-click')" :style="buttonStyle">
    <slot name="buttonContents" />
  </button>
</template>

<script>
export default {
  name: 'AppUserButton',
  props: {
    buttonStyle: {
      type: Object,
      default: () =>{}
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
