<template>
  <b-modal
    ref="review-preview"
    modal-class="common-modal"
    dialog-class="common-modal-dialog"
    content-class="review-modal-content"
    header-class="review-modal-header"
    body-class="common-modal-body"
    hide-header-close
    hide-footer
    hide-backdrop
    scrollable
    no-fade
  >
    <ReviewPreview
      :userType="userType"
      :review="review"
      @hide-modal-click="hideModal"
      @send-review="$_onSendReview"
    />
    <template #modal-header>
      <Header
        @left-arrow-click="hideModal"
        @logo-click="hideModal"
      />
    </template>
  </b-modal>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import ReviewPreview from '@/components/organisms/user/modals/contents/AppUserReviewPreview'
import { postReview } from '@/helper/firestore/review'
import { showInfoPopupAlert } from '@/helper/common'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserReviewPreviewModal',
  components: {
    Header,
    ReviewPreview
  },
  props: {
    userType: {
      type: String,
      default: ''
    },
    review: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    selectedPlace() {
      return this.$store.getters['place/selectedPlace']
    },
  },
  methods: {
    showModal() {
      this.$refs['review-preview'].show()
    },
    hideModal() {
      this.$refs['review-preview'].hide()
    },
    async $_onSendReview(review) {
      this.$store.dispatch('loadingMask/showLoadingMask')

      try {
        await postReview({
          ...review,
          spaceName: this.selectedPlace.spaceName,
          spaceId: this.selectedPlace.placeId,
        })
        // ローディングマスクを閉じる
        this.$store.dispatch('loadingMask/hideLoadingMask')
        // モーダルをスペース詳細に移行
        this.$store.dispatch('modal/setModalId', 'space-detail')
        this.hideModal()
        // トースト表示(ローディングマスクの非表示より先に表示されるので、タイムアウト設定)
        setTimeout(() => showInfoPopupAlert(
          '',
          false,
          {
            html: `<img src="${require('@/assets/image/icon_action_result.svg')}" /><p style="font-size: 1rem; margin: 48px 0 8px 0;">クチコミ投稿を受付けました。</p><p style="font-size: 1rem;">クチコミの投稿は近日中に反映されます。</p>`,
            customClass: {
              popup: 'review-action-result',
              actions: 'custom-actions'
            }
          }
        ), 1000)
      } catch(error) {
        this.$store.dispatch('loadingMask/hideLoadingMask')
        window.alert(`クチコミ投稿に失敗しました。${error}`)
      }
    }
  }
}
</script>
  
<style lang="scss">
.review-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.review-modal-header {
  flex-direction: column;
  align-items: center;
}
.custom-actions {
  flex-direction: column;
  width: 100% !important;
  button {
    width: 80%;
  }
}
.review-action-result {
  padding: 48px 0 !important;
}
</style>