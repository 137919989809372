<template>
  <div class="app-user-review-guideline">
    <!-- Header -->
    <div class="review-guideline-title">
      <div class="review-guideline-title-text">クチコミ投稿ガイドライン</div>
    </div>

    <!-- Review Guideline -->
    <div class="review-guideline-inner">
      <div class="review-guideline-item">
        いつもPoshspaceをご利用いただき、ありがとうございます。<br />
        みなさまに安心してクチコミ機能を利用していただくために、クチコミ投稿時は次のガイドラインに同意の上ご利用ください。
      </div>

      <div class="review-guideline-item-title-sub">■クチコミ機能の目的について</div>
      <div class="review-guideline-item">
        クチコミ機能は、スペースや出店に関する情報交換を目的としています。クチコミは誰でも自由に投稿・閲覧することが可能ですが、ルールや目的に反するクチコミについては削除される場合があります。クチコミ機能の利用目的をよく理解し、ルールを守って利用してください。
      </div>

      <div class="review-guideline-item-title-sub">■違反報告について</div>
      <div class="review-guideline-item">
        事務局では投稿いただいたクチコミの内容を確認した上、公開します。<br />
        クチコミ機能は、一人ひとりの多様な意見や体験談を尊重しておりますが、違法行為につながる投稿や、公序良俗に反する投稿、差別・人権侵害を助長する投稿などについては、非表示や削除対応を行うことがあります。<br />
        <br />
        クチコミ機能内にある「通報する」ボタンにて利用者からお知らせいただくと、事務局にて1件ずつ確認・対応いたします。違反に当たらないと判断した場合には、非表示・削除などを行わないこともあります。<br />
      </div>

      <div class="review-guideline-item-title-sub">■禁止されているコメントや行為</div>
      <div class="review-guideline-item">
        ・公序良俗に反する投稿<br />
        ・法令に違反する投稿<br />
        ・犯罪または犯罪の助長にあたるコメント（犯罪・自殺予告、犯罪・自殺助長、恐喝、詐欺、フィッシングなど）<br />
        ・スパムなど、記事内容と関係のない投稿<br />
        ・誰かになりすました投稿<br />
        ・個人情報（氏名、住所、電話番号、メールアドレスなど個人の特定が可能な情報）の投稿や、他者のプライバシーを侵害する投稿<br />
        ・特定の宗教や他者の信条、信念を攻撃する投稿<br />
        ・人種、性別、学歴などあらゆる差別に関する投稿<br />
        ・個人、企業、団体などに対する誹謗中傷する投稿<br />
        ・宣伝活動、政治活動などに関わる投稿<br />
        ・一度削除された投稿の再投稿<br />
        ・同一内容、あるいはほぼ同一と判断できるクチコミを重複して投稿する行為<br />
        ・意図が伝わり難い伏せ字や隠語を含む投稿<br />
        ・引用の範囲を越えた他者コンテンツの無断転載など、知的財産権やその他の権利を侵害する投稿<br />
        ・上記内容を含む他サイトへのリンクを含む投稿<br />
        ・その他、事務局が不適切と判断した投稿<br />
        <br />
        ※名誉棄損、信用棄損、犯罪予告、業務妨害罪など、クチコミの内容によっては警察に通報することもございますのでご注意ください。
      </div>

      <div class="review-guideline-item-title-sub">■クチコミの取り扱いについて</div>
      <div class="review-guideline-item">
        クチコミをご投稿いただいた際には、本ガイドラインにご同意いただいたものとみなします。また、クチコミについての著作権や著作者人格権その他の権利をいかなる場合であっても行使しないことにもご同意いただいたものとします。また、投稿されたクチコミは事務局が一部改変・編集し、Poshspaceの記事・販促物として自由に使用できるものとします。
      </div>

      <div class="review-guideline-item mt-5 mb-5">
        ※本ガイドラインは予告なく改定される場合があります。
      </div>

      <div class="review-guideline-item mb-2">
        【2023年1月10日制定】
      </div>

      <!-- <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '32px 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppUserReviewGuideline',
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-review-guideline {
  // control map size
  width: 100%;
  height: 100%;
}

/*
 * Header
 */
.review-guideline-title {
  display: flex;
  justify-content: flex-start;
  padding: 62px 20px 0 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.review-guideline-title-text {
  color: #707070;
  font-size: 20px;
}

/*
 * PoshInfo
 */
.review-guideline-inner {
  padding: 20px;
}
.review-guideline-item {
  font-size: 16px;
  color: #707070;
}
.review-guideline-item-title-sub {
  font-size: 16px;
  color: #707070;
  margin: 48px 0 12px 0;
}
.review-guideline-item {
  font-size: 14px;
  color: #707070;
}
</style>