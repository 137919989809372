<template>
  <div class="app-user-privacy-policy">
    <PrivacyPolicyContents />
  </div>
</template>

<script>
import PrivacyPolicyContents from '@/components/organisms/user/AppUserUserPrivacyPolicyContents'

export default {
  name: 'AppUserUserGuideline',
  components: {
    PrivacyPolicyContents
  },
}
</script>

<style lang="scss" scoped>
.app-user-privacy-policy {
  // control map size
  width: 100%;
  height: 100%;
}
</style>