<template>
  <div>
    <b-modal
      ref="review"
      modal-class="common-modal"
      dialog-class="common-modal-dialog"
      content-class="review-modal-content"
      header-class="review-modal-header"
      body-class="common-modal-body"
      hide-header-close
      hide-footer
      hide-backdrop
      scrollable
      no-fade
    >
      <Review
        @show-preview-click="() => $refs['review-preview'].showModal()"
        @value-change="(value) => $_setValue(value)"
      />
      <template #modal-header>
        <Header
          @left-arrow-click="() => {
            $store.dispatch('modal/setModalId', 'space-detail')
            hideModal()
          }"
          @logo-click="() => {
            $store.dispatch('modal/setModalId', 'map')
            hideModal()
          }"
        />
      </template>
    </b-modal>

    <ReviewPreviewModal
      ref="review-preview"
      :userType="userType"
      :review="review"
    />
  </div>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import Review from '@/components/organisms/user/modals/contents/AppUserReview'
import ReviewPreviewModal from '@/components/organisms/user/modals/AppUserReviewPreviewModal'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserReviewModal',
  components: {
    Header,
    Review,
    ReviewPreviewModal
  },
  data() {
    return {
      userType: '',
      review: ''
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
  },
  methods: {
    $_setValue(value) {
      this.userType = value.userType
      this.review = value.review
    },
    showModal() {
      this.$refs['review'].show()
    },
    hideModal() {
      this.$refs['review'].hide()
    }
  }
}
</script>
  
<style lang="scss">
.review-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.review-modal-header {
  flex-direction: column;
  align-items: center;
}
</style>