<template>
  <div class="app-user-posh-info">
    <!-- Header -->
    <div class="posh-info-title">
      <div class="posh-info-title-text">運営会社</div>
    </div>

    <!-- Poshspace Info -->
    <div class="posh-info-inner">
      <div
        v-for="(item, index) in managementCompany"
        :key="`posh-info-${index}`"
      >
        <div class="posh-info-item-title-sub">{{ item.title }}</div>
        <div class="posh-info-item">
          <p
            style="marginBottom: 0;"
            v-for="(line, index) in item.text"
            :key="`posh-info-item-${index}`"
            class="line"
          >
            {{ line || '&nbsp;' }}
          </p>
        </div>
      </div>
      <div class="posh-info-item-title-sub">連絡先</div>
      <div class="posh-info-item">
        <a
          :style="{  color: '#707070', textDecoration: 'none' }"
          :href="`mailto:${adminEmail}`"
        >
          {{ adminEmail }}
        </a>
      </div>
      <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '32px 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { ADMIN_EMAIL } from '@/constants/common'
import { MANAGEMENT_COMPANY } from '@/constants/user'

export default {
  name: 'AppUserPoshInfo',
  data() {
    return {
      managementCompany: MANAGEMENT_COMPANY,
      adminEmail: ADMIN_EMAIL
    }
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-posh-info {
  // control map size
  width: 100%;
  height: 100%;
}

/*
 * Header
 */
.posh-info-title {
  display: flex;
  justify-content: flex-start;
  padding: 62px 20px 0 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
  .posh-info-title-text {
    color: #707070;
    font-size: 20px;
  }
}

/*
 * PoshInfo
 */
.posh-info-inner {
  padding: 20px;
  .posh-info-item {
    font-size: 16px;
    color: #707070;
    .line { line-height: 1.5rem; }
  }
  .posh-info-item-title-sub {
    font-size: 13px;
    color: #A5A5A5;
    margin: 32px 0 12px 0;
  }
}
</style>