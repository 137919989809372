export default [
  /**
   * Mobile User(出店者側ページ)
   */
  {
    path: '/',
    name: 'map',
    loadPage: 'user/TheUserTop',
    meta: { appDomain: 'user', showHeader: true, showFooter: true }
  },
  {
    path: '/user/login',
    name: 'userLogIn',
    loadPage: 'user/TheLogIn',
    meta: { appDomain: 'user', showHeader: false, showFooter: false }
  },
  {
    path: '/user/signup',
    name: 'userSignUp',
    loadPage: 'user/TheSignUp',
    meta: { appDomain: 'user', showHeader: false, showFooter: false }
  },
  {
    path: '/user/logout',
    name: 'userLogOut',
    loadPage: 'user/TheLogOut',
    meta: { appDomain: 'user', showHeader: false, showFooter: false }
  },
  /* Reset password */
  {
    path: '/user/send_reset_password',
    name: 'userSendResetPassword',
    loadPage: 'user/TheSendResetPassword',
    meta: { appDomain: 'user', showHeader: false, showFooter: false }
  },
  // FirebaseのアクションURLがひとつしか指定できないので、管理側と共有
  // {
  //   path: '/user/reset_password',
  //   name: 'userResetPassword',
  //   loadPage: 'user/TheResetPassword',
  //   meta: { appDomain: 'user', showHeader: false, showFooter: false }
  // },
  /**
   * Pc User(出店者側ページ)
   */
  {
    path: '/pc/map',
    name: 'pcUserMap',
    loadPage: 'user/ThePcUserMap',
    meta: { appDomain: 'user', showHeader: true, showAside: true }
  },
  {
    path: '/login_access_redirect',
    name: 'loginAccessRedirect',
    loadPage: 'user/TheLoginAccessRedirect',
    meta: { appDomain: 'user', showHeader: false, showFooter: false }
  },
  {
    path: '/register_access_redirect',
    name: 'registerAccessRedirect',
    loadPage: 'user/TheRegisterAccessRedirect',
    meta: { appDomain: 'user', showHeader: false, showFooter: false }
  },
]