<template>
  <div class="app-user-others">
    <div v-if="page === 'others'">
      <!-- Header -->
      <div class="other-list-title">
        <IconOthers class="other-list-title-icon"/>
        <div class="other-list-title-text">その他</div>
      </div>

      <div :style="{ margin: '16px 20px 8px 20px' }">
        <WideButton
          v-if="isLoggedIn"
          class="logout-button"
          :label="'ログアウト'"
          @click="$_onClickLogOut"
        />
        <WideButton
          v-else
          class="login-button"
          :label="'ログイン'"
          @click="$_onClickLogIn"
        />
        <p
          v-if="!isLoggedIn"
          class="signup-button"
          @click="$_onClickSignUp"
        >
          新規会員登録（無料）はこちら
        </p>
      </div>

      <!-- Body(Item's label) -->
      <div class="other-body">
        <OthersItemLabel
          v-for="item, index in othersItemMenu"
          :key="`other-card-${index}`"
          :title="item.title"
          :isRedirectLabel="!!item.redirectUrl"
          @card-redirect-click="$_onClickRedirectItem(item.redirectUrl)"
          @card-link-click="() => { page = item.tag }"
        />

        <div
          v-if="showAds"
          :style="{
            width: '100%',
            margin: '32px 0 0 0'
          }"
        >
          <img width="100%" src="@/assets/image/ad_sample.png" />
        </div>
      </div>
    </div>

    <!-- Body(Item's contents) -->
    <div v-else-if="page === 'about-posh'">
      <AboutPosh />
    </div>
    <div v-else-if="page === 'posh-info'">
      <PoshInfo />
    </div>
    <div v-else-if="page === 'location-setting'">
      <LocationSetting />
    </div>
    <div v-else-if="page === 'add-to-homescreen'">
      <AddToHomeScreenDetail />
    </div>
    <div v-else-if="page === 'review-guideline'">
      <ReviewGuideline />
    </div>
    <div v-else-if="page === 'user-guideline'">
      <UserGuideline />
    </div>
    <div v-else-if="page === 'privacy-policy'">
      <PrivacyPolicy />
    </div>
  </div>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import OthersItemLabel from '@/components/organisms/user/AppUserOthersItemLabel.vue'
import AboutPosh from '@/components/organisms/user/modals/contents/AppUserAboutPosh.vue'
import PoshInfo from '@/components/organisms/user/modals/contents/AppUserPoshInfo.vue'
import AddToHomeScreenDetail from '@/components/organisms/user/modals/contents/AppUserAddToHomeScreenDetail'
import LocationSetting from '@/components/organisms/user/modals/contents/AppUserLocationSetting'
import ReviewGuideline from '@/components/organisms/user/modals/contents/AppUserReviewGuideline'
import UserGuideline from '@/components/organisms/user/modals/contents/AppUserUserGuideline'
import PrivacyPolicy from '@/components/organisms/user/modals/contents/AppUserPrivacyPolicy'
import { OTHERS_PAGE_MENU } from '@/constants/user'
import { auth } from '@/firebase/config'
import IconOthers from '@/assets/image/icon_other.svg?component'
import inobounce from 'inobounce'

export default {
  name: 'AppUserOthers',
  components: {
    WideButton,
    IconOthers,
    OthersItemLabel,
    AboutPosh,
    PoshInfo,
    AddToHomeScreenDetail,
    LocationSetting,
    ReviewGuideline,
    UserGuideline,
    PrivacyPolicy
  },
  props: {
    targetPage: {
      type: String,
      default: 'others'
    }
  },
  data() {
    return {
      page: this.targetPage
    }
  },
  watch: {
    targetPage() {
      this.page = this.targetPage
    },
    page() {
      // その他ページとサブモーダルがスクロール位置を共有しているので、トップに戻す
      document.getElementsByClassName('modal-body')[0].scrollTo(0, 0)
      this.$emit('change-page', this.page)
    }
  },
  computed: {
    isLoggedIn() {
      // メールアドレス未認証の場合はログアウト扱いとする
      return auth.currentUser && auth.currentUser.emailVerified
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    othersItemMenu: function () {
      return OTHERS_PAGE_MENU
    },
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()
  },
  methods: {
    $_onClickLogIn() {
      // モーダルをとじる
      this.$store.dispatch('modal/setModalId', 'map')
      this.$router.push('/user/login')
    },
    $_onClickLogOut() {
      // モーダルをとじる
      this.$store.dispatch('modal/setModalId', 'map')
      this.$router.push('/user/logout')
    },
    $_onClickSignUp() {
      // モーダルをとじる
      this.$store.dispatch('modal/setModalId', 'map')
      this.$router.push('/user/signup')
    },
    $_onClickRedirectItem(url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-others {
  width: 100%;
  height: auto;
  padding: 0;
}
.login-button {
  margin-top: 0px;
}
.logout-button {
  margin-top: 0px;
  background-color: white !important;
  color: rgb(18, 182, 212) !important;
  border: 1px solid rgb(18, 182, 212) !important;
}
.signup-button {
  color: rgb(18, 182, 212);
  text-decoration: underline;
  margin: 16px 0 0 0;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.other-list-title {
  display: flex;
  justify-content: flex-start;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.other-list-title-icon {
  margin-top: 12px;
  margin-right: 16px;
}
.other-list-title-text {
  color: #707070;
  font-size: 20px;
}
.other-body {
  padding: 20px
}
#logout_button {
  font-size: 16px;
  margin-top: 32px;
  width: 100%;
  min-height: 24px;
  padding: 1.5vh;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
</style>