import common from './common'
import { SPACE_DOCS_NAME, CHUNK_DOCS_NAME } from '@/constants/firestore'
import { extractChunkData, getAvailableChunkDocId } from '@/helper/firestore/chunk'
import { isEmptyObject } from '@/helper/util'

/**
 * spaceId かちゃんとバリデーションすること
 * Firestoreの処理自体は./common.jsにまとめること
 * このjsのやることは、要求された処理とSpaceドキュメントへの参照、
 *  必要な引数をcommon.jsの処理に渡し、エラーを監視し、完了したら値を返すこと
 *   commonのメソッド呼び出しと、値に対する操作が責務
 */

/**
 * 特定ドキュメント取得
 * @param {String} spaceId 
 * @return {Array.<Object>} スペース配列
 */
const getSpace = async spaceId => {
  try {
    if (!spaceId) throw new Error('Space document ID is not provided.')  
    return await common.get(SPACE_DOCS_NAME, spaceId)
  } catch (e) {
    throw new Error(`getSpace => ${e}`)
  }
}

/**
 * spacesコレクションの全ドキュメント取得
 * @return {Array.<Object>} スペース配列
 */
const getAllSpaces = async () => {
  try {
    return await common.get(SPACE_DOCS_NAME)
  } catch (e) {
    throw new Error(`getAllSpaces => ${e}`)
  }
}

/**
 * ドキュメント更新
 * @param {*} value 更新対象のスペースIDを以下の形式で持つこと
 * { ..., placeId: xxx }
 * @param {*} chunkId ChunkID指定時はバッチ処理でspaces/chunksnの両方更新
 * @returns {Object|Array.<Object>} 更新結果（バッチ処理の場合はそれぞれの処理結果配列）
 */
const putSpace = async (value, chunkId) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')
    if (!value.placeId) throw new Error('Space document ID is not provided.')

    if (chunkId) {
      const chunkValue = extractChunkData(value)
      return await common.batch([
        { // Space
          collectionName: SPACE_DOCS_NAME,
          id: value.placeId,
          condition: { addUpdateDate: true },
          value: value,
          method: 'update'
        },
        { // Chunk
          collectionName: CHUNK_DOCS_NAME,
          id: chunkId,
          condition: { addUpdateDate: { key: value.placeId } },
          value: { [value.placeId]: { ...chunkValue } },
          method: 'update'
        },
      ])
    } else {
      return await common.put(SPACE_DOCS_NAME, value.placeId, value)
    }
  } catch (e) {
    throw new Error(`putSpace => ${e}`)
  }
} 

/**
 * スペース登録処理
 * Chunkデータも同時に作成
 * @param {Object} value 
 * @return {Object} space/chunkそれぞれの処理結果
 */
const postSpace = async (value) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')  

    // Space, Chunkへの単純なPUTなので、バッチ処理じゃなくていい
    const spaceResult = await common.post(SPACE_DOCS_NAME, value)

    // 取得したスペースIDをChunk用に埋め込む
    value.placeId = spaceResult.docId

    // Space形式の値をChunk形式変換
    const chunkValue = extractChunkData(value)

    // 書き込み可能なChunkドキュメントIDを取得（配列が返ってくる）
    const chunkId = await getAvailableChunkDocId()
    const chunkResult = await common.postChunk(
      chunkValue,
      chunkId,
      spaceResult.docId
    )

    return {
      space: spaceResult,
      chunk: chunkResult
    }
  } catch (e) {
    throw new Error(`postSpace => ${e}`)
  }
} 

/**
 * スペース削除処理
 * @param {String} spaceId 
 * @param {String} chunkId 
 * @returns {Object} 成功時オブジェクト({ statue: 'success' })
 */
const deleteSpace = async (spaceId, chunkId)  => {
  try {
    if (!spaceId) throw new Error('Space document ID is not provided.')  

    if (chunkId) {
      return await common.delete(SPACE_DOCS_NAME, spaceId, chunkId)
    } else {
      return await common.delete(SPACE_DOCS_NAME, spaceId)
    }
  } catch (e) {
    throw new Error(`deleteSpace => ${e}`)
  }
}

export {
  getSpace,
  getAllSpaces,
  putSpace,
  postSpace,
  deleteSpace,
}