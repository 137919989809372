<template>
  <div class="app-user-privacy-policy-contents">
    <!-- Header -->
    <div class="privacy-policy-title">
      <div class="privacy-policy-title-text">プライバシーポリシー</div>
    </div>

    <!-- Privacy Policy -->
    <div class="privacy-policy-inner">
      <div
        v-for="(item, index) in userPrivacyPolicy"
        :key="`${item.page}-privacy-policy-${index}`"
      >
        <div v-if="currentPage === item.page" class="mb-5">
          <div
            v-if="item.page === 1"
            class="privacy-policy-item mt-4"
          >
            株式会社ティーピーズ（以下「当社」といいます。）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
          </div>

          <div
            v-for="(content, index) in item.contents"
            :key="`${item.page}-privacy-policy-item-wrapper-${index}`"
          >
            <div class="privacy-policy-item-title-sub">{{ content.title }}</div>
            <div class="privacy-policy-item">
              <p
                style="marginBottom: 0;"
                v-for="(line, index) in content.text"
                :key="`${item.page}-privacy-policy-item-${index}`"
              >
                {{ line || '&nbsp;' }}
              </p>
            </div>
          </div>
          <div v-if="item.page === maxPage">
            <div class="privacy-policy-item mt-5 mb-5">
              {{ fixDateText }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 page-button-wrapper">
      <b-button
        :class="{ 'hide-button': isFirstPage }"
        variant="outline-secondary"
        @click="currentPage = Math.max(1, --currentPage)"
      >
        <b-icon icon="chevron-left" aria-label="Help"></b-icon>
      </b-button>
      {{ currentPage }} / {{ maxPage }}
      <b-button
        :class="{ 'hide-button': isLastPage }"
        variant="outline-secondary"
        @click="currentPage = Math.min(maxPage, ++currentPage)"
      >
        <b-icon icon="chevron-right" aria-label="Help"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import { USER_PRIVACY_POLICY, USER_PRIVACY_POLICY_FIXED_DATE } from '@/constants/user'

const MAX_PAGE = 3

export default {
  name: 'AppUserUserPrivacyPolicyContents',
  data() {
    return {
      currentPage: 1,
      maxPage: MAX_PAGE,
      userPrivacyPolicy: USER_PRIVACY_POLICY,
      fixDateText: USER_PRIVACY_POLICY_FIXED_DATE,
    }
  },
  watch: {
    currentPage() {
      // スクロールを戻す
      document.getElementsByClassName('modal-body')[0].scrollTo(0, 0)
    },
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    isLastPage() {
      return this.currentPage === this.maxPage
    },
    isFirstPage() {
      return this.currentPage === 1
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-privacy-policy-contents {
  // control map size
  width: 100%;
  height: 100%;
  /*
  * Header
  */
  .privacy-policy-title {
    display: flex;
    justify-content: flex-start;
    padding: 62px 20px 0 20px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
  }
  .privacy-policy-title-text {
    color: #707070;
    font-size: 20px;
  }

  /*
  * Body
  */
  .privacy-policy-inner {
    padding: 0 20px 20px 20px;
  }
  .privacy-policy-item-title-sub {
    font-size: 16px;
    color: #707070;
    margin: 48px 0 12px 0;
  }
  .privacy-policy-item {
    font-size: 14px;
    color: #707070;
  }
  .page-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    font-size: 16px;
    button {
      width: 100px;
    }
  }
  .hide-button {
    visibility: hidden;
  }
}
</style>