<template>
  <!-- PC操作の際はclickイベントが必要だが、開発時にブラウザでモバイル環境をエミュレートしているとlike-button-clickedが2回発生する
    <div
    class="app-user-like-button"
    :style="{  width: width, height: height }"
    v-on:click.stop="$emit('like-button-clicked')"
    v-on:touchend.stop="$emit('like-button-clicked')"
  > -->
  <div
    class="app-user-like-button"
    :style="{  width: width, height: height }"
    v-on:touchend.stop="$emit('like-button-clicked')"
  >
    <LoadingHeart v-if="isProcessing" />
    <b-icon
      v-else-if="liked"
      v-bind:icon="'heart-fill'"
      aria-hidden="true" dark
    />
    <IconEmptyHeart v-else />
  </div>
</template>

<script>
import IconEmptyHeart from "@/assets/image/Icon_empty_heart.svg?component"
import LoadingHeart from "@/assets/image/icon_heart_loading.svg?component"

export default {
  name: 'AppUserLikeButton',
  components: {
    IconEmptyHeart,
    LoadingHeart
  },
  props: {
    liked: {
      type: Boolean,
      default: false
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '34px' 
    },
    height: {
      type: String,
      default: '34px' 
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-like-button {
  border-radius: 50%;
  background-color: white;
  color: #0097b5;
  border: 1px solid #0097b5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>