<template>
  <div class="app-user-introduction">
    <carousel
      :paginationEnabled="false"
      :perPage="1"
      :navigateTo="sliderIndex"
      @page-change="$_onPageChange"
    >
      <slide>
        <div class="slider-inner" :style="{paddingTop: '2rem'}">
          <IconInitialSlide1 />
          <p class="introduction-title">ようこそPoshspaceへ</p>
          <p class="introduction-description">
            Poshspaceは移動販売事業者を応援しています。<br />
            移動販売車両の出店スペースや<br />
            イベント出店に特化した情報を<br />
            掲載しています。
          </p>
        </div>
      </slide>
      <slide>
        <div class="slider-inner" :style="{paddingTop: '2rem'}">
          <IconInitialSlide2 />
          <p class="introduction-title">
            お気に入りスペースや<br />
            イベントを探そう
          </p>
          <p class="introduction-description">
            まずはマップからスペースやイベントを<br />
            探してみましょう。<br />
            お気に入りボタンで<br />
            ブックマークしておくことができます。
          </p>
        </div>
      </slide>
      <slide>
        <div class="slider-inner" :style="{ position: 'relative'}">
          <img width="85%" src="@/assets/image/introduction/introduction_popup_3.png" />
          <p class="introduction-title">
            位置情報オンでもっと便利に
          </p>
          <p class="introduction-description">
            マップ画面で右下の
            <img
              src="@/assets/image/introduction/introduction_popup_3_location_button.png"
              :style="{ width: '1.5rem', verticalAlign: 'middle' }"
            />
            ボタンから<br />
            位置情報をオンにすると、<br />
            あなたの近くのスペースや<br />
            イベントが探しやすくなります。
          </p>
        </div>
      </slide>
      <slide>
        <div class="slider-inner" :style="{ position: 'relative'}">
          <img width="85%" src="@/assets/image/introduction/Iinitial-popup-4@2x.png" />
          <p class="introduction-title">
            クチコミを投稿しよう！
          </p>
          <p class="introduction-description">
            スペースページでは<br />
            クチコミを閲覧・投稿することができます！<br />
            ぜひリアルな声をお寄せください。

          </p>
        </div>
      </slide>
      <slide>
        <div class="slider-inner" :style="{ position: 'relative'}">
          <img width="85%" src="@/assets/image/introduction/1st-popup-5.png" />
          <p class="introduction-title">
            スペース・イベント情報募集中！
          </p>
          <p class="introduction-description">
            マップ画面で右下の
            <img
              :src="require('@/assets/image/introduction/add-place-button.svg')"
              :style="{ width: '1.5rem', verticalAlign: 'middle' }"
            />
            ボタンから<br />
            投稿モードをオンにすると<br />
            スペースやイベント情報を投稿できます。<br />
            みなさまの投稿お待ちしています！
          </p>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import IconInitialSlide1 from '@/assets/image/introduction/icon_init_slide_1.svg?component'
import IconInitialSlide2 from '@/assets/image/introduction/icon_init_slide_2.svg?component'

export default {
  name: 'AppUserIntroduction',
  components: {
    IconInitialSlide1,
    IconInitialSlide2
  },
  data() {
    return {
      sliderIndex: -1,
    }
  },
  props: {
    targetCarouselIndex: {
      type: Number,
      defalut: 0
    }
  },
  watch: {
    targetCarouselIndex() {
      this.sliderIndex = this.targetCarouselIndex
    }
  },
  mounted: async function() {
    // レンダリング後にsliderIndexを変更してリアクティブ処理しないとホワイトアウトする
    await this.$nextTick()
    this.sliderIndex = this.targetCarouselIndex
  },
  methods: {
    $_onPageChange: function (index) {
      this.$emit('carousel-change', index)
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-introduction {
  text-align: center;
  .introduction-title {
    margin: 2rem 0 1rem 0;
    font-size: 18px;
    color: #707070;
    font-weight: bold;
  }
  .introduction-description {
    line-height: 2;
    font-size: 14px;
    color: #707070;
  }
}
</style>