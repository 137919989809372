var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-review"},[_c('div',{staticClass:"review-list-title"},[_c('IconReview',{staticClass:"review-list-title-icon"}),_c('div',{staticClass:"review-list-title-text"},[_vm._v("クチコミ投稿 内容確認")])],1),_c('div',{staticClass:"review-body"},[_c('div',{staticClass:"sub-title"},[_vm._v("あなたの属性")]),_c('p',{staticClass:"user-type"},[_vm._v(_vm._s(_vm.userType))]),_c('div',{staticClass:"sub-title mt-4 mb-2"},[_vm._v("クチコミ内容")]),_c('CommonTextarea',{attrs:{"prefix":'user-review-preview',"maxlength":'140',"readonly":true,"remarks":_vm.review,"shown":_vm.shown,"userStyle":{
        fontSize: '16px',
        color: '#A5A5A5',
        padding: '0 16px 16px 16px'
      }}}),_c('div',{staticClass:"pt-3"},[_c('button',{attrs:{"id":"submit-button"},on:{"click":_vm.$_saveReview}},[_vm._v(" 上記の内容で送信する ")]),_c('button',{staticClass:"mb-3",attrs:{"id":"back-button"},on:{"click":function($event){return _vm.$emit('hide-modal-click')}}},[_vm._v(" 編集にもどる ")])]),(_vm.showAds)?_c('div',{style:({
        width: '100%',
        margin: '32px 0 0 0'
      })},[_c('img',{attrs:{"width":"100%","src":require("@/assets/image/ad_sample.png")}})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }