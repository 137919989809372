<template>
  <div
    class="chip-container"
    :style="{
      color: turnOn ? color : bkColor,
      backgroundColor: turnOn ? bkColor : color,
      border: `solid 1px ${borderColor}`,
    }"
    @click="onClick"
  >
    <p class="chip-title">{{ title }}</p>
    <div
      class="chip-arrow"
      v-if="withArrow && (isArrowUp || isArrowDown)"
    >
      <IconArrowUp v-if="isArrowUp" :fill="color" />
      <IconArrowDown v-else-if="isArrowDown"  :fill="color" />
    </div>
  </div>
</template>

<script>
import IconArrowUp from '@/assets/image/icon_filter_up.svg?component'
import IconArrowDown from '@/assets/image/icon_filter_down.svg?component'

export default {
  name: 'AppUserStateChip',
  components: {
    IconArrowUp,
    IconArrowDown
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#000'
    },
    bkColor: {
      type: String,
      default: '#fff'
    },
    borderColor: {
      type: String,
      default: '#000'
    },
    withArrow: {
      type: Boolean,
      default: false
    },
    turnOn: {
      type: Boolean,
      default: true
    },
    sortDirection: {
      type: String,
      defatul: ''
    },
    onClick: {
      type: Function,
      default: () => {}
    },
  },
  computed: {
    isArrowUp: function () {
      return this.sortDirection === 'up'
    },
    isArrowDown: function () {
      return this.sortDirection === 'down'
    }
  }
}
</script>

<style lang="scss" scoped>
.chip-container {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  margin: 0 0.5rem;
  border-radius: 24px;
  .chip-title {
    margin-bottom: 0;
    line-height: 1.75;
  }
  .chip-arrow {
    margin-left: 8px;
  }
}
</style>