var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"others",attrs:{"modal-class":"common-modal","dialog-class":"common-modal-dialog","content-class":"others-modal-content","header-class":"others-modal-header","body-class":"common-modal-body","hide-header-close":"","hide-footer":"","hide-backdrop":"","scrollable":"","no-fade":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('Header',{on:{"left-arrow-click":() => {
        if (_vm.modalHistory.length && _vm.modalHistory[0] === 'review') {
          // review(space detail) -> review guide line -> review(space detail)
          const history = [..._vm.modalHistory]
          _vm.$store.dispatch('modal/setModalId', history.shift()) 
          _vm.$store.dispatch('modal/replaceModalHistory', history)
          _vm.hideModal()
        } else {
          _vm.$emit('others-left-arrow-clicke')
        }
      },"logo-click":() => { _vm.$emit('others-logo-click', 'map') }}})]},proxy:true}])},[_c('Others',{attrs:{"targetPage":_vm.othersPage},on:{"change-page":(page) => { _vm.$emit('others-page-change', page) }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }