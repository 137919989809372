<template>
  <div class="image-box-container" @click="$_onImageClick">
    <img
      v-lazy="src"
      :width="imageWidth"
      :height="imageHeight"
      @load="$_onImageLoadEnd"
    />
  </div>
</template>

<script>
export default {
  name: 'AppUserImageBox',
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      loading: true,
      imageWidth: this.width,
      imageHeight: this.height
    }
  },
  methods: {
    $_onImageLoadEnd() {
      this.loading = false
    },
    $_onImageClick() {
      this.$emit('click-image')
    }
  },
}
</script>

<style lang="scss" scoped>
.image-box-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-basis: 25%;
}
.loading-mask {
  height: 60%;
  padding: 0 3rem; 
}
</style>