<template>
  <div class="app-user-user-guideline">
    <GuidelineContents />
  </div>
</template>

<script>
import GuidelineContents from '@/components/organisms/user/AppUserUserGuidelineContents'

export default {
  name: 'AppUserUserGuideline',
  components: {
    GuidelineContents
  },
}
</script>

<style lang="scss" scoped>
.app-user-user-guideline {
  // control map size
  width: 100%;
  height: 100%;
}
</style>