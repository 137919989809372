<template>
  <div class="app">
    <router-view exact />
    <ModalManager v-if="setUserModalManager" />
  </div>
</template>

<script>
// User
import ModalManager from '@/components/organisms/user/AppUserModalManager'
import isMobile from 'ismobilejs'
import common from '@/helper/firestore/common'
import localForage from 'localforage'
// import initialState from '@/store/initialState.js'

export default {
  name: 'App',
  components: {
    ModalManager,
  },
  data() {
    return {
      appDomain: '' ,
      showHeader: false,
      showAside: false,
      storedBookmarkedPlaceIdsFlg: false,
    }
  },
  computed: {
    setUserModalManager() {
      return isMobile().phone && this.appDomain === 'user'
    },
    bookmarkedPlaceIds() {
      return this.$store.getters.bookmarkedPlaceIds || []
    }
  },
  watch: {
    $route: async function(to, from) {
      this.appDomain = this.$router.history.current.meta.appDomain
      this.showHeader = this.$router.history.current.meta.showHeader
      this.showAside = this.$router.history.current.meta.showAside
    },
    bookmarkedPlaceIds: function() {
      // bookmarkedPlaceIdsのロード完了時（store初期化完了時）に
      // この処理が走り、それ以降はbookmarkedPlaceIdsの更新は発生しない
      // (ログインしていないユーザーはお気に入り追加/削除できない and ログイン後は
      // リモートのusersコレクションに書き込まれるため)
      this.storedBookmarkedPlaceIdsFlg = true
    },
    async storedBookmarkedPlaceIdsFlg() {
      // アカウント作成前のお気に入り情報は移行処理で扱うので残す
      // → storeリセットで削除されないよう、localStoragenに逃がす
      if (this.bookmarkedPlaceIds.length) {
        localStorage.setItem(
          'bookmarkedPlaceIds',
          [...this.bookmarkedPlaceIds]
        )
      }

      try {
        this.$store.dispatch('loadingMask/showLoadingMask')
        // バージョンチェックとlocalForageリフレッシュ
        await this.$_refrashStore()
      } catch(e) {
        console.error(e)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    }
  },
  mounted: async function () {
    // ここで以下関数内でstoreにアクセスしても初期化前の状態になるので
    // store内の値をwatchし、変更したタイミングでリフレッシュ処理を行う
    // バージョンチェックとlocalForageリフレッシュ
    // await this.$_refrashStore()
  },
  methods: {
    async $_refrashStore() {
      /**
       * storeをリセットする必要があるかバージョンで判定
       * null or 古い場合はstoreを全てリセットしてページをリロード
       * 古いstore構造が残っている場合があるので、VuexのlocalForage事態も
       * 再構築するするので、appVersion自体はリセットせずに次回同様の確認を
       * する用にlocalStorageに保存（VuexのlocalForage）とは別も場所に保存
       */
      const localAppVersion = localStorage.getItem('appVersion')
      const currentAppVersion = await common.getAppVersion()

      if (!localAppVersion || localAppVersion !== currentAppVersion) {
        // storeが古い構造の場合があるので,storeの値が保存されているlocalForegeをクリア
        await localForage.clear()
        this.$store.dispatch('reset')

        // localStorageのバージョンを更新
        localStorage.setItem('appVersion', currentAppVersion)

        // キャッシュをクリア後にリロード
        // Vue LifeCycle Hookを使おうとしたが、コンポーネントの親子間での実行順序を勘違いしていた
        // (子コンポーネントの created, mounted などが親より先に実行される)
        // ↓だとキャッシュが残ってストアが復元されてしまう（リセットされない）
        // window.location.reload(true)
        // query string がある場合、reloadすると消えてしまうので、hrefで再読み込みしつつ
        // query string を復元する
        if (this.$route.query.placeType) {
          window.location.href = window.location.pathname + '?' + `placeType=${this.$route.query.placeType}`
        } else if (this.$route.query.openModalId) {
          window.location.href = window.location.pathname + '?' + `openModalId=${this.$route.query.openModalId}`
        } else {
          window.location.reload()
        }
      }
    }
  }
}
</script>

<style lang="scss">
/**
 * Shop (SP)
 */
.shop-app {
  /* font manager */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // disable double tap zoom
  touch-action: manipulation;

  /* responsive for full height */
  height: 100vh; /* Fallback */
  height: calc(var(--vh, 1vh) * 100);
}


.header-wrapper {
  background-color: white;
  height: 72px;
  flex-shrink: 0;
}

.footer-wrapper {
  background-color: white;
  height: 62px;
  flex-shrink: 0;
}

svg {
  transform:translate(0,0);
}

/**
 * Admin (PC)
 */
.admin-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
/* 横幅992px以下 */
.admin-wrapper {
  height: calc(100vh - 56px);
  margin-top: 56px;
}

/* 横幅992px以上 */
@media (min-width: 992px) {
  /* メニューボタン非表示 */
  #menubtn,
  .b-sidebar-backdrop {
    display: none;
  }

  /* Sidebar常時表示 */
  .b-sidebar {
    display: block !important;
  }

  /* Sidebarの幅を確保 */
  .admin-wrapper {
    margin-left: 250px;
    width: calc(100% - 250px);
    height: calc(100vh - 56px);
    margin-top: 56px;
  }
}

// No result toast at keyword search
.display-flex {
  display: flex !important;
  border-radius: 16px;
}

.container-offset {
  top: 20% !important;
}
</style>
