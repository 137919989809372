// firebase v9 way
// node_modulesからコアとなるfirebaseのパッケージをimportする、firebaseを使うなら必ずimportする必要がある
import { initializeApp } from 'firebase/app' 
import {
  collection, doc, addDoc,
  deleteDoc, getDoc, setDoc,
  updateDoc, deleteField, serverTimestamp,
  arrayUnion, arrayRemove, increment,
  getDocs, getFirestore, query, where,
  orderBy, startAt, endAt,
  limit, documentId,
  writeBatch, runTransaction
} from "firebase/firestore/lite";
import {
  getAuth, onAuthStateChanged,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  sendEmailVerification,
  isSignInWithEmailLink,
  signInWithEmailLink, signOut,
  setPersistence, browserLocalPersistence,
  updateEmail, updatePassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode
} from 'firebase/auth'
import {
  getStorage, ref, getDownloadURL,
  uploadBytes, uploadBytesResumable,
  deleteObject
} from "firebase/storage";
import {
  getFunctions,
  httpsCallable
} from 'firebase/functions'

// TODO use .env
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL:
  process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASREMENT_ID,
}

// init firebase
const firebaseApp = initializeApp(firebaseConfig) // バックエンドのfirebaseを初期化する

// init firestore service
const projectFunctions = getFunctions(firebaseApp, 'asia-northeast1')

const auth = getAuth()
const db = getFirestore(firebaseApp)

// keep session in current tab
// setPersistence(auth, browserLocalPersistence)

// Get a reference to the storage service, which is used to create references in
// your storage bucket
const storage = getStorage()

// set language
auth.languageCode = 'ja'

export {
  projectFunctions, httpsCallable, auth,
  onAuthStateChanged, createUserWithEmailAndPassword,
  signInWithEmailAndPassword, EmailAuthProvider,
  reauthenticateWithCredential, updatePassword,
  browserLocalPersistence, signInWithEmailLink,
  sendSignInLinkToEmail, sendPasswordResetEmail,
  sendEmailVerification, isSignInWithEmailLink,
  signOut, storage, uploadBytes, deleteObject,
  uploadBytesResumable, ref, getDownloadURL,
  setPersistence, updateEmail, db,
  collection, doc, addDoc, getDoc,
  setDoc, deleteDoc, updateDoc, deleteField,
  serverTimestamp, arrayUnion, arrayRemove,
  increment, getDocs, query, where, orderBy,
  startAt, endAt, limit, documentId,
  verifyPasswordResetCode,
  confirmPasswordReset, getAuth,
  writeBatch, runTransaction, applyActionCode
}
