<template>
  <div class="app-user-list">
    <div class="scroll-wrapper">
      <div id="scroll-anchor" />

      <div
        v-for="item, index in itemsPerPage"
        :key="`list-item-${item.placeId}`"
        id="list-scroll-container"
      >
        <CarouselCard
          v-bind:placeId="item.placeId"
          v-bind:imageHeight="100"
          v-bind:imageWidth="136"
          v-bind:width="'100%'"
          v-bind:direction="'column'"
          v-on:card-click="$_onCardClick"
        />
        <div v-if="index > 0 && !((index + 1) % 3)">
          <!-- AD -->
          <div
            v-if="showAds"
            :style="{
              width: '100%',
              margin: '16px 0'
            }"
          >
            <img width="100%" src="@/assets/image/ad_sample.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- 登録が0〜2件の場合も広告は1個表示 -->
    <div v-if="items.length < 3">
      <!-- AD -->
      <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '16px 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>

    <b-pagination
      v-if="items.length"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
import CarouselCard from '@/components/organisms/user/AppUserCarouselCard.vue'
import { getDistanceFromCurrentLocation } from '@/helper/user'
import { getFilterCallBacks } from '@/helper/place'
import { showInfoPopupAlert, showTopPopup } from '@/helper/common'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

// 一ページ毎の表示件数
const PER_PAGE_ITEM_NUM = 12

export default {
  name: 'AppUserList',
  components: {
    CarouselCard,
  },
  data() {
    return {
      items: [],
      itemsPerPage: [],
      conditionalPlaces: [],
      perPage: PER_PAGE_ITEM_NUM,
      currentPage: 0,
    }
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    ...mapGetters('modal', [ 'modalId' ]),
    ...mapGetters(
      'map',
      [
        'mapCenter',
        'currentLocation'
      ]
    ),
    ...mapGetters(
      'place',
      [
        'validSpaces',
        'validEvents',
      ]
    ),
    ...mapGetters(
      'filter',
      [
        'hasCondition',
        'conditions',
        'prevConditions'
      ]
    ),
    places() {
      return this.$store.getters['place/validChunk']
    },
    searchKeyword: function () {
      return this.$store.getters.searchKeyword
    }, 
    showSpace: function () {
      return this.$store.getters.showSpace
    },
    showEvent: function () {
      return this.$store.getters.showEvent
    }, 
    currentListPage: function () {
      return this.$store.getters.currentListPage
    },
    rows: function () {
      return this.items ? this.items.length : 0
    },
    distanceFilterDirection: function () {
      return this.$store.getters.distanceFilterDirection
    },
    likeFilterDirection: function () {
      return this.$store.getters.likeFilterDirection
    },
    displayPlaces() {
      return this.hasCondition ? [...this.conditionalPlaces] : [...this.targetTypePlaces]
    },
    targetTypePlaces() {
      if (this.conditions.showSpace && !this.conditions.showEvent) {
        // only space
        return [...this.validSpaces]
      } else if (this.conditions.showEvent && !this.conditions.showSpace) {
        // only event
        return [...this.validEvents]
      } else if (this.conditions.showSpace && this.conditions.showEvent) {
        // both
        return [
          ...this.validSpaces,
          ...this.validEvents
        ]
      } else {
        // none
        return []
      }
    },
    isTypeChanged() {
      return (
        this.conditions.showSpace !== this.prevConditions.showSpace ||
        this.conditions.showEvent !== this.prevConditions.showEvent
      )
    },
  },
  watch: {
    conditions: {
      handler() {
        this.$_filterPlaceByConditions()
      },
      deep: true
    },
    conditionalPlaces() {
      this.$_updateItems()
    },
    distanceFilterDirection() {
      this.$_updateItems()
    },
    likeFilterDirection() {
      this.$_updateItems()
    },
    currentPage() {
      this.$_setItemsPerPage()
      this.$store.dispatch('set_current_list_page', this.currentPage)
      // スクロールを戻す
      document.getElementsByClassName('modal-body')[0].scrollTo(0, 0)
    },
  },
  created() {
    this.$_filterPlaceByConditions()
  },
  mounted: function () {
    // Enable pull down bounce
    inobounce.disable()

    // Set card list
    this.$_setItems()

    // Restore page
    this.currentPage = this.currentListPage
  },
  methods: {
    async $_onCardClick(target) {
      this.$store.dispatch('place/setSelectedPlace', target.placeId)
      // modalID更新前に履歴保存
      this.$store.dispatch('modal/setModalHistory', this.modalId)
      this.$store.dispatch('modal/setModalId', `${target.type}-detail`)
    },
    $_setItems() {
      // sort(破壊的操作) -> listIndexの振りなおし
      this.items = [...this.displayPlaces]
        .sort(this.$_distanceSort)
        .sort(this.$_likesSort)
        .map((state, index) => ({
          ...state,
          listIndex: index
        }))
    },
    $_setItemsPerPage() {
      // pagenation のサイズに切り取り
      const begin = (this.currentPage - 1) * this.perPage
      this.itemsPerPage = this.items.filter(b => (
        b.listIndex >= begin &&
        b.listIndex < begin + this.perPage
      ))
    },
    async $_updateItems() {
      this.$_setItems()
      this.$_setItemsPerPage()
    },
    $_distanceSort(a, b) {
      let from
      if (this.distanceFilterDirection !== 'off' && this.currentLocation) {
        from = this.currentLocation
      } else {
        // 中間状態なので、マップの中心から近い順にソート
        from = this.mapCenter
      }
      const distA = getDistanceFromCurrentLocation(from, a)
      const distB = getDistanceFromCurrentLocation(from, b)

      if (this.distanceFilterDirection === 'down') {
        return distA - distB
      } else if (this.distanceFilterDirection === 'up') {
        return distB - distA
      } else {
        // default
        return distA - distB
      }
    },
    $_likesSort(a, b) {
      if (this.likeFilterDirection === 'down') {
        return (a.likes || 0) - (b.likes || 0)
      } else if (this.likeFilterDirection === 'up') {
        return (b.likes || 0) - (a.likes || 0)
      } else {
        return 0
      }
    },
    $_filterPlaceByConditions: function () {
      try {
        let newPlaces
        // 各プレイス毎にタイプ判定するのは冗長なので対象タイプのみフィルタにかける
        if (!this.targetTypePlaces.length) {
          // 対象タイププレイスなし -> プレイスなし
          newPlaces = []
        } else {
          const callbacks = getFilterCallBacks(this.conditions)
          newPlaces = [...this.targetTypePlaces]
          for (let i = 0; i < callbacks.length; i++) {
            newPlaces = newPlaces.filter(callbacks[i])
          }
        }

        if (!newPlaces.length) {
          showTopPopup('<p style="font-size: 12px; margin-bottom: 0;">検索したキーワードに<br />一致する情報が見つかりませんでした</p>')
        }

        // 距離順にソートして設定
        this.conditionalPlaces = newPlaces
      } catch (error) {
        showInfoPopupAlert(
          `スペース/イベントの読み込みに失敗しました。<br /><br />${error.message || ''}`,
        )
        return
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-list {
  width: 100%;
  height: 100%;
}
/* overwrite pagenation style */
::v-deep(.page-item) {
  margin: 0px 6px;
}
::v-deep(.page-link) {
  color: #12B6D4 !important;
  border: solid 1px #12B6D4;
  background-color: #fff !important;
}
::v-deep(.active) {
  .page-link {
    color: #fff !important;
    border: solid 1px #12B6D4 !important;
    background-color: #12B6D4 !important;
  }
}
::v-deep(.disabled) {
  display: none;
}
</style>