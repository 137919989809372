<template>
  <div class="app-user-news">
    <!-- Header -->
    <div class="news-title">
      <div class="news-title-text">お知らせ</div>
    </div>

    <!-- Body -->
    <div class="news-body">
      <!-- Search bar -->
      <div class="news-search">
        <input
          type="text"
          placeholder="検索"
          v-model="keyword"
        />
        <button class="news-search-button">
          <IconSearch />
        </button> 
      </div> 

      <!-- News card -->
      <NewsCard
        v-for="n in displayNews"
        :key="`news-${n.docId}`"
        :newsId="n.docId"
        :title="n.title"
        :date="n.publishDate"
        :contents="n.text"
      />
      <b-button
        class="show-past-news-button"
        v-if="showAdditionalNewsButton"
        v-on:click="$_onClickShowPastNews"
      >
        もっと前のお知らせを見る
      </b-button>
      <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '32px 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/organisms/user/AppUserNewsCard"
import IconSearch from '@/assets/image/icon_search.svg?component'
import inobounce from 'inobounce'

const INITIAL_LIMIT = 30

export default {
  name: 'AppUserNews',
  components: {
    NewsCard,
    IconSearch
  },
  data() {
    return {
      keyword: '',
      currentLimit: INITIAL_LIMIT 
    }
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    news: function () {
      const news = [...this.$store.getters['news/userNews']]
      // sort by publish date
      return news.sort((a, b) => {
        if (!a.publishDate || !b.publishDate) return 0
        const dateA = new Date(a.publishDate)
        const dateB = new Date(b.publishDate)
        if( dateA.getTime() > dateB.getTime() ) return -1
        if( dateA.getTime() < dateB.getTime() ) return 1
        return 0
      })
    },
    showAdditionalNewsButton() {
      // 有効なお知らせから合計数を計算
      const validNews = this.keyword
        ? this.news.filter(n => (
          n.title.includes(this.keyword) ||
          n.text.includes(this.keyword)
        ))
        : this.news
      return validNews.length >= this.currentLimit
    },
    displayNews: function () {
      // filtering by keyword
      const newsData = this.keyword
        ? this.news.filter(n => (
          n.title.includes(this.keyword) ||
          n.text.includes(this.keyword)
        ))
        : this.news
      // slice by limit
      return newsData.slice(
        0,
        Math.min(newsData.length, this.currentLimit)
      )
    },
  },
  mounted: function(){
    // enable pull down bounce
    inobounce.disable()
  },
  methods: {
    /**
     * もっと前のお知らせを見るボタン押下
     */    
    $_onClickShowPastNews: function () {
      this.currentLimit += INITIAL_LIMIT
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-news {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/*
 * Header
 */
.news-title {
  display: flex;
  justify-content: flex-start;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.news-title-text {
  color: #707070;
  font-size: 20px;
}

/*
 * News
 */
.news-body {
  background-color: #F5F5F5;
  padding: 16px 20px;
}
.news-search {
  position: relative;
  margin-bottom: 24px;
  .news-search-button {
    position: absolute;
    top: 48%;
    left: 90%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: none
  }
  input {
    width: 100%;
    height: 42px;
    border: solid 1px #C6C6C6;
    border-radius: 8px;
    padding-left: 16px;
    &::placeholder {
      font-size: 14px;
      color: #A0A0A0;
    }
  } 
}
.show-past-news-button {
  font-size: 14px;
  background-color: #707070;
  border-radius: 6px;
  margin-top: 32px;
  height: 46px;
  border: none;
  width: 100%;
}
</style>