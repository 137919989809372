<template>
  <b-modal
    ref="introduction"
    centered 
    :footer-class="'introduction-modal-footer'"
    :body-class="'common-modal-body'"
    :content-class="'introduction-modal-content'"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    no-fade
    @close="() => $store.dispatch('modal/setModalId', 'map')"
  >
    <Introduction
      :targetCarouselIndex="targetCarousel"
      @carousel-change="$_onCarouselChange"
    />
    <template v-slot:modal-footer>
      <button
        id="submit-button"
        @click="$_onClickNextSlide"
      >
        {{ initialSlideButtonLabel }}
      </button>
      <div
        v-for="(n, i) in totalSlideCount"
        :class="i === sliderIndex ? 'slider-dot-active' : 'slider-dot'"
        :key="`slider-dots-${i}`"
      >
        {{ i === sliderIndex ? '●' : '●' }}
      </div>
    </template>
  </b-modal>
</template>
  
<script>
import Introduction from '@/components/organisms/user/modals/contents/AppUserIntroduction'

// スライドの枚数
const TOTAL_SLIDE_COUNT = 5

export default {
  name: 'AppUserIntroductionModal',
  data() {
    return {
      sliderIndex: 0,
      targetCarousel: 0,
      totalSlideCount: TOTAL_SLIDE_COUNT
    }
  },
  components: {
    Introduction
  },
  computed: {
    initialSlideButtonLabel () {
      return this.isLastSlide ? 'Poshspaceをはじめる' : '次へ'
    },
    isLastSlide() {
      return this.sliderIndex === (this.totalSlideCount - 1)
    }
  },
  methods: {
    showModal() {
      this.$refs['introduction'].show()
    },
    hideModal() {
      this.$refs['introduction'].hide()
    },
    $_onCarouselChange: function (index) {
      this.sliderIndex = index
    },
    $_onClickNextSlide: function () {
      if (this.isLastSlide) {
        this.$store.dispatch('modal/setModalId', 'map')
        return
      } else {
        this.targetCarousel = this.sliderIndex + 1
      }
    },    
  }
}
</script>
  
<style lang="scss">
.introduction-modal-content {
  height: 550px;
}
.introduction-modal-footer {
  justify-content: center !important;
  border: none !important;
  margin-bottom: 2rem;
}
.slider-dot-active {
  color: #95BB00;
}
.slider-dot {
  color: #C6C6C6;
}
</style>