export const OTHERS_PAGE_MENU = [
  {
    title: 'Poshspaceとは',
    link: '',
    redirectUrl: 'https://poshspace.net/about/'
  },
  {
    title: '運営会社',
    link: '/posh_info',
    tag: 'posh-info',
  },
  {
    title: 'お問い合わせ',
    link: '',
    redirectUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSepG3MOTJN6hKO3EVvOL8q-_xPG5PcEUqRUDZOrBw7ZbyYrAQ/viewform',
  },
  {
    title: '位置情報利用の許可設定',
    link: '/location_setting',
    tag: 'location-setting',
  },
  {
    title: 'ホーム画面への登録方法',
    link: '/add_to_homescreen',
    tag: 'add-to-homescreen',
  },
  {
    title: 'クチコミ投稿ガイドライン',
    link: '/review-guideline',
    tag: 'review-guideline',
  },
  {
    title: '利用規約',
    link: '/user-guideline',
    tag: 'user-guideline',
  },
  {
    title: 'プライバシーポリシー',
    link: '/privacy-policy',
    tag: 'privacy-policy',
  },
]

// assetsへのパスを bind 時に文字列として処理されるので、モジュールとして読み込んでから渡す
export const FOOTER_MENU_BUTTONS = [
  {
    id: 'map',
    text: 'マップ',
    activeIcon: require("@/assets/image/icon_map.svg"),
    inactiveIcon: require("@/assets/image/icon_map.svg")
  },
  {
    id: 'likes',
    text: 'お気に入り',
    activeIcon: require("@/assets/image/icon_fav.svg"),
    inactiveIcon: require("@/assets/image/icon_fav_on.svg")
  },
  {
    id: 'news',
    text: 'お知らせ',
    activeIcon: require("@/assets/image/icon_news.svg"),
    inactiveIcon: require("@/assets/image/icon_news.svg")
  },
  {
    id: 'others',
    text: 'その他',
    activeIcon: require("@/assets/image/icon_other.svg"),
    inactiveIcon: require("@/assets/image/icon_other.svg")
  }
]

export const TOP_PAGE_PATH = '/'

// 東京駅
export const DEFAULT_POSITION = {
  lat: 35.681382,
  lng: 139.766084
}

export const DEFAULT_ZOOM = 8

export const CULSTER_ZOOM_LEVEL = 14

export const INITIAL_CONDITIONS = {
  SHOW_SPACE: true,
  SHOW_EVENT: true,
  KEYWORD: '',
  LIKES_COUNT: 0,
  REVIEW_COUNT: 0,
  DISTANCE: null,
  PREFECTURE: null,
  HELD_DATES: [],
  PREV_CONDITIONS: {},
}

export const USER_GUIDELINE = [
  {
    page: 1,
    contents: [
      {
        title: '第1条.（適用）',
        text: [
          '1. 本規約は、Poshspace運営者 （以下「運営者」といいます。）が提供する本サービスの利用に関する条件を定めたものとなります。利用者は、コンテンツの投稿や閲覧などその方法に関わらず、本サービスをご利用いただいたときに、本規約の全てに同意したものとみなされます。',
          '2. 運営者が運営者ウェブサイト上で掲載する本サービスの利用に関する全てのルールは、本規約の一部を構成するものとします。',
          '3. 本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。ただし、投稿コンテンツの利用などに関し、利用者と運営者の間で本規約とは別の契約 （以下 「個別契約」といいます。）を書面により締結した場合は、当該個別契約の規定が本規約に優先します。'
        ]
      },
      {
        title: '第2条.（定義）',
        text: [
          '本規約において使用する用語は、次の各号に定める意味を有するものとします。',
          '',
          '(1)「利用者」とは、コンテンツの投稿の有無を問わず、本サービスの閲覧など本サービスを利用されるすべての方を意味します。',
          '(2)「会員」とは、利用者のうち、第4条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。',
          '(3)「オーナー」とは、スペースを保有する個人又は法人を意味します。',
          '(4)「投稿コンテンツ」とは、利用者が投稿したコンテンツ（スペース情報やイベント情報にかかるものも含む。）を意味します。',
          '(5)「コンテンツ」とは、文章、画像、動画、音声等のデータを意味します。',
          '(6)「登録情報」とは、会員として本サービスを利用する際に必要となる、ユーザーID（メールアドレス）およびパスワードを意味します。',
          '(7)「運営者ウェブサイト」とは、そのドメインが｢https://poshspace.jp｣、または「https://poshspace.net｣である、運営者が運営するウェブサイト（理由の如何を問わず、運営者のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）の全てを意味します。',
          '(8)「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権およびその他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。',
          '(9)「スペース」とは、運営者ウェブサイトに登録された、オーナーが管理する部屋、空地その他のスペース等を意味します。',
          '(10)「イベント」とは、運営者ウェブサイトに登録された、祭り、催事等を意味します。',
          '(11)「スペース情報」とは、運営者ウェブサイトにおいて、運営者が登録または編集する施設の情報や、利用者が情報提供した施設の情報を意味します。',
          '(12)「スペース利用」とは、利用者自身による施設におけるスペースの利用を意味します。',
          '(13)「イベント情報」とは、運営者ウェブサイトにおいて、運営者が登録または編集するイベントの情報や、利用者が情報提供したイベントの情報を意味します。'
        ]
      },
      {
        title: '第3条（情報と投稿）',
        text: [
          '本サービスは、開催実績のあるスペース情報、開催予定のイベント情報の掲載を目的としています。',
          '情報は、利用者やオーナーによる情報提供から、承認プロセスを経て公開されます。品質を上げるべく仕組みづくりに取り組みますが、情報の真偽について保証するものではありません。',
          '投稿された情報に関しての、表現方法・利用方法・範囲に関しては、運営が責任と権利を保有するものとします。'
        ]
      },
    ]
  },
  {
    page: 2,
    contents: [
      {
        title: '第4条.（登録）',
        text: [
          '運営者は、登録を希望する者が以下の各号に該当する場合は、事前に通知または催告することなく登録を拒否することがあります。なお、拒否の理由は一切開示しないものとします。',
          '',
          '(1) 登録情報の全部または一部につき虚偽があった場合',
          '(2) 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力およびその他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると運営者が判断した場合',
          '(3) 過去に本サービスもしくは運営者との契約に違反した者またはその関係者であると運営者が判断した場合',
          '(4) 第11条（登録抹消等）に定める措置を受けたことがある場合',
          '(5) 既に会員として登録されている場合',
          '(6) その他、登録が不適当であると運営者が判断した場合'
        ]
      },
      {
        title: '第5条.（登録事項の変更）',
        text: [
          '会員は、登録情報に変更があった場合、運営者の定める方法により当該変更事項を遅滞なく運営者に通知するものとします。'
        ]
      },
      {
        title: '第6条.（パスワードおよびユーザーIDの管理）',
        text: [
          '1. 会員は、自己の責任において、本サービスに関するパスワードおよびユーザーID（メールアドレス）を適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更もしくは売買等をしてはならないものとします。',
          '2. パスワードもしくはユーザーID（メールアドレス）の管理不十分、使用上の過誤または第三者の使用等によって生じた損害に関する責任は会員が負うものとします。'
        ]
      },
      {
        title: '第7条.（権利の帰属）',
        text: [
          '1. 投稿コンテンツの著作権その他知的財産権は、利用者または利用者にライセンスを許諾している第三者に帰属し、運営者は一切の権利を取得しません。ただし、利用者は、運営者に対し、投稿コンテンツについて、本サービスの運営、広告宣伝および派生著作物の作成の目的において、無償で複製、公衆送信 （送信可能化を含みます。）、翻案できる非独占的な権利を地域の限定なく許諾します。この目的外で利用する場合は、運営者は事前に利用者に対し利用方法などを通知し、許諾を得るものとします。',
          '2. 利用者は、投稿コンテンツについて、投稿に関する適法で正当な権利を有しており、第三者の権利を侵害していないことを表明し、保証するものとします。',
          '3. 利用者は、投稿コンテンツについて著作者人格権を行使しないことに同意するものとします。',
          '4. 投稿コンテンツに関するもの以外の、運営者ウェブサイトおよび本サービスに関するその他の知的財産権は、全て運営者または運営者にライセンスを許諾している第三者に帰属します。本規約に基づく本サービスの利用許諾は、運営者または運営者にライセンスを許諾している第三者の知的財産権の使用許諾を意味するものではありません。'
        ]
      },
    ]
  },
  {
    page: 3,
    contents: [
      {
        title: '第8条.（禁止事項）',
        text: [
          '利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると運営者が判断する行為をしてはなりません。',
          '',
          '(1) 法令に違反する、または犯罪行為に関連する行為',
          '(2) 運営者、他の利用者または第三者に対する詐欺または脅迫行為',
          '(3) 公序良俗に反する行為',
          '(4) 運営者、他の利用者または第三者の知的財産権、肖像権、プライバシー権、名誉権、その他法令または契約上の権利を侵害する行為',
          '(5) 本サービスを通じ、以下に該当する情報または該当すると運営者が判断する情報を投稿し、または運営者に送信すること',
          '• 過度に暴力的または残虐な表現を含む情報',
          '• コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報',
          '• 運営者、本サービスの他の利用者または第三者の名誉または信用を毀損する表現を含む情報',
          '• 過度にわいせつな表現を含む情報',
          '• 差別を助長する表現を含む情報',
          '• 自殺、自傷行為を助長する表現を含む情報',
          '• 薬物の不適切な利用を助長する表現を含む情報',
          '• 反社会的な表現を含む情報',
          '• チェーンメール等の第三者への情報の拡散を求める情報',
          '• 他人に不快感を与える表現を含む情報',
          '• 嫌がらせや誹謗中傷を目的とした表現を含む情報',
          '• 運営者ウェブサイトに掲載された本サービスの目的に反し、またはこれと関係がない情報',
          '• 他の利用者を揶揄しまたは利用者間の対立を煽る表現を含む情報',
          '• 施設において禁止されている行為により取得した情報(写真を含む)',
          '(6) 対価の提供の有無にかかわらず、クチコミの投稿もしくは「お気に入り」を押すこと等を他の利用者に求める行為',
          '(7) 意図的に虚偽の情報を流布させる行為',
          '(8) 不正アクセスまたは本サービスの他の会員の登録情報等を利用する行為',
          '(9) 運営者ウェブサイトを構成する一切のデータ(投稿コンテンツを含む。)を収集する行為(スクレイピング及びクローリングを含むがこれらの手法に限らない。)、自動化された手段(情報収集ボット、ロボット、スパイダー、スクレーパーなど)を使用して本サービスにアカウント登録したりアクセスしたりする行為、本サービスによって提供される機能を複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳もしくは解析する行為、その入手経路を問わず運営者ウェブサイトを構成する一切のデータ(投稿コンテンツを含む。)を運営者の許可なく利用する行為、本サービスのサーバやネットワークに支障を与える行為および本サービスの運営や利用者による本サービスの利用を妨げる行為。ただし、商用目的(法人であるか否か又は利益を得ているか否かを問わない。なお、ウェブサービスおよびアプリ制作に使用する場合は広告の掲載の有無にかかわらず商用目的とみなす。)ではなく個人による私的使用であること、本サービスのサーバやネットワークに負担をかけない方法(10秒に1回程度のアクセスを上限とする。)により行うことおよび当該行為により取得した情報の分析結果等を第三者に開示する場合には、利用者を含むキッチンカー・移動販売業界の健全な発展を目的とするものであること（開示の方法および対象は問わないが、本サービスから取得した情報を利用した旨を併せて示すこと。）の全てを満たす場合であると運営者が判断する場合はこの限りではありません。',
          '(10) 第三者に成りすます行為',
          '(11) 本サービスの他の利用者の情報の収集',
          '(12) 運営者、他の利用者または第三者に不利益、損害、不快感を与える行為',
          '(13) 同じスペース利用についての記録を繰り返し投稿すること',
          '(14) 会員として既に登録されているにもかかわらず第4条（登録）にかかる登録を求める行為',
          '(15) 反社会的勢力等への利益供与',
          '(16) 性行為やわいせつな行為を行うために面識のない他人（異性に限らない）と出会うことを目的とした行為',
          '(17) 前各号の行為を直接または間接に惹起しまたは容易にする行為',
          '(18) 前各号の行為を試みること',
          '(19) その他、運営者が不適当と判断した行為',
        ]
      },
    ]
  },
  {
    page: 4,
    contents: [
      {
        title: '第9条.（本サービスの停止等）',
        text: [
          '運営者は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。',
          '',
          '(1) 本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合',
          '(2) コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセスまたはハッキング等により本サービスの運営ができなくなった場合',
          '(3) 地震、落雷、火災、風水害、停電または天災地変などの不可抗力により本サービスの運営ができなくなった場合',
          '(4) その他、運営者が停止または中断を必要と判断した場合'
        ]
      },
      {
        title: '第10条.（本サービスの変更および終了）',
        text: [
          '運営者は、運営者の都合により、利用者に対して事前の通知をすることなく本サービスの内容を変更することができ、また事前に通知することにより本サービスを終了することができます。'
        ]
      },
      {
        title: '第11条.（登録抹消等）',
        text: [
          '運営者は、会員が以下の各号のいずれかの事由に該当する場合または該当すると合理的な根拠に基づき運営者が判断した場合は、事前に通知または催告することなく、また当該会員に対してその理由を示すことなく、当該会員が会員サービスに蓄積した情報を削除すること、当該会員について、本サービスの全ての利用を一時的に停止すること、または会員の登録を抹消することができます。',
          '',
          '(1) 本規約のいずれかの条項に違反した場合',
          '(2) 登録情報に虚偽の事実があることが判明した場合',
          '(3) 第4条（登録）各号に該当する場合',
          '(4) メールアドレスの変更により、連絡が取れなくなった場合',
          '(5) 会員が最後にログインをした日より起算し1年を経てもログインがない場合',
          '(6) その他、運営者が会員としての登録の継続を適当でないと判断した場合'
        ]
      },
      {
        title: '第12条.（退会）',
        text: [
          '1. 会員は、運営者が定める手続の完了により、本サービスから退会し、自己の会員としての登録を抹消することができます。',
          '2. 退会後の登録情報の取扱いについては、第14条（個人情報の取扱い）の規定に従うものとします。',
          '3. 誤ってアカウントを削除した場合その他理由の如何を問わず、会員が会員サービスを利用する権利を失った場合、会員は、アカウント、その他会員サービスに蓄積した情報を利用することができなくなることをあらかじめ承諾するものとします。',
          '4. 当社は、会員が会員サービスを退会した後も、当該会員に関し当社が取得した情報を保有・利用することができるものとします。',
          '5. 会員サービス退会後、会員が再度会員サービスの利用を希望する場合には、あらためて会員サービスの会員登録を行う必要があります。会員は、再登録の際に前のデータが引き継がれないことを予め承諾するものとします。'
        ]
      },
    ]
  },
  {
    page: 5,
    contents: [
      {
        title: '第13条.（秘密保持）',
        text: [
          '利用者は、本サービスに関連して運営者が利用者に対して秘密に取扱うことを求めて開示した非公知の情報について、運営者の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。'
        ]
      },
      {
        title: '第14条.（個人情報の取扱い）',
        text: [
          '運営者による利用者の個人情報の取扱いについては、別途プライバシーポリシーの定めによるものとし、利用者はこのプライバシーポリシーに従って運営者が利用者の個人情報を取扱うことについて同意するものとします。'
        ]
      },
      {
        title: '第15条.（本規約の変更）',
        text: [
          '1. 運営者は、運営者が必要と認めた場合は、本規約を変更できるものとします。',
          '2. 本規約を変更する場合、変更後の本規約の施行時期および内容を運営者ウェブサイト上での掲載その他の適切な方法により周知します。',
          '3. 変更後の規約は、前項の施行時期からその効力を生じるものとし、利用者は、本規約の変更後も本サービスの利用を継続することで、変更後の本規約に同意したものとみなします。'
        ]
      },
      {
        title: '第16条.（連絡／通知）',
        text: [
          '1. 本サービスに関する問い合わせその他利用者から運営者に対する連絡または通知、および運営者から利用者に対する連絡または通知は、運営者の定める方法で行うものとします。',
          '2. 運営者が登録情報に含まれるユーザーID（メールアドレス）・その他の連絡先に連絡または通知を行った場合、利用者は当該連絡または通知を受領したものとみなします。'
        ]
      },
      {
        title: '第17条.（免責等）',
        text: [
          '1. 運営者は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、および不具合が生じないことについて、明示または黙示を問わず何ら保証するものではなく、これらに関する瑕疵を除去する義務を利用者に対して負いません。',
          '2. 運営者は、運営者の責めに帰すべき事由により本サービスに関して利用者が被った損害につき、運営者に故意または重過失がある場合を除き、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないものとします。ただし、本規約においてこれと異なる定めがある場合はこの限りではありません。',
          '3. 本サービスまたは運営者ウェブサイトに関連して利用者と他の利用者または第三者との間に生じた取引、連絡または紛争等については、利用者が自己の責任により解決するものとします。',
          '4. 運営者は、運営者ウェブサイト上の任意のページに運営者または第三者の広告を掲載することができるものとします。'
        ]
      },
      {
        title: '第18条.（分離可能性）',
        text: [
          '本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約のその他の条項および一部が無効または執行不能と判断された条項の残りの部分については、継続して完全に効力を有するものとします。'
        ]
      },
      {
        title: '第19条.（準拠法および管轄裁判所）',
        text: [
          '本規約および個別契約の準拠法は日本法とします。本サービス、本規約および個別契約に関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。'
        ]
      },
    ]
  },
]

export const USER_GUIDELINE_FIXED_DATE = '【2023年7月7日改定】'

export const USER_PRIVACY_POLICY = [
  {
    page: 1,
    contents: [
      {
        title: '1. 個人情報の定義',
        text: [
          '本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を意味するものとします。'
        ]
      },
      {
        title: '2. 個人情報の利用目的',
        text: [
          '当社は、お客様の個人情報を、以下の目的で利用いたします。',
          '',
          '1. Poshspaceサービスその他のサービス（以下「当社サービス」といいます。）の提供のため',
          '2. 当社サービスに関するご案内、お問い合せ等への対応のため',
          '3. 当社の商品、サービス等のご案内のため',
          '4. 当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため',
          '5. 当社サービスに関する規約等の変更等を通知するため',
          '6. 当社サービスの改善、新サービスの開発等に役立てるため',
          '7. 当社サービスに関連して、個人を識別できない形式に加工した統計データを作成するため',
          '8. 当社サービスの運営上のトラブル防止のため',
          '9. 当社とパートナー関係にある企業に対し、当社サービスの提供に必要な範囲で開示するため',
          '10.本プライバシーポリシーの規定に従って、第三者に開示するため',
          '11.その他、上記利用目的に付随する目的のため'
        ]
      },
      {
        title: '3. 個人情報利用目的の変更',
        text: [
          '当社は、個人情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。'
        ]
      },
    ]
  },
  {
    page: 2,
    contents: [
      {
        title: '4. 個人情報利用の制限',
        text: [
          '当社は、個人情報保護法その他の法令により許容される場合を除き、お客様の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません',
          '',
          '1. 法令に基づく場合',
          '2. 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき',
          '3. 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難であるとき',
          '4. 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき'
        ]
      },
      {
        title: '5. 個人情報の適正な取得',
        text: [
          '当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。',
        ]
      },
      {
        title: '6. 個人情報の安全管理',
        text: [
          '当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。'
        ]
      },
      {
        title: '7. 第三者提供',
        text: [
          '当社は、個人情報保護法その他の法令に基づき開示が認められる場合を除き、あらかじめお客様の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は第三者への提供には該当しません。',
          '',
          '1. 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合',
          '2. 合併その他の事由による事業の承継に伴って個人情報が提供される場合',
          '3. 個人情報保護法の定めに基づき共同利用する場合'
        ]
      },
      {
        title: '8. 個人情報の開示',
        text: [
          '当社は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1000円）を頂戴しておりますので、あらかじめ御了承ください。'
        ]
      },
    ]
  },
  {
    page: 3,
    contents: [
      {
        title: '9. 個人情報の訂正等',
        text: [
          '当社は、お客様から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、お客様ご本人からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨をお客様に通知します（訂正等を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。'
        ]
      },
      {
        title: '10. 個人情報の利用停止等',
        text: [
          '当社は、お客様から、お客様の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、お客様ご本人からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等を行い、その旨をお客様に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、この限りではありません。'
        ]
      },
      {
        title: '11. Cookie（クッキー）その他の技術の利用',
        text: [
          '当社のサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。'
        ]
      },
      {
        title: '12. お問い合わせ',
        text: [
          '開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記までお願い致します。',
          '',
          '〒182-0011 東京都調布市深大寺北町4−6−4',
          '株式会社ティーピーズ',
          'E-mail：info@teepees.co.jp',
          '（なお、受付時間は、平日10:00から17:00までとさせていただきます。）'
        ]
      },
      {
        title: '13. 継続的改善',
        text: [
          '当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。'
        ]
      },
    ]
  }
]

export const USER_PRIVACY_POLICY_FIXED_DATE = '【2023年3月24日制定】'

export const MANAGEMENT_COMPANY = [
  {
    title: '商号',
    text: [
      '株式会社ティーピーズ',
      'TEEPEES Inc.'
    ]
  },
  {
    title: '住所',
    text: [
      '〒182-0011',
      '東京都調布市深大寺北町4-6-4'
    ]
  },
  {
    title: '設立',
    text: [
      '2017年7月'
    ]
  },
  {
    title: '代表者',
    text: [
      '代表取締役　佐藤　翔一'
    ]
  },
]