<template>
  <div class="app-user-add-to-homescreen">
    <IconThirdSlide1 />
    <p class="description" :style="{ lineHeight: '2' }">
      <br />
      Poshspaceのご利用は、<br />
      スマートフォンのホーム画面への<br />
      登録が便利です。
    </p>
  </div>
</template>

<script>
import IconThirdSlide1 from '@/assets/image/icon_third_slide_1.svg?component'

export default {
  name: 'AppUserIntroduction',
  components: {
    IconThirdSlide1
  },
}
</script>

<style lang="scss" scoped>
.app-user-add-to-homescreen {
  text-align: center;
}
</style>