export default {
  namespaced: true,
  state: {
    email: '',
    status: 0,
    type: 1,
    likes: [],
    createAt: null,
    updateAt: null,
    deleteAt: null,
    showedAddPlaceModal: false
  },
  mutations: {
    resetState(state) {
      state.email = ''
      state.status = 0
      state.type = 1
      state.likes = []
      state.createAt = null
      state.updateAt = null
      state.deleteAt = null
    },
    setUser(state, value) {
      // リモートと同じデータ構造が理想
      const {
        email, status, type, likes,
        createAt, updateAt, deleteAt
      } = value
      state.email = email
      state.status = status
      state.type = type
      state.likes = likes || []
      state.createAt = createAt
      state.updateAt = updateAt
      state.deleteAt = deleteAt
    },
    replaceLikes(state, value) {
      state.likes = value
    },
    setShowedAddPlaceModal(state, value) {
      state.showedAddPlaceModal = value
    },
  },
  getters: {
    user: (state) => {
      // ログイン処理をしていないユーザーはstateがnullになるため
      if (!state) return {}
      return {
        email: state.email,
        status: state.status,
        type: state.type,
        likes: state.likes,
        createAt: state.createAt,
        updateAt: state.updateAt,
        deleteAt: state.deleteAt,
      }
    },
    likes: (state) => {
      // ログイン処理をしていないユーザーはstateがnullになるため
      if (!state) return null
      return state.likes
    },
    showedAddPlaceModal: (state) => {
      return state.showedAddPlaceModal
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    setUser(context, value) {
      context.commit('setUser', value)
    },
    replaceLikes(context, value) {
      context.commit('replaceLikes', value)
    },
    setShowedAddPlaceModal(context, value) {
      context.commit('setShowedAddPlaceModal', value)
    },
  }
}
