<template>
  <div class="app-user-review">
    <!-- Header -->
    <div class="review-list-title">
      <IconReview class="review-list-title-icon"/>
      <div class="review-list-title-text">通報</div>
    </div>

    <!-- Body -->
    <div class="review-body">
      <div class="sub-title" :style="{ color: '#A5A5A5', marginBottom: '16px' }">通報するクチコミ</div>
      <div :style="{ borderRadius: '4px', border: 'solid 1px #C6C6C6', padding: '16px' }">
        <div class="post-user-type-container">
          <p
            class="review-post-user-type"
            :class="$_getUserTypeClass(reviewPostUserType)"
          >
            {{ reviewPostUserType }}
          </p>
          <div class="review-create-at">
            {{ $_formatDate(reviewCreateAt) }}
          </div>
        </div>
        <CommonTextarea
          v-model="reviewContent"
          :prefix="'user-objection-target'"
          :readonly="true"
          :shown="shown"
          :userStyle="{
            color: '#A5A5A5',
            fontSize: '16px',
          }"
        />
      </div>

      <div class="sub-title pt-5 mb-2">通報する理由を以下から選んでください。</div>
      <div class="radio-button-input-form">
        <input
          id="01-A"
          name="radio01"
          type="radio"
          value="スパム / 宣伝行為"
          v-model="objectionType"
        >
        <label for="01-A" class="radio01">スパム / 宣伝行為</label>
        <input
          id="01-B"
          name="radio01"
          type="radio"
          value="迷惑行為"
          v-model="objectionType"
        >
        <label for="01-B" class="radio01">迷惑行為</label>
        <input
          id="01-C"
          name="radio01"
          type="radio"
          value="その他"
          v-model="objectionType"
        >
        <label for="01-C" class="radio01">その他</label>
      </div>

      <div class="sub-title mt-4" :style="{ display: 'flex', alignItems: 'center' }">
        通報理由<span :style="{ color: 'red', marginLeft: '8px', fontSize: '10px' }">必須</span>
      </div>
      <p class="review-limitation-text">
        300字以内でご入力ください。（現在：<span :style="{ color: reachLimitation ? 'red' : '#707070' }">{{ trimedObjectionReasonLength }}</span>文字）
      </p>

      <CommonTextarea
        v-model="objectionReason"
        :prefix="'user-objection'"
        :maxlength="String(maxObjectionReasonLength)"
        :rows="'7'"
        :shown="shown"
        :userStyle="{
          color: '#A5A5A5',
          border: '1px solid #C6C6C6',
          borderRadius: '4px',
          fontSize: '16px',
          padding: '16px',
        }"
      />

      <!-- Preview button -->
      <div class="pt-5">
        <button
          id="back-button"
          class="mb-3"
          @click="$emit('back-to-review-list-click')"
        >
          クチコミ一覧にもどる
        </button>
        <button
          id="submit-button"
          :disabled="!trimedObjectionReasonLength"
          :class="{ 'disabled-button': !trimedObjectionReasonLength }"
          @click="$_sendObjection"
        >
          通報する
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import IconReview from '@/assets/image/icon_review.svg?component'
import moment from 'moment'
import inobounce from 'inobounce'

const MAX_OBJECTION_REASON_LENGTH = 300

export default {
  name: 'AppUserObjection',
  components: {
    CommonTextarea,
    IconReview,
  },
  props: {
    reviewForObjection: {
      type: Object,
      defalut: () => {}
    },
  },
  data() {
    return {
      objectionType: 'スパム / 宣伝行為',
      objectionReason: '',
      shown: false
    }
  },
  computed: {
    reviewCreateAt() {
      return this.reviewForObjection.createAt || null
    },
    reviewContent() {
      return this.reviewForObjection.review || ''
    },
    reviewPostUserType() {
      return this.reviewForObjection.postUserType || ''
    },
    isWebkit() {
      return window.navigator.userAgent.includes('WebKit')
    },
    maxObjectionReasonLength() {
      /**
       * 
       * Textareaの仕様で、value(入力したテキスト)の扱いがに以下の種類がある
       * 
       * *** 注：モバイルのWebkitのみの仕様 ***
       *
       *  - raw value        : ユーザーが入力したそのままの値
       *  - API value        : jsエンジンが触れる値(textarea.valueと同じ)で、改行コードはLFになってる
       *  - submission value : HTMLがフォームデータとして保持する値で改行コードはCR/LFになり、そのほかwrap属性も加味
       * 
       * つまり this.review の値は API value となり、改行コードがLFの1文字分である一方、Textarea自身が見ている値は
       * submission value となり、改行が CR/LF で2文字分のカウントとなる
       * 
       * この関数の処理では、exceptions はスペースと改行をすべて含む配列となり、exceptions.length を取ると改行の
       * カウントが１となる（要素数を撮っているだけなので、LF or CR/LF は気にしていない）
       * 
       * よってブラウザがWebkit系の場合のみ、改行を２文字としてカウントする（改行の数を抜き出してトータルに足す）
       * 
       * FIXME: 本来は AppTextare の責務であるため、本機能を AppTextarea に組み込むこと（AppUserReviewにも重複する処理がある）
       * 
       */
      const exceptions = this.objectionReason.match(/[\s|\n|\r|\r\n]/g)
      if (exceptions) {
        if (this.isWebkit) {
          const lineBreaks = exceptions.filter(e => /\n/.test(e)).length
          return MAX_OBJECTION_REASON_LENGTH + (exceptions ? exceptions.length + lineBreaks : 0)
        } else {
          // Gecko(Firefox)
          return MAX_OBJECTION_REASON_LENGTH + (exceptions ? exceptions.length : 0)
        }
      } else {
        return MAX_OBJECTION_REASON_LENGTH
      }
    },
    reachLimitation() {
      return this.trimedObjectionReasonLength === MAX_OBJECTION_REASON_LENGTH
    },
    trimedObjectionReasonLength() {
      return this.objectionReason.replace(/[\s|\n|\r|\r\n]/g, '').length
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()
    await this.$nextTick()
    this.shown = true
  },
  methods: {
    $_formatDate(timeStamp) {
      return timeStamp
        ? moment(timeStamp.seconds * 1000).format('YYYY/MM/DD HH:mm')
        : ''
    },
    $_sendObjection() {
      this.$emit('send-objection', {
        type: this.objectionType,
        reason: this.objectionReason
      })
    },
    $_getUserTypeClass(postUserType) {
      switch(postUserType) {
        case '移動販売事業者':
          return 'mobile-vendor-user-type'
        case '利用客':
          return 'customer-user-type'
        case 'その他':
          return 'other-user-type'
        default:
          return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-review {
  width: 100%;
  height: auto;
  padding: 0;
  overflow-y: auto;
}
.review-list-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.review-list-title-icon {
  margin-right: 16px;
}
.review-list-title-text {
  color: #707070;
  font-size: 20px;
}
.review-body {
  padding: 20px;
  .post-user-type-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .review-post-user-type {
    color: #707070;
    font-size: 14px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    &:before {
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      width: 10px;
      margin-right: 8px;
    }
  }
  .mobile-vendor-user-type {
    &:before {
      background: #12B6D4;
    }
  }
  .customer-user-type {
    &:before {
      background: #FFDA0B;
    }
  }
  .other-user-type {
    &:before {
      background: #95BB00;
    }
  }
  .sub-title {
    color: #707070;
    font-size: 14px;
  }
  .radio-button-input-form {
    display: flex;
    flex-direction: column;
    /* overwrite */
    input[type=radio] {
      display: none;
    }
    .radio01 {
      color: #A5A5A5;
      user-select: none;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: auto;
      margin: 16px 0 0 0;
      padding-left: 32px;
    }
    .radio01::before {
      background: #fff;
      border: 1px solid #A5A5A5;
      border-radius: 50%;
      content: '';
      display: block;
      height: 20px;
      left: 5px;
      margin-top: -10px;
      position: absolute;
      top: 50%;
      width: 20px;
    }
    .radio01::after {
      background: #12B6D4;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 10px;
      margin-top: -5px;
      opacity: 0;
      position: absolute;
      top: 48%;
      width: 10px;
    }
    input[type=radio]:checked + .radio01::after {
      opacity: 1;
    }
  }
  .review-textarea {
    width: 100%;
    color: #A5A5A5;
    border: 1px solid #A5A5A5;
    border-radius: 4px;
    resize: none;
    padding: 16px;
  }
  .review-create-at {
    color: #C6C6C6;
    margin-bottom: 0;
    font-size: 12px;
  }
  .review-limitation-text {
    color: #A5A5A5;
    font-size: 12px;
    margin: 8px 0;
  }
  .review-over-limitation-text {
    color: #F56350;
    font-size: 12px;
    margin: 8px 0;
  }
  #back-button {
    width: 100%;
    font-size: 16px;
    width: 100%;
    min-height: 24px;
    padding: 1.5vh;
    margin-bottom: 0;
    border-radius: 5px;
    border: none;
    background-color: #C6C6C6;
    color: white;
  }
  #submit-button {
    width: 100%;
  }
  .disabled-button {
    background-color: gray !important;
  }
}
</style>