<template>
  <b-modal
    ref="list"
    modal-class="common-modal"
    dialog-class="common-modal-dialog"
    content-class="list-modal-content"
    header-class="list-modal-header"
    body-class="list-modal-body"
    hide-header-close
    hide-backdrop
    hide-footer
    scrollable
    no-fade
    @close="() => $store.dispatch('modal/setModalId', 'map')"
  >
    <List />
    <template #modal-header="{}">
      <KeywrordHeader />
      <div class="chip-wrapper">
        <StateChip
          v-for="(chip, index) in chipData"
          :key="`filter-chip-${index}`"
          v-bind="chip"
        />
      </div>
    </template>
  </b-modal>
</template>
  
<script>
import KeywrordHeader from '@/components/organisms/user/AppUserHeader' 
import StateChip from '@/components/molecules/user/AppUserStateChip' 
import List from '@/components/organisms/user/modals/contents/AppUserList' 
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserListModal',
  components: {
    KeywrordHeader,
    StateChip,
    List
  },
  data: function () {
    return {
      chipData: null,
      watchPositionId: null,
    }
  },
  watch: {
    showSpace() {
      this.chipData = this.$_getFilterChipData()
    },
    showEvent() {
      this.chipData = this.$_getFilterChipData()
    },
    distanceFilterDirection() {
      this.chipData = this.$_getFilterChipData()
    },
    likeFilterDirection() {
      this.chipData = this.$_getFilterChipData()
    },
  },
  mounted: async function () {
    this.chipData = this.$_getFilterChipData()
  },
  computed: {
    ...mapGetters('map', [ 'currentLocation' ]),
    ...mapGetters('filter', [ 'conditions' ]),
    showSpace: function () {
      return this.conditions.showSpace
    },
    showEvent: function () {
      return this.conditions.showEvent
    }, 
    distanceFilterDirection() {
      return this.$store.getters.distanceFilterDirection
    },
    likeFilterDirection() {
      return this.$store.getters.likeFilterDirection
    },
  },
  methods: {
    showModal() {
      this.$refs['list'].show()
    },
    hideModal() {
      this.$refs['list'].hide()
    },
    $_getFilterChipData() {
      return [
        {
          title: 'スペース',
          color: '#fff',
          bkColor: '#12B6D4',
          borderColor: '#12B6D4',
          turnOn: this.showSpace,
          onClick: () =>{
            this.$store.dispatch(
              'filter/setConditions',
              { showSpace: !this.showSpace }
            )
          }
        },
        {
          title: 'イベント',
          color: '#fff',
          bkColor: '#95BB00',
          borderColor: '#95BB00',
          turnOn: this.showEvent,
          onClick: () =>{
            this.$store.dispatch(
              'filter/setConditions',
              { showEvent: !this.showEvent }
            )
          }
        },
        {
          title: '現在地',
          color: '#fff',
          bkColor: '#707070',
          borderColor: '#707070',
          withArrow: true,
          turnOn: (
            this.distanceFilterDirection === 'up'||
            this.distanceFilterDirection === 'down'
          ),
          sortDirection: this.distanceFilterDirection,
          onClick: () =>{
            if (!this.currentLocation) {
              this.$_watchCurrentLocation()
              return
            }
            this.$_setDistanceFilterDirection()
          }
        },
        {
          title: 'お気に入り数',
          color: '#707070',
          bkColor: '#fff',
          borderColor: '#707070',
          withArrow: true,
          sortDirection: this.likeFilterDirection,
          onClick: () =>{
            if(this.likeFilterDirection === 'off') {
              this.$store.dispatch('set_like_filter_direction', 'down')
            } else if(this.likeFilterDirection === 'down') {
              this.$store.dispatch('set_like_filter_direction', 'up')
            } else {
              this.$store.dispatch('set_like_filter_direction', 'off')
            }
          }
        },
      ]
    },
    $_watchCurrentLocation: async function (panTo=false) {
      this.$store.dispatch('loadingMask/showLoadingMask')

      if (!navigator || !navigator.geolocation) {
        // Geolocation API非対応
        alert("この端末では位置情報が取得できません")
        this.$store.dispatch('loadingMask/hideLoadingMask')
        return
      }

      // 以前のWatchIDをクリア（意味があるかは不明）
      if (this.watchPositionId) {
        navigator.geolocation.clearWatch(this.watchPositionId)
      }

      // Geolocation API対応
      this.watchPositionId = navigator.geolocation.watchPosition(
        (position) => this.$_setCurrentLocation(position, panTo), // success
        (error) => this.$_showErrorCurrentLocation(error),        // failed
        {
          enableHighAccuracy: false , // そんなに正確じゃなくていい
          maximumAge: 30000 , // そんなに頻繁に更新しなくていいので30秒
          "timeout": 30000 ,
        }
      )
    },
    $_setCurrentLocation: async function (position) {
      try {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        this.$store.dispatch('map/setBasePoint', location)
        this.$store.dispatch('map/setCurrentLocation', location)
        // 現在地ボタンがOffの場合のみをOn（asc）する
        if (this.distanceFilterDirection === 'init') {
          this.$_setDistanceFilterDirection()
        }
      } catch (error) {
        this.$store.dispatch('map/setBasePoint', null)
        alert(`現在位置の更新に失敗しました。${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    $_showErrorCurrentLocation: function (error) {
      // エラーメッセージを表示
      switch(error.code) {
        case 1: { // PERMISSION_DENIED
          this.$store.dispatch('modal/setModalId', 'failed-get-location')
          break
        }
        case 2: // POSITION_UNAVAILABLE
          alert("現在位置が取得できませんでした")
          break
        case 3: // TIMEOUT
          alert("タイムアウトになりました")
          break
        default:
          console.log("その他のエラー(エラーコード:"+error.code+")")
          break
      }
      this.$store.dispatch('map/setBasePoint', null)
      this.$store.dispatch('loadingMask/hideLoadingMask')
    },
    $_setDistanceFilterDirection() {
      if (
        this.distanceFilterDirection === 'off' ||
        this.distanceFilterDirection === 'init'
      ) {
        this.$store.dispatch('set_distance_filter_direction', 'down')
      } else if(this.distanceFilterDirection === 'down') {
        this.$store.dispatch('set_distance_filter_direction', 'up')
      } else {
        this.$store.dispatch('set_distance_filter_direction', 'off')
      }
    },
  }
}
</script>
  
<style lang="scss">
.list-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.list-modal-header {
  flex-direction: column;
  padding: 0 0 0 0 !important;
  height: $header-height;
}
.list-modal-body {
  padding-top: 3rem !important;
  background-color: #F5F5F5;
}
.chip-wrapper {
  width: 100%;
  display: flex;
  z-index: 999;
  background-color: #F5F5F5;
  padding: 0.75rem;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
</style>