<!-- molecules へ移動？-->
<template>
  <div
    v-if="target"
    class="carousel-card-wrapper"
    v-bind:style="cardStyle"
  >
    <!-- Horizonal -->
    <div
      :class="['card', isSelectedCard && 'selected-card']"
      v-if="direction === 'row'"
    >
      <!-- thumbnail -->
      <div class="card-image" :class="{'no-image': isSpace}">
        <img
          v-if="thumbnailUrl"
          class="card-img-top"
          v-lazy="thumbnailUrl"
          :height="'100%'"
        />
      </div>

      <div :class="isSpace ? 'card-text' : 'card-text-event'">
        <!-- chip and likes -->
        <div class="card-text-header-wrapper">
          <!-- chip -->
          <Chip
            :title="chipTitle"
            :textColor="'#fff'"
            :backgroundColor="chipBkColor"
          />
          <!-- likes -->
          <div class="likes-wrapper">
            <b-icon
              v-bind:icon="'heart-fill'"
              aria-hidden="true"
              dark
            />
            <span class="likes-count">
              {{ likesCount }}
            </span>
          </div>
          <!-- review -->
          <div v-if="isSpace" class="review-wrapper">
            <IconReviewSmall />
            <span class="review-count">
              {{ reviewCount }}
            </span>
          </div>
        </div>
        <!-- place name -->
        <div
          class="title-wrapper"
          :class="isSpace ? 'card-title' : 'card-title-event'"
        >
          <p :class="isSpace ? 'limit-width' : 'limit-width-event'">
            {{ title }}
          </p>
        </div>
        <!-- address or term -->
        <div class="info-wrapper">
          <div v-if="isSpace">
            <p>{{ target.pref }} {{ target.area }}  {{ target.address }}</p>
          </div>
          <div v-else class="event-info-container">
            <p class="event-info-icon">開</p>
            <p>{{ `${$_getFormattedDate(target.heldDates, true)}`}}</p>
            <p class="event-info-icon">募</p>
            <p>{{ `${$_getFormattedDate(target.endOfPublicationDate)}〆` }}</p>
          </div>
        </div>
      </div>
      <!-- Like Button -->
      <div class="like-button-wrapper row_position">
        <LikeButton
          :liked="isLiked" 
          :isProcessing="isProcessing"
          @like-button-clicked="$_switchDisplayStyle"
        />
      </div>
    </div>

    <!-- Vertical -->
    <div
      v-else 
      class="vertical-card box-shadow vertical-margin"
      v-on:touchstart="$_onTouchStart"
      v-on:touchmove="$_onTouchMove" 
      v-on:touchend="$_onTouchEnd" 
    >
      <!-- thumbnail -->
      <div class="card-image" :class="{'no-image': isSpace}">
        <img
          v-if="thumbnailUrl"
          class="card-img-top"
          v-lazy="thumbnailUrl"
          :height="'100%'"
        />
      </div>

      <div :class="isSpace ? 'card-text' : 'card-text-event'">
        <!-- chip and likes -->
        <div class="card-text-header-wrapper">
          <Chip
            :title="chipTitle"
            :textColor="'#fff'"
            :backgroundColor="chipBkColor"
          />
          <!-- likes -->
          <div class="likes-wrapper">
            <b-icon
              v-bind:icon="'heart-fill'"
              aria-hidden="true" dark
            />
            <span class="likes-count">
              {{ likesCount }}
            </span>
          </div>
          <!-- review -->
          <div v-if="isSpace" class="review-wrapper">
            <IconReviewSmall />
            <span class="review-count">
              {{ reviewCount }}
            </span>
          </div>
        </div>
        <!-- place name -->
        <div
          class="title-wrapper"
          :class="isSpace ? 'card-title' : 'card-title-event'"
        >
          <p :class="isSpace ? 'limit-width' : 'limit-width-event'">
            {{ title }}
          </p>
        </div>
        <!-- address or term -->
        <div class="info-wrapper">
          <div v-if="isSpace">
            <p>{{ target.pref }} {{ target.area }}  {{ target.address }}</p>
          </div>
          <div v-else class="event-info-container">
            <p class="event-info-icon">開</p>
            <p :style="{}">{{ `${$_getFormattedDate(target.heldDates, true)}`}}</p>
            <p class="event-info-icon">募</p>
            <p :style="{}">{{ `${$_getFormattedDate(target.endOfPublicationDate)}〆` }}</p>
          </div>
        </div>
      </div>
      <!-- Like Button -->
      <div class="like-button-wrapper">
        <LikeButton
          :liked="isLiked" 
          :isProcessing="isProcessing"
          @like-button-clicked="$_switchDisplayStyle"
        />
      </div>
    </div> 
  </div>
</template>

<script>
import LikeButton from '@/components/molecules/user/AppUserLikeButton'
import Chip from '@/components/molecules/user/AppUserChip.vue'
import IconReviewSmall from '@/assets/image/icon_review_small.svg?component'
import { getThumbnail } from '@/helper/place'
import { showBottomInfoToast, getChunkFromLocal, showRequireLoginPopup } from '@/helper/common'
import { updateLikes } from '@/helper/user'
import { auth } from '@/firebase/config'
import moment from 'moment'
import { mapGetters } from 'vuex'

// 上下スワイプの閾値
const SWIPE_RETIO = 0

export default {
  name: 'AppUserCarouselCard',
  components: {
    LikeButton,
    Chip,
    IconReviewSmall
  },
  props: {
    imageHeight: {
      type: Number,
      default: 110,
    },
    imageWidth: {
      type: Number,
      default: 120,
    },
    textHeight: {
      type: Number,
      default: 55,
    },
    width: {
      type: String,
      default: '',
    },
    placeId: {
      type: String,
      default: '',
    },
    direction: {
      type: String, // このプロップスでmap/listの表示タイプを切り替える
      default: 'row'
    },
    selectedId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      target: null,
      isProcessing: false,
      thumbnailUrl: '',
    }
  },
  computed: {
    ...mapGetters('place', [ 'chunks' ]),
    toastText: function () {
      return this.isLiked ? 'お気に入りに追加しました。' : 'お気に入りから外しました。'
    },
    isSpace() {
      return this.target && this.target.type === 'space'
    },
    isEvent() {
      return this.target && this.target.type === 'event'
    },
    likesPlaceIds: function () {
      return this.$store.getters['user/likes'] || []
    },
    isLiked: function () {
      return this.target
        ? this.likesPlaceIds.includes(this.target.placeId)
        : false
    },
    cardStyle() {
      return {
        '--image-height': this.imageHeight + 'px',
        '--image-width': this.imageWidth + 'px',
        '--text-height': this.textHeight + 'px',
        'width': this.width + 'px'
      }
    },
    title: function () {
      return this.target 
        ? this.target.eventName || this.target.spaceName
        : ''
    },
    chipTitle: function () {
      return this.isEvent ? 'イベント' : 'スペース'
    },
    chipBkColor: function () {
      return this.isEvent ? '#95BB00' : '#12B6D4'
    },
    likesCount: function () {
      return (this.target && this.target.likes)
        ? this.target.likes
        : 0
    },
    reviewCount: function () {
      return (this.target && this.target.review)
        ? this.target.review
        : 0
    },
    isSelectedCard: function () {
      return this.target && this.target.placeId === this.selectedId
    },
  },
  watch: {
    chunks: {
      handler() {
        this.target = getChunkFromLocal(this.placeId)
      },
      deep: true
    },
  },
  async mounted() {
    try {
      this.target = getChunkFromLocal(this.placeId)

      if (this.isEvent) {
        this.thumbnailUrl = await getThumbnail('event', this.placeId)
      }
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    $_getFormattedDate: function (date, suffix=false) {
      // CSVデータにダブルクォーテーションが2種類入っているため
      const target = date
        .replace(/”/g, "").replace(/"/g, "")
        .split(",")
      if (!target || !target.length) return ''

      const sfx = suffix && target.length > 1 ? '他' : ''

      moment.locale('ja')
      return moment(target[0]).format('MM/DD(ddd)') + sfx
    },
    /**
     * 上下スワイプとクリックを判定するため、タッチ開始時のpageYを保持
     */
    $_onTouchStart: function(e) {
      this.startY = e.touches[0].pageY
      this.$emit('card-touch-start')
    },
    /**
     * 上下スワイプとクリックを判定するため、タッチ移動量をもとにスワイプフラグを設定
     */
    $_onTouchMove: function(e) {
      const touchMoveDistanceY = Math.abs(e.touches[0].pageY - this.startY)
      this.isVerticalSwipe = touchMoveDistanceY > SWIPE_RETIO
      this.$emit('card-touch-move')
    },
    /**
     * スワイプフラグが上下スワイプでない場合にクリック処理を実行
     */
    $_onTouchEnd: function (e) {
      this.$emit('card-touch-end')

      // // お気に入りボタン(アイコン)の場合は処理中断
      const tagName = e.srcElement.tagName || e.target.tagName
      if (['path', 'svg'].includes(tagName)) return

      if (!this.isVerticalSwipe) {
        this.$emit('card-click', this.target)
      } else {
        // リセットしないと次の判定が永遠にtrueのままになる
        this.isVerticalSwipe = false
      }
    },
    $_switchDisplayStyle: async function () {
      try {
        // ログインしていないユーザーのお気に入り動作制限
        if (!auth.currentUser || !auth.currentUser.emailVerified) {
          this.$_showRequireLoginPopup()
        } else {
          this.isProcessing = true

          // リモート、ストアのデータを更新
          await updateLikes(this.target)

          this.$_showBottomInfoToast('info', this.toastText, 1500)
        }
      } catch (error) {
        this.$_showBottomInfoToast('error', `お気に入りの変更に失敗しました ${error}`, 3000)
      } finally {
        this.isProcessing = false
      }
    },
    $_showRequireLoginPopup: function () {
      // customClassを定義すること
      // FIXME: customClassはscssで共通化する
      showRequireLoginPopup(
        'middle',
        {
          close: () => {},
          login: () => {
            // モーダルをとじる
            this.$store.dispatch('modal/setModalId', 'map')
            this.$router.push('/user/login')
          },
          signup: () => {
            // モーダルをとじる
            this.$store.dispatch('modal/setModalId', 'map')
            this.$router.push('/user/signup')
          },
        },
      )
    },
    /**
     * 画面下部にトーストを表示
     * @param {String} type トーストの種類(info, error,,,)
     * @param {String} text 表示テキスト
     * @param {Number} timer 表示時間(ms)
     */
     $_showBottomInfoToast: function (type, text, timer) {
       showBottomInfoToast(type, text, timer)
     },
  },
}
</script>

<style lang="scss" scoped>
.carousel-card-wrapper {
  position: relative;
  padding: 0 0.25rem;
  .card-text-header-wrapper {
    display: flex;
  }
  .title-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    max-width: 300px;
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: normal;
    .event-info-container {
      display: flex;
      align-items: center;
      column-gap: 4px;
      height: 18px;
      .event-info-icon {
        color: #fff;
        background-color: #95BB00;
        font-size: 10px;
        width: 18px;
        height: 18px;
        line-height: 17px;
        text-align: center;
        border-radius: 50%;
        padding: 2px;
      }
    }
  }
  .vertical-card {
    display: flex;
    border-radius: 8px;
    background-color: #fff;
  }
  .likes-wrapper {
    color: #12B6D4;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-left: 10px;
    .likes-count {
      margin: 0 0 0 8px;
    }
  }
  .review-wrapper {
    color: #12B6D4;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-left: 10px;
    .review-count {
      margin: 0 0 0 6px;
    }
  }
}
/* overwrite */
.card {
  border-radius: 8px;
  flex-direction: row;
}
.selected-card {
  box-shadow: 0 0 6px #12B6D4;
}
.card-detail {
  font-size: 12px;
}
.card-title {
  font-weight: bold;
  font-size: 18px;
}
.card-title-event {
  font-weight: bold;
}
.card-image {
  flex-shrink: none;
  width: var(--image-width);
  height: var(--image-height);
  border-radius: 8px;
}
.no-image {
  width: 0px !important;
  margin: 0 !important;
  width: 50px;
  margin: 0 50px;
  opacity: 0.2;
}
.card-text {
  margin: 14px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
    overflow: hidden;
  p {
    margin-bottom: 0;
    color: #707070;
    white-space: nowrap;
  }
}
.card-text-event {
  margin: 8px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
    overflow: hidden;
  p {
    margin-bottom: 0;
    color: #707070;
    white-space: nowrap;
  }
}
.limit-width {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.limit-width-event {
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
/* overwrite */
.card-body {
  height: var(--text-height);
  padding: 0.25rem 1.25rem;
}
/* overwrite */
.card-img-top {
  height: var(--image-height);
  width: var(--image-width);
  ::-webkit-scrollbar {
    display:none;
  }
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0; /* overwrite */
}
.box-shadow {
  border-radius: 16px;
  border: solid 1px #c6c6c6;
}
.vertical-margin {
  margin-bottom: 16px;
}
.like-button-wrapper {
  position: absolute;
  top: 6px;
  right: 10px;
}
</style>

<style>
.custom-popup-container {
  margin-left: 8px;
  margin-right: 8px;
}
.custom-popup-deny-button {
  background-color: #fff !important;
  color: #12B6D4 !important;
  border: solid 1px #12B6D4 !important;
  width: 132px !important;
}
.custom-popup-confirm-button {
  background-color: #12B6D4 !important;
  color: #fff !important;
  border: solid 1px #fff !important;
  width: 132px !important;
}
.custom-popup-close-button {
  color: #12B6D4 !important;
  border-radius: 50% !important;
  border: solid 2px #12B6D4 !important;
  font-size: 24px !important;
  background-color: #fff !important;
  position: absolute;
  top: -22px;
  right: -18px;
  padding: 1.25rem;
}
.custom-popup-action {
  margin-top: 0;
}
</style>