<template>
  <div v-if="placeDetail" class="app-user-space-detail">
    <!-- Header -->
    <div class="header-wrapper">
      <div class="like-button-wrapper">
        <LikeButton
          :liked="isLiked" 
          :isProcessing="isProcessing"
          :width="'46px'"
          :height="'46px'"
          @like-button-clicked="$_switchDisplayStyle"
        />
      </div>
      <div class="space-detail-chip-wrapper">
        <Chip
          :title="'スペース'"
          :textColor="'#fff'"
          :backgroundColor="'#12B6D4'"
        />
        <Chip
          v-if="addedByUser"
          :title="'ユーザー投稿'"
          :textColor="'#fff'"
          :backgroundColor="'#A5A5A5'"
        />
      </div>
      <div class="title-detail-wrapper">
        <div class="space-name">{{ spaceName }}</div>
        <div class="like-count-wrapper">
          <b-icon
            v-bind:icon="'heart-fill'"
            aria-hidden="true" dark
          />
          <span class="like-count-number">
            {{ likesCount }}
          </span>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="body-wrapper">
      <b-card no-body>
        <!-- StreetView -->
        <div >
        </div>
        <div
          v-if="addedByUser" 
          class="space-image-wrapper"
        >
          <AppUserImageBox
            v-if="thumbnailUrl"
            :src="thumbnailUrl"
            :width="'100%'"
            :height="'300px'"
          />
          <img
            v-else
            class="space-default-image"
            :src="defaultImage"
          />
        </div>
        <div
          v-else
          v-html="embeddedStreetView"
        />

        <!-- Review title -->
        <div class="review-container">
          <IconReviewMiddle />
          <div class="review-title">
            クチコミ
          </div>
          <span class="review-count">
            {{ reviewCount }}件
          </span>
        </div>

        <!-- First review -->
        <div
          v-if="firstReview"
          class="first-review-container"
        >
          <div class="post-user-type-container">
            <p
              class="post-user-type"
              :class="$_getUserTypeClass(reviewUserType)"
            >
              {{ reviewUserType }}
            </p>
            <p class="create-at">{{ reviewCreateAt }}</p>
          </div>
          <CommonTextarea
            :prefix="'first-review'"
            :readonly="true"
            :rows="'3'"
            :userStyle="{ margin: '8px 0', fontSize: '16px' }"
            :remarks="reviewContent"
          />
          <p
            class="all-review-link"
            @click="() => {
              $store.dispatch('modal/setModalHistory', this.modalId)
              $store.dispatch('modal/setModalId', 'review-list')
            }"
          >
            ＞ みんなのクチコミをもっと見る
          </p>
        </div>
        <div v-else :style="{
          border: 'solid 1px #C6C6C6',
          borderRadius: '8px',
          padding: '16px',
          color: '#707070',
          fontSize: '12px'
        }">
          <p class="mb-1">まだクチコミ投稿がありません。</p>
          <p class="mb-0">ぜひクチコミ投稿にご協力ください！</p>
        </div>

        <!-- Review button -->
        <div class="review-button-container">
          <button
            id="submit-button"
            @click="() => {
              $store.dispatch('modal/setModalHistory', this.modalId)
              $store.dispatch('modal/setModalId', 'review')
            }"
          >
            クチコミを投稿する
          </button>
        </div>

        <!-- Twitter link button -->
        <div
          v-if="twitterKeywordUrl"
          class="twitter-link-button-container"
        >
          <!-- <img
            class="twitter-link-button"
            :src="require('@/assets/image/x_banner.png')"
            @click="$_openLink(twitterKeywordUrl)"
          /> -->
          <IconX
            class="twitter-link-button"
            @click="$_openLink(twitterKeywordUrl)"
          />
          <p class="twitter-keywords">{{ twitterKeywords }}</p>
        </div>

        <!-- Map -->
        <div class="access-map-container">
          <div class="access-map-title">アクセス</div>
          <div class="access-map">
            <MapContents
              :showUiLayer="false"
              :places="[placeDetail]"
              :mapCenter="placeDetail.coordinates"
              :mapZoom="16"
              :mapOptions="mapOptions"
              @map-loadend="({ map }) => {map.setZoom(18)}"
            />
          </div>
          <p class="open-streetview" @click="$_onClickStreetView">
            Googleマップを別ウィンドウで開く
          </p>
          <p class="pin-position-remarks">
            ※ピンの位置は住所情報をもとに取得されるため、正確な位置からずれて表示される場合や、実際の出店場所とは異なる場合がありますのでご注意ください。
          </p>
        </div>

        <!-- Address -->
        <div class="address-container">
          <div class="address-title">
            住所
          </div>
          <div class="address-detail">
            <p class="zip-code">〒{{ address.zip }}</p>
            <p class="address-string">
              {{ address.prefecture }}{{ address.area }} {{ address.address }}
            </p>
          </div>
        </div>

        <!-- Apply Url -->
        <div class="apply-url-container">
          <div class="apply-url-title">募集ページ</div>
          <div class="apply-url-detail">
            <a
              v-if="applyUrl"
              class="apply-urls"
              target="_blank"
              rel="noopener noreferrer"
              :href="applyUrl"
            >
              {{ applyUrl }}
            </a>
            <div v-else>
              <p class="no-apply-url-detail">
                情報募集中です。<br />
                スペースの出店募集情報のご提供は
                <a
                  class="no-apply-url-link"
                  target="_blank"
                  rel="noopener"
                  :href="'https://docs.google.com/forms/d/e/1FAIpQLSepG3MOTJN6hKO3EVvOL8q-_xPG5PcEUqRUDZOrBw7ZbyYrAQ/viewform'"
                >
                  お問い合わせ
                </a>
                までお寄せください。
              </p>
              <p class="no-apply-url-sub-detail">
                ※お寄せいただいた情報については、掲載をお約束するものではございませんので、予めご了承ください。
              </p>
            </div>
          </div>
        </div>

        <!-- Remarks -->
        <div v-if="remarks" class="ramarks-container">
          <div class="ramarks-title">
            備考
          </div>
          <div>
            <CommonTextarea
              :prefix="'space-remarks'"
              :shown="shown"
              :readonly="true"
              :userStyle="{ margin: '8px 0', fontSize: '14px' }"
              :remarks="remarks"
            />
          </div>
        </div>

        <!-- AD -->
        <div
          v-if="showAds"
          :style="{
            width: '100%',
            margin: '32px 0 48px 0'
          }"
        >
          <img width="100%" src="@/assets/image/ad_sample.png" />
        </div>
        <!-- Spacer when no AD -->
        <div
          v-else
          :style="{
            width: '100%',
            marginBottom: '54px'
          }"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import MapContents from '@/components/molecules/user/AppUserMapContents'
import LikeButton from '@/components/molecules/user/AppUserLikeButton'
import Chip from '@/components/molecules/user/AppUserChip.vue'
import AppUserImageBox from '@/components/molecules/user/AppUserImageBox'
import IconReviewMiddle from '@/assets/image/icon_review_middle.svg?component'
import IconX from '@/assets/image/x_banner.svg?component'
import { getFirstReview } from '@/helper/firestore/review'
import { getSpace } from '@/helper/firestore/space'
import { openTwitterLink, showBottomInfoToast, showRequireLoginPopup } from '@/helper/common'
import { updateLikes } from '@/helper/user'
import { auth } from '@/firebase/config'
import moment from 'moment'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserSpaceDetail',
  components: {
    CommonTextarea,
    MapContents,
    LikeButton,
    Chip,
    AppUserImageBox,
    IconReviewMiddle,
    IconX
  },
  data() {
    return {
      placeDetail: null,
      shown: false,
      isProcessing: false,
      firstReview: null,
      mapOptions: {
        disableDefaultUI: false,
        mapTypeControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        keyboardShortcuts: false,
        zoomControl: true,
        streetViewControl: false,
        gestureHandling: 'cooperative'
      }
    }
  },
  mounted: async function(){
    // enable pull down bounce
    inobounce.disable()

    this.$store.dispatch('loadingMask/showLoadingMask')
    try {
      this.placeDetail = await getSpace(this.selectedPlaceId)

      if (this.reviewCount) {
        this.firstReview = await getFirstReview(this.selectedPlaceId)
      } 

      //FIXME: 待たないとtextarea の scrollHeight がとれない
      await this.$nextTick()
      this.shown = true
    } catch (error) {
      alert(error)
    } finally {
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [ 'mapCenter', 'mapZoom' ]
    ),
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    places() {
      return this.$store.getters['place/validChunk']
    },
    selectedPlace() { // Chunkデータ
      return this.$store.getters['place/selectedPlace']
    },
    selectedPlaceId() {
      return this.$store.getters['place/selectedPlaceId']
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    toastText: function () {
      return this.isLiked ? 'お気に入りに追加しました。' : 'お気に入りから外しました。'
    },
    isLiked: function () {
      return this.placeDetail
        ? this.likesPlaceIds.includes(this.placeDetail.placeId)
        : false
    },
    likesPlaceIds: function () {
      return this.$store.getters['user/likes'] || []
    },
    likesCount: function () {
      return (this.placeDetail && this.placeDetail.likes)
        ? Number(this.placeDetail.likes)
        : 0
    },
    reviewCount: function () {
      return this.selectedPlace && this.selectedPlace.review
        ? Number(this.selectedPlace.review)
        : 0
    },
    embeddedStreetView: function () {
      return (this.placeDetail && this.placeDetail.embeddedStreetView)
        ? this.placeDetail.embeddedStreetView
          .replace('width="600" height="450"', 'width="100%" height="200"')
        : ''
    },
    reviewUserType() {
      return this.firstReview
        ? this.firstReview.postUserType
        : ''
    },
    reviewCreateAt() {
      return this.firstReview
        ? moment(this.firstReview.createAt.seconds * 1000).format('YYYY/MM/DD HH:mm')
        : ''
    },
    reviewContent() {
      return this.firstReview
        ? this.firstReview.review
        : ''
    },
    twitterKeywordUrl: function () {
      return (this.placeDetail && this.placeDetail.twitterKeywordUrl)
        ? this.placeDetail.twitterKeywordUrl
        : ''
    },
    twitterKeywords: function () {
      return (this.placeDetail && this.placeDetail.twitterKeywords)
        ? `検索キーワード：${this.placeDetail.twitterKeywords.replace(/"/g, '').replace(/,/g, ' ')}`
        : ''
    },
    applyUrl: function () {
      return (this.placeDetail && this.placeDetail.applyUrl)
        ? this.placeDetail.applyUrl
        : ''
    },
    remarks: function () {
      return (this.placeDetail && this.placeDetail.remarks)
        ? this.placeDetail.remarks
        : ''
    },
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    addedByUser: function () {
      return this.placeDetail.addedBy
        ? this.placeDetail.addedBy === 'user'
        : false
    },
    spaceName: function () {
      return this.placeDetail.spaceName
    },
    thumbnailUrl: function () {
      return this.placeDetail && this.placeDetail.thumbnail
        ? this.placeDetail.thumbnail
        : ''
    },
    title: function () {
      return this.placeDetail.spaceName
        ? `${this.placeDetail.facilityName}/${this.placeDetail.spaceName}`
        : this.placeDetail.facilityName
    },
    address: function() {
      return {
        zip: this.placeDetail.zip,
        prefecture: this.placeDetail.prefecture,
        area: this.placeDetail.area,
        address: this.placeDetail.address
      }
    },
    defaultImage: function () {
      return require('@/assets/image/place_default.png')
    }
  },
  methods: {
    $_openLink: function (url) {
      openTwitterLink(url)
    },
    $_switchDisplayStyle: async function () {
      try {
        if (!auth.currentUser || !auth.currentUser.emailVerified) {
          this.$_showRequireLoginPopup()
        } else {
          this.isProcessing = true

          // リモート、ストアのデータを更新
          await updateLikes(this.selectedPlace)
          // リモートデータ再取得
          this.placeDetail = await getSpace(this.selectedPlaceId)

          this.$_showBottomInfoToast('info', this.toastText, 1500)
        }
      } catch (error) {
        this.$_showBottomInfoToast('error', `お気に入りの変更に失敗しました ${error}`, 1500)
      } finally {
        this.isProcessing = false
      }
    },
    $_showRequireLoginPopup: function () {
      // customClassを定義すること
      // FIXME: customClassはscssで共通化する
      showRequireLoginPopup(
        'middle',
        {
          close: () => {},
          login: () => {
            // モーダルをとじる
            this.$store.dispatch('modal/setModalId', 'map')
            this.$router.push('/user/login')
          },
          signup: () => {
            // モーダルをとじる
            this.$store.dispatch('modal/setModalId', 'map')
            this.$router.push('/user/signup')
          },
        },
      )
    },
    /**
     * ストリートビューへのリンクへ遷移
     */
    $_onClickStreetView: function () {
      const coords = `
        ${this.placeDetail.coordinates.lat},
        ${this.placeDetail.coordinates.lng}
      `
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${coords}`,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * 画面下部にトーストを表示
     * @param {String} type トーストの種類(info, error,,,)
     * @param {String} text 表示テキスト
     * @param {Number} timer 表示時間(ms)
     */
     $_showBottomInfoToast: function (type, text, timer) {
       showBottomInfoToast(type, text, timer)
     },
    $_getUserTypeClass(postUserType) {
      switch(postUserType) {
        case '移動販売事業者':
          return 'mobile-vendor-user-type'
        case '利用客':
          return 'customer-user-type'
        case 'その他':
          return 'other-user-type'
        default:
          return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-space-detail {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .header-wrapper {
    position: relative;
    padding: 36px 20px 16px 20px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
    .like-button-wrapper { 
      position: absolute;
      top: 12px;
      right: 20px;
      z-index: 9999;
    }
    .space-detail-chip-wrapper {
      display: flex;
      column-gap: 8px;
      justify-content: flex-start;
      align-items: center;
      color: #707070;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 999;
    }
    .title-detail-wrapper {
      position: sticky;
      top: 20px;
      z-index: 999;
      background-color: #fff;
      .space-name {
        color: #707070;
        padding: 8px 0;
      }
      .like-count-wrapper {
        color: #12B6D4;
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 8px;
        .like-count-number {
          margin: 0 0 0 8px;
        }
      }
    }
  }
  .body-wrapper {
    padding: 0 20px;
    /* overwrite */
    .card {
      border: none;
    }
    .space-image-wrapper {
      width: 100%;
      max-height: 300px;
      .space-default-image {
        width: 100%;
      }
    }
    .review-container {
      color: #12B6D4;
      display: flex;
      align-items: center;
      margin: 24px 0 16px 0;
      .review-title {
        color: #707070;
        display: flex;
        align-items: center;
        margin-left: 4px;
      }
      .review-count {
        margin: 0 0 0 6px;
      }
    }
    .first-review-container {
      height: 172px;
      border: solid 1px #C6C6C6;
      border-radius: 8px;
      padding: 16px;
    }
    .post-user-type-container {
       display: flex;
       align-items: center;
       justify-content: space-between;
    }
    .post-user-type {
      color: #707070;
      font-size: 12px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      &:before {
        border-radius: 50%;
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        margin-right: 4px;
      }
    }
    .create-at {
      color: #C6C6C6;
      margin-bottom: 0;
      font-size: 12px;
    }
    .review-textarea {
      width: 100%;
      border: none;
      resize: none;
      color: #707070;
      font-size: 16px;
      margin: 8px 0;
    }
    .all-review-link {
      color: #12B6D4;
      text-align: right;
      font-size: 12px;
      font-weight: bold;
    }
    .review-button-container {
      padding-top: 20px;
      #submit-button {
        font-size: 16px;
        width: 100%;
        min-height: 24px;
        padding: 1.5vh;
        margin-bottom: 0;
        border-radius: 5px;
        border: none;
        background-color: #12b6d4;
        color: white;
      }
    }
    .twitter-link-button-container {
      color: #707070;  
      margin: 20px 0 16px 0;
      .twitter-link-button {
        width: 100%;
      }
      .twitter-keywords {
        margin: 1rem 0 0 0;
        text-align: center;
        font-size: 12px;
      }
    }
    .access-map-container {
      color: #707070;  
      margin-bottom: 16px;
      .access-map-title {
        margin: 2rem 0 1.25rem 0;
      }
      .access-map {
        height: 260px;
      }
      .open-streetview {
        text-decoration: underline;
        font-size: 12px;
        line-height: 36px;
      }
      .pin-position-remarks {
        color: #707070;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .ramarks-container {
      color: #707070;
      margin-bottom: 16px;
      .ramarks-title {
        margin: 2rem 0 1.25rem 0;
      }
    }
    .address-container {
      color: #707070;  
      .address-title {
        margin: 2rem 0 1.25rem 0;
      }
      .zip-code {
        font-size: 13px;
      }
      .address-string {
        font-size: 16px;
      }
    }
    .apply-url-container {
      color: #707070;  
      margin-bottom: 16px;
      .apply-url-title {
        margin: 2rem 0 1.25rem 0;
      }
      .apply-url-detail {
        .apply-urls {
          color: #707070 !important;  
          font-size: 16px;
          text-decoration: underline;
        }
        .no-apply-url-detail {
          font-size: 14px;
        }
        .no-apply-url-link {
          color: #707070;
          text-decoration: underline;
        }
        .no-apply-url-sub-detail {
          font-size: 12px;
        }
      }
    }
  }
}
.mobile-vendor-user-type {
  &:before {
    background: #12B6D4;
  }
}
.customer-user-type {
  &:before {
    background: #FFDA0B;
  }
}
.other-user-type {
  &:before {
    background: #95BB00;
  }
}
</style>

<style>
.custom-popup-container {
  margin-left: 8px;
  margin-right: 8px;
}
.custom-popup-deny-button {
  background-color: #fff !important;
  color: #12B6D4 !important;
  border: solid 1px #12B6D4 !important;
  width: 132px !important;
}
.custom-popup-confirm-button {
  background-color: #12B6D4 !important;
  color: #fff !important;
  border: solid 1px #fff !important;
  width: 132px !important;
}
.custom-popup-close-button {
  color: #12B6D4 !important;
  border-radius: 50% !important;
  border: solid 2px #12B6D4 !important;
  font-size: 24px !important;
  background-color: #fff !important;
  position: absolute;
  top: -22px;
  right: -18px;
  padding: 1.25rem;
}
.custom-popup-action {
  margin-top: 0;
}
</style>