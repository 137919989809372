export default {
  // basePointは機能ごといらない希ガス
  // それほどユーザー似メリットを提供できているとは言えない上、コストが掛かっているため
  // 一旦MTGの課題にあげる
  namespaced: true,
  state: {
    mapCenter: null,
    mapZoom: null,
    mapBounds: null,
    currentLocation: null,
    selectedMarker: null,
    zoomControl: false,
    basePoint: null,
  },
  mutations: {
    resetState(state) {
      state.mapCenter = null
      state.mapZoom = null
      state.mapBounds = null
      state.currentLocation = null
      state.selectedMarker = null
      state.zoomControl = false
      state.basePoint = null
    },
    setMapCenter: (state, value) => {
      state.mapCenter = value
    },
    setMapZoom: (state, value) => {
      state.mapZoom = value
    },
    setZoomControl: (state, value) => {
      state.zoomControl = value
    },
    setMapBounds: (state, value) => {
      state.mapBounds = value
    },
    setCurrentLocation: (state, value) => {
      state.currentLocation = value
    },
    setSelectedMarker(state, value) {
      state.selectedMarker = value
    },
    setBasePoint:(state, value) => {
      state.basePoint = value
    },
  },
  getters: {
    mapConfig: (state) => {
      return {
        mapCenter: state.mapCenter,
        mapZoom: state.mapZoom,
        zoomControl: state.zoomControl
      }
    },
    mapCenter: (state) => {
      return state.mapCenter
    },
    mapZoom: (state) => {
      return state.mapZoom
    },
    zoomControl: (state) => {
      return state.zoomControl
    },
    mapBounds: (state) => {
      return state.mapBounds
    },
    currentLocation: (state) => {
      return state.currentLocation
    },
    selectedMarker: (state) => {
      return state.selectedMarker
    },
    basePoint:(state) => {
      return state.basePoint
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    setMapCenter: ({ commit }, value) => {
      commit('setMapCenter', value)
    },
    setMapZoom: ({ commit }, value) => {
      commit('setMapZoom', value)
    },
    setZoomControl: ({ commit }, value) => {
      commit('setZoomControl', value)
    },
    setMapBounds: ({ commit }, value) => {
      commit('setMapBounds', value)
    },
    setCurrentLocation: ({ commit }, value) => {
      commit('setCurrentLocation', value)
    },
    setSelectedMarker: ({ commit }, value) => {
      commit('setSelectedMarker', value)
    },
    setBasePoint: ({ commit }, value) => {
      commit('setBasePoint', value)
    },
  }
}
