import Vue from 'vue'
import VueRouter from 'vue-router'
import isMobile from 'ismobilejs'
import { auth, onAuthStateChanged } from '@/firebase/config'
import  userRoutes from './route/user'
import  adminRoutes from './route/admin'

Vue.use(VueRouter)

// 初期ロードの負荷を抑えるためにWebpackのCode Splittingを用いたDynamic Importを使用する
const loadPage = (view) => {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/pages/${view}.vue`)
}

const routes = [
  ...userRoutes.map(r => ({ ...r, component: loadPage(r.loadPage),})),
  ...adminRoutes.map(r => ({ ...r, component: loadPage(r.loadPage),}))
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  // ページ遷移ごとにスクロールが上端に戻る挙動となる。
  // savedPositionはオプションで、指定すると戻る/進むボタンによる遷移を行った際、そのページにいた状態のスクロールが再現される。
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// TODO: shop, space, adminの各ドメインに向けたルーティング修正
router.beforeEach((to, from, next) => {
  // let requiresAuth = to.matched.some((record) => record.meta.requiredAuth)
  // let currentUser = auth.currentUser

  // モバイル環境以外で{ appDomain: 'user' }ページにアクセスした際のリダイレクト
  if (
    !to.path.includes('access_redirect') && // リダイレクト案内ページへのダイレクトアクセス回避
    to.matched.some(record => record.meta.appDomain === 'user')
  ) {
    if (isMobile().phone && to.path.includes('/pc/map')) {
      // PCマップへのモバイルでのアクセスはモバイルマップへ飛ばす
      next({ path: '/' })
      return
    }

    // PC版マップ以外でuserドメインにモバイル以外がアクセスした場合はリダイレクトページへ
    if (!isMobile().phone && !to.path.includes('/pc/map')) {
      to.path.includes('login') 
        ? next({ path: '/login_access_redirect' })
        : next({ path: '/register_access_redirect' })
      return
    }
  }

  if (to.matched.some(record => record.meta.requiredAuth)) {
    // すでに認証済みの場合は遷移
    if (auth.currentUser) {
      next()
      return
    }
    // まだ認証されていない場合は Firebase SDK からのコールバックを待つ
    onAuthStateChanged(auth, user => {
      if (user) {
        next()
      } else {
        next({
          path: `/${to.meta.appDomain}/login`,
          query: { redirect: to.fullPath },
        })
      }
      return
    })
  }
  next()
})

export default router
