<template>
  <div v-if="placeDetail" class="app-user-event-detailr">
    <!-- Header -->
    <div  class="header-wrapper">
      <div class="like-button-wrapper">
        <LikeButton
          :liked="isLiked" 
          :isProcessing="isProcessing"
          :width="'46px'"
          :height="'46px'"
          @like-button-clicked="$_switchDisplayStyle"
        />
      </div>
      <div class="event-detail-chip-wrapper">
        <Chip
          :title="'イベント'"
          :textColor="'#fff'"
          :backgroundColor="'#95BB00'"
        />
        <Chip
          v-if="addedByUser"
          :title="'ユーザー投稿'"
          :textColor="'#fff'"
          :backgroundColor="'#A5A5A5'"
        />
      </div>
      <div class="title-detail-wrapper">
        <div class="event-name">{{ eventName }}</div>
        <div class="like-count-wrapper">
          <b-icon
            v-bind:icon="'heart-fill'"
            aria-hidden="true" dark
          />
          <span class="like-count-number">
            {{ likesCount }}
          </span>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="body-wrapper">
      <b-card no-body>
        <!-- Images -->
        <div class="event-image-wrapper">
          <AppUserImageBox
            v-if="thumbnailUrl"
            :src="thumbnailUrl"
            :width="'100%'"
            :height="'300px'"
          />
          <img
            v-else
            class="event-default-image"
            :src="defaultImage"
          />
        </div>
        <!-- サムネイル引用元URL -->
        <div v-if="copyRightUrl" class="copyright-url-wrapper">
          引用：
          <a
            class="copyright-url-wrapper"
            target="_blank"
            rel="noopener" 
            :href="copyRightUrl"
          >
            {{ copyRightUrl }}
          </a>
        </div>
        <!-- スペース名 -->
        <div class="event-detail-item-wrapper">
          <div class="event-detail-item-title">
            スペース名
          </div>
          <p class="event-detail-item-text">
            {{ spaceName }}
          </p>
        </div>
        <!-- 開催日 -->
        <div class="event-detail-item-wrapper">
          <div class="event-detail-item-title">
            開催日
          </div>
          <p
            class="event-detail-item-text mb-0"
            v-for="(dates, index) in displayHeldDateArray"
            :key="`held-date-${index}`"
          >
            {{ dates }}
          </p>
        </div>
        <!-- 募集期間 -->
        <div
          v-if="displayTermBegin ||   displayTermEnd"
          class="event-detail-item-wrapper"
        >
          <div class="event-detail-item-title">
            募集期間
          </div>
          <div v-if="displayTermBegin || displayTermEnd">
            <p v-if="displayTermBegin" class="event-detail-item-text">
              開始：{{ displayTermBegin }}
            </p>
            <p v-if="displayTermEnd" class="event-detail-item-text">
              終了：{{ displayTermEnd }}
            </p>
          </div>
        </div>
        <!-- 掲載終了日 -->
        <div class="event-detail-item-wrapper">
          <div class="event-detail-item-title">
            掲載終了日
          </div>
          <p class="event-detail-item-text">
            {{ displayEndOfPublicationDate }}
          </p>
        </div>
        <!-- Map -->
        <div class="access-map-container">
          <div class="access-map-title">
            アクセス
          </div>
          <div class="access-map">
            <MapContents
              :showUiLayer="false"
              :places="[placeDetail]"
              :mapCenter="placeDetail.coordinates"
              :mapZoom="16"
              :mapOptions="mapOptions"
              @map-loadend="({ map }) => {map.setZoom(18)}"
            />
            <!-- <p 
              @click="$_onClickStreetView" 
              class="open-streetview"
            >
              Googleマップを別ウィンドウで開く
            </p> -->
          </div>
          <p class="open-streetview" @click="$_onClickStreetView">
            Googleマップを別ウィンドウで開く
          </p>
          <p class="pin-position-remarks">
            ※ピンの位置は住所情報をもとに取得されるため、正確な位置からずれて表示される場合や、実際の出店場所とは異なる場合がありますのでご注意ください。
          </p>
        </div>
        <!-- Address -->
        <div class="address-container">
          <div class="address-title">
            住所
          </div>
          <div class="address-detail">
            <p class="zip-code">〒{{ address.zip }}</p>
            <p class="event-detail-item-text">
              {{ address.prefecture }}{{ address.area }} {{ address.address }}
            </p>
          </div>
        </div>
        <!-- Apply Url -->
        <div class="event-detail-item-wrapper">
          <div class="event-detail-item-title">
            イベント情報掲載ページ
          </div>
          <div class="apply-url-detail">
            <a
              class="apply-urls"
              target="_blank"
              rel="noopener noreferrer"
              :href="eventInfoUrl"
              :style="{ fontSize: '12px' }"
            >
              {{ eventInfoUrl }}
            </a>
          </div>
        </div>
        <!-- Remarks -->
        <div v-if="remarks" class="ramarks-container">
          <div class="ramarks-title">
            備考
          </div>
          <div>
            <CommonTextarea
              :prefix="'space-remarks'"
              :shown="shown"
              :readonly="true"
              :userStyle="{ margin: '8px 0', fontSize: '14px' }"
              :remarks="remarks"
            />
          </div>
        </div>
        <!-- note -->
        <p class="event-detail-note">
          ※記載されている内容は調査時の情報ですので、最新の情報<br />
          とは異なる場合がございます。正式な情報は公式の情報を<br />
          ご確認ください。
        </p>
        <!-- AD -->
        <div
          v-if="showAds"
          :style="{
            width: '100%',
            margin: '32px 0 48px 0'
          }"
        >
          <img width="100%" src="@/assets/image/ad_sample.png" />
        </div>
        <!-- Spacer when no AD -->
        <div
          v-else
          :style="{
            width: '100%',
            marginBottom: '54px'
          }"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import MapContents from '@/components/molecules/user/AppUserMapContents'
import LikeButton from '@/components/molecules/user/AppUserLikeButton'
import AppUserImageBox from '@/components/molecules/user/AppUserImageBox'
import Chip from '@/components/molecules/user/AppUserChip.vue'
import {
   getSummaryEventTerm,
   showBottomInfoToast,
   showRequireLoginPopup
} from '@/helper/common'
import { auth } from '@/firebase/config'
import { getEvent } from '@/helper/firestore/event'
import { updateLikes } from '@/helper/user'
import inobounce from 'inobounce'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserEventDetail',
  components: {
    CommonTextarea,
    MapContents,
    AppUserImageBox,
    Chip,
    LikeButton,
  },
  data() {
    return {
      placeDetail: null,
      isProcessing: false,
      shown: false,
      mapOptions: {
        disableDefaultUI: false,
        mapTypeControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        keyboardShortcuts: false,
        zoomControl: true,
        streetViewControl: false,
        gestureHandling: 'cooperative'
      }
    }
  },
  mounted: async function(){
    // enable pull down bounce
    inobounce.disable()

    this.$store.dispatch('loadingMask/showLoadingMask')
    try {
      this.placeDetail = await getEvent(this.selectedPlaceId)

      //FIXME: 待たないとtextarea の scrollHeight がとれない
      await this.$nextTick()
      this.shown = true
    } catch (error) {
      alert(error)
    } finally {
      this.$store.dispatch('loadingMask/hideLoadingMask')
    }
  },
  computed: {
    ...mapGetters(
      'map',
      [ 'mapCenter', 'mapZoom' ]
    ),
    places() {
      return this.$store.getters['place/validChunk']
    },
    selectedPlace() { // Chunkデータ
      return this.$store.getters['place/selectedPlace']
    },
    selectedPlaceId() {
      return this.$store.getters['place/selectedPlaceId']
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    toastText: function () {
      return this.isLiked ? 'お気に入りに追加しました。' : 'お気に入りから外しました。'
    },
    hertIconType: function () {
      return this.isLiked ? 'heart-fill' : 'heart'
    },
    isLiked: function () {
      return this.placeDetail
        ? this.likesPlaceIds.includes(this.placeDetail.placeId)
        : false
    },
    likesPlaceIds: function () {
      return this.$store.getters['user/likes'] || []
    },
    likesCount: function () {
      return (this.placeDetail && this.placeDetail.likes)
        ? Number(this.placeDetail.likes)
        : 0
    },
    heldDates: function () {
      return (this.placeDetail && this.placeDetail.heldDates)
        ? this.placeDetail.heldDates
        : ''
    },
    copyRightUrl: function () {
      return (this.placeDetail && this.placeDetail.copyRightUrl)
        ? this.placeDetail.copyRightUrl
        : ''
    },
    eventInfoUrl: function () {
      return (this.placeDetail && this.placeDetail.eventInfoUrl)
        ? this.placeDetail.eventInfoUrl
        : ''
    },
    remarks: function () {
      return (this.placeDetail && this.placeDetail.remarks)
        ? this.placeDetail.remarks
        : ''
    },
    displayHeldDateArray: function () {
      const data = this.$_parseCsvData(this.heldDates)
      return getSummaryEventTerm(data)
    },
    termBeginDate: function () {
      return (this.placeDetail && this.placeDetail.termBeginDate)
        ? this.placeDetail.termBeginDate
        : ''
    },
    displayTermBegin: function () {
      const data = this.$_parseCsvData(this.placeDetail.termBeginDate)
      return data.length && data[0]
        ? moment(data[0]).format('YYYY年MM月DD日(ddd)')
        : ''
    },
    termEndDate: function () {
      return (this.placeDetail && this.placeDetail.termEndDate)
        ? this.placeDetail.termEndDate
        : ''
    },
    displayTermEnd: function () {
      const data = this.$_parseCsvData(this.placeDetail.termEndDate)
      return data.length && data[0]
        ? moment(data[0]).format('YYYY年MM月DD日(ddd)')
        : ''
    },
    endOfPublicationDate: function () {
      return (this.placeDetail && this.placeDetail.endOfPublicationDate)
        ? this.placeDetail.endOfPublicationDate
        : ''
    },
    displayEndOfPublicationDate: function () {
      const data = this.$_parseCsvData(this.placeDetail.endOfPublicationDate)
      return Array.isArray(data) && data.length
        ? moment(data[0]).format('M月D日(ddd)')
        : ''
    },
    showLoadingMask: function () {
      return this.$store.getters.showLoadingMask
    },
    addedByUser: function () {
      return this.placeDetail.addedBy
        ? this.placeDetail.addedBy === 'user'
        : false
    },
    eventName: function () {
      return this.placeDetail.eventName
    },
    spaceName: function () {
      return this.placeDetail.spaceName
    },
    thumbnailUrl: function () {
      return this.placeDetail && this.placeDetail.exteriorImage
        ? this.placeDetail.exteriorImage
        : ''
    },
    title: function () {
      return this.placeDetail.eventName
        ? `${this.placeDetail.facilityName}/${this.placeDetail.eventName}`
        : this.placeDetail.facilityName
    },
    address: function() {
      return {
        zip: this.placeDetail.zip,
        prefecture: this.placeDetail.prefecture,
        area: this.placeDetail.area,
        address: this.placeDetail.address
      }
    },
    defaultImage: function () {
      return require('@/assets/image/place_default.png')
    }
  },
  methods: {
    $_switchDisplayStyle: async function () {
      try {
        if (!auth.currentUser || !auth.currentUser.emailVerified) {
          this.$_showRequireLoginPopup()
        } else {
          this.isProcessing = true

          // リモート、ストアのデータを更新
          await updateLikes(this.selectedPlace)
          // リモートデータ再取得
          this.placeDetail = await getEvent(this.selectedPlaceId)

          this.$_showBottomInfoToast('info', this.toastText, 1500)
        }
      } catch (error) {
        this.$_showBottomInfoToast('error', `お気に入りの変更に失敗しました ${error}`, 1500)
      } finally {
        this.isProcessing = false
      }
    },
    $_showRequireLoginPopup: function () {
      // customClassを定義すること
      // FIXME: customClassはscssで共通化する
      showRequireLoginPopup(
        'middle',
        {
          close: () => {},
          login: () => {
            // モーダルをとじる
            this.$store.dispatch('modal/setModalId', 'map')
            this.$router.push('/user/login')
          },
          signup: () => {
            // モーダルをとじる
            this.$store.dispatch('modal/setModalId', 'map')
            this.$router.push('/user/signup')
          },
        },
      )
    },
    /**
     * ストリートビューへのリンクへ遷移
     */
    $_onClickStreetView: function () {
      const coords = `
        ${this.placeDetail.coordinates.lat},
        ${this.placeDetail.coordinates.lng}
      `
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${coords}`,
        '_blank',
        'noreferrer'
      )
    },
    $_parseCsvData: function (data) {
      // 2種類のダブルクォーテーションと改行が交じることへの対処(CSVデータがソースのため)
      return data.replace(/”/g, '').replace(/"/g, '').replace(/\n/g, '').split(",")
    },
    /**
     * 画面下部にトーストを表示
     * @param {String} type トーストの種類(info, error,,,)
     * @param {String} text 表示テキスト
     * @param {Number} timer 表示時間(ms)
     */
    $_showBottomInfoToast: function (type, text, timer) {
      showBottomInfoToast(type, text, timer)
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-event-detailr {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .header-wrapper {
    position: relative;
    padding: 36px 20px 16px 20px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
    .like-button-wrapper { 
      position: absolute;
      top: 12px;
      right: 20px;
      z-index: 9999;
    }
    .event-detail-chip-wrapper {
      display: flex;
      column-gap: 8px;
      justify-content: flex-start;
      align-items: center;
      color: #707070;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 999;
    }
    .title-detail-wrapper {
      position: sticky;
      top: 20px;
      z-index: 999;
      background-color: #fff;
      .event-name {
        color: #707070;
        padding: 8px 0;
      }
      .like-count-wrapper {
        color: #12B6D4;
        display: flex;
        align-items: center;
        font-weight: bold;
        .like-count-number {
          margin: 0 0 0 8px;
        }
      }
    }
  }
  .body-wrapper {
    padding: 0 20px;
    /* overwrite */
    .card {
      border: none;
    }
    .event-image-wrapper {
      width: 100%;
      max-height: 300px;
      .event-default-image {
        width: 100%;
      }
    }
    .copyright-url-wrapper {
      color: #707070;
      font-size: 12px;
      margin-top: 16px;
      .copyright-url-wrapper {
        color: #707070;
        font-size: 12px;
      }
    }
    .event-detail-item-wrapper {
      color: #707070;  
      .event-detail-item-title {
        margin: 2rem 0 1.25rem 0;
      }
    }
    .ramarks-container {
      color: #707070;
      margin-bottom: 16px;
      .ramarks-title {
        margin: 2rem 0 1.25rem 0;
      }
    }
    .apply-urls {
      color: #707070 !important;  
      font-size: 16px;
      text-decoration: underline;
    }
    .event-detail-item-text {
      font-size: 14px;
      margin-bottom: 0;
    }
    .access-map-container {
      color: #707070;  
      .access-map-title {
        margin: 2rem 0 1.25rem 0;
      }
      .access-map {
        height: 260px;
      }
      .open-streetview {
        text-decoration: underline;
        font-size: 12px;
        line-height: 36px;
      }
      .pin-position-remarks {
        color: #707070;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .address-container {
      color: #707070;  
    }
    .address-title {
      margin: 2rem 0 1.25rem 0;
    }
    .zip-code {
      font-size: 13px;
    }
    .event-detail-note {
      color: #707070;
      font-size: 12px;
    }
  }
}
</style>