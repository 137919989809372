var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"review",attrs:{"modal-class":"common-modal","dialog-class":"common-modal-dialog","content-class":"review-modal-content","header-class":"review-modal-header","body-class":"common-modal-body","hide-header-close":"","hide-footer":"","hide-backdrop":"","scrollable":"","no-fade":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('Header',{on:{"left-arrow-click":() => {
          _vm.$store.dispatch('modal/setModalId', 'space-detail')
          _vm.hideModal()
        },"logo-click":() => {
          _vm.$store.dispatch('modal/setModalId', 'map')
          _vm.hideModal()
        }}})]},proxy:true}])},[_c('Review',{on:{"show-preview-click":() => _vm.$refs['review-preview'].showModal(),"value-change":(value) => _vm.$_setValue(value)}})],1),_c('ReviewPreviewModal',{ref:"review-preview",attrs:{"userType":_vm.userType,"review":_vm.review}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }