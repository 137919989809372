import { WEEK_CHARS } from '@/constants/common'
import Swal from 'sweetalert2'
import moment from 'moment'
// storeにアクセスするため
import store from '@/store/index.js'

export const getPageFlameState = (page) => {
  // Set default state
  const state = {
    backArrow: false,
    searchBar: false,
    footerMenu: false,
    toTopBottom: false,
    backgroundColor: '#fff'
  }

  switch (page) {
    case 'map':
      state.searchBar = true
      state.footerMenu = true
      break
    case 'list':
      state.searchBar = true
      state.footerMenu = true
      state.backgroundColor = '#bfbfbf38'
      break
    case 'likes':
      state.searchBar = false
      state.backArrow = true
      state.footerMenu = true
      state.backgroundColor = '#bfbfbf38'
      break
    case 'others':
      state.searchBar = false
      state.backArrow = true
      state.footerMenu = true
      state.backgroundColor = '#FFFFFF'
      break
    case 'location_setting':
      state.searchBar = false
      state.backArrow = true
      state.footerMenu = true
      state.backgroundColor = '#FFFFFF'
      break
    case 'add_to_homescreen':
      state.searchBar = false
      state.backArrow = true
      state.footerMenu = true
      state.backgroundColor = '#FFFFFF'
      break
    default: 
      break
  }

  return state
}

export const toLocaleString = (date) => {
  const partDates = date.split('/')
  const dayOfWeek = new Date(partDates[0], partDates[1] - 1, partDates[2])
  return `${partDates[0]}年${partDates[1]}月${partDates[2]}日 (${WEEK_CHARS[dayOfWeek.getDay()]})`
}

export const showTopPopup = (text) => {
  Swal.fire({
    icon: 'info',
    iconColor: '#F56350',
    type: 'success',
    toast: true,
    showConfirmButton: false,
    timer: 3000,  //3秒経過後に閉じる
    html: text,
    position : 'top',
    customClass: {
      container: 'container-offset',
      popup: 'display-flex'
    },
    target: document.getElementById('body')
  })
} 

export const showInfoPopupAlert = (text, withIcon=true, options={}, callback=null) => {
  Swal.fire({
    icon: withIcon ? 'info' : '',
    iconColor: '#12B6D4',
    confirmButtonText: '閉じる',
    confirmButtonColor: '#12B6D4',
    html: `<p>${text}</p>`,
    ...options
  }).then((result) => {
    if (result.isConfirmed) callback && callback()
  })
}

export const showErrorPopupAlert = (text) => {
  Swal.fire({
    icon: 'error',
    confirmButtonText: '閉じる',
    confirmButtonColor: '#12B6D4',
    html: `<p>${text}</p>`,
  })
}

export const showBottomInfoToast = (type, text, timer, width = 340) => {
  const iconColor = type !== 'error' ? '#12B6D4' : ''
  Swal.fire({
    toast: true,
    width: width,
    position: 'bottom',
    icon: type,
    iconColor: iconColor,
    target: document.body,
    html: `<div style="position: fixed; font-size: 14px; "><p>${text}</p></div>`,
    showConfirmButton: false,
    timer: timer
  })
}

export const showConfirmPopup = async (title, confirmButtonText, calcelButtonText, options={}) => {
  return await Swal.fire({
    // title: title,
    html: `<div style="font-size: 18px; margin-top: 24px; "><p>${title}</p></div>`,
    showCancelButton: true,
    confirmButtonColor: '#12B6D4',
    confirmButtonText: confirmButtonText,
    cancelButtonText: calcelButtonText,
    ...options
  })
}

export const openInquiryForm = () => {
  const URL = 'https://docs.google.com/forms/d/e/1FAIpQLSepG3MOTJN6hKO3EVvOL8q-_xPG5PcEUqRUDZOrBw7ZbyYrAQ/viewform'
  window.open(URL, "_blank", "noreferrer")
}

export const openTwitterLink = (url) => {
  window.open(url, "_blank", "noreferrer")
}

/**
 * 
 * @param {Array.<Object>} reservations 
 * @returns {Array.<String>}
 */
export const getSummaryTerm = (reservations) => {
  if (!reservations.length) return ''

  // sort
  reservations.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateA - dateB
  })

  if (reservations.length === 1) {
      return [toLocaleString(reservations[0].date)]
  } else {
    const result = [[]]
    let currentIndex = 0
    for (let i = 0; i < reservations.length; i++) {
      const date = new Date(reservations[i].date)
      if (i === 0) {
        // first
        result[currentIndex] = [date]
        continue
      }
      
      const prevDate = new Date(reservations[i - 1].date)
      const compareTime = new Date(date.getTime())
      compareTime.setDate(date.getDate() - 1)

      if (prevDate.getTime() == compareTime.getTime()) {
        // sequencal date
        if (!result[currentIndex]) result[currentIndex] = []
        result[currentIndex].push(date)
      } else {
        // separate date (push to new array)
        if (result[currentIndex]) currentIndex += 1
        result[currentIndex] = [date]
      }
    }

    // convert to dispay string array
    return result.map(r => (
      r.length == 1
        ? moment(r[0]).format('YYYY年MM月DD日 (ddd)')
        : `${moment(r[0]).format('YYYY年MM月DD日 (ddd)')} 〜 ${moment(r[r.length - 1]).format('YYYY年MM月DD日 (ddd)')}`
    ))
  }
}

/**
 * 
 * @param {String} dates 
 * @returns {Array.<String>}
 */
 export const getSummaryEventTerm = (dates) => {
  if (!dates.length) return []

  // sort
  dates.sort((a, b) => {
    const dateA = new Date(a)
    const dateB = new Date(b)
    return dateA - dateB
  })

  if (dates.length === 1) {
      return [toLocaleString(dates[0])]
  } else {
    const result = [[]]
    let currentIndex = 0
    for (let i = 0; i < dates.length; i++) {
      const date = new Date(dates[i])
      if (i === 0) {
        // first
        result[currentIndex] = [date]
        continue
      }
      
      const prevDate = new Date(dates[i - 1])
      const compareTime = new Date(date.getTime())
      compareTime.setDate(date.getDate() - 1)

      if (prevDate.getTime() == compareTime.getTime()) {
        // sequencal date
        if (!result[currentIndex]) result[currentIndex] = []
        result[currentIndex].push(date)
      } else {
        // separate date (push to new array)
        if (result[currentIndex]) currentIndex += 1
        result[currentIndex] = [date]
      }
    }

    // convert to dispay string array
    return result.map(r => (
      r.length == 1
        ? moment(r[0]).format('YYYY年MM月DD日(ddd)')
        : `${moment(r[0]).format('YYYY年MM月DD日(ddd)')} 〜 ${moment(r[r.length - 1]).format('YYYY年MM月DD日(ddd)')}`
    ))
  }
}

/**
 * 
 * @param {*} placeId 
 * @param {*} value 
 */
export const updateLocalChunk = async (placeId, value) => {
  const chunks = [ ...store.getters['place/chunks'] ]
  const index = chunks.findIndex(c => c.placeId === placeId)
  chunks[index] = { ...chunks[index], ...value }
  store.dispatch('place/setChunks', chunks)
}

export const getChunkFromLocal = (placeId) => {
  return store.getters['place/chunks'].find(c => c.placeId === placeId)
}

/**
 * 
 * @param {*} a 
 * @param {*} b 
 * @param {*} order 
 * @returns 
 */
export const sortByNum = (a, b, order) => {
  if (!a || !b) return
  // 「昇順」は古い順、「降順」は新しい順
  if (order === 'desc') {
    return a < b ? 1 : -1
  } else if (order === 'asc') {
    return b < a ? 1 : -1
  } else {
    return 0
  }
}

/**
 * 日付情報のソート結果を返す
 * 引数は Firestore の Timestamp か { seconds: aaa, nanoseconds: bbb } の形式
 * @param {Object} a
 * @param {Object} b 
 * @param {String} order ソート順 
 * @return Array.sortに渡す結果数値
 */
export const sortByDate = (a, b, order='desc') => {
  if (!a || !b) return 0
  const aMillis  = a.seconds * 1000 + a.nanoseconds / 1000000
  const bMillis  = b.seconds * 1000 + b.nanoseconds / 1000000
  if (order === 'desc') {
    return aMillis < bMillis ? 1 : -1
  } else if (order === 'asc') {
    return bMillis < aMillis ? 1 : -1
  } else {
    return 0
  }
}

export const showRequireLoginPopup = (position, callback, customClass={}) => {
  Swal.fire({
    width: 340,
    html: `
      <p style="margin-bottom: 8px; font-size: 15px; font-weight: bold;">
        「お気に入り」のご利用は会員登録が必要です
      </p>
    `,
    position: position,
    focusConfirm: false,
    showCloseButton: true,
    showConfirmButton: true,
    showDenyButton: true,
    confirmButtonText: 'ログイン',
    denyButtonText: '会員登録',
    backdrop: false,
    customClass: {
      htmlContainer: 'custom-popup-container',
      actions: 'custom-popup-action',
      denyButton: 'custom-popup-deny-button',
      confirmButton: 'custom-popup-confirm-button',
      closeButton: 'custom-popup-close-button',
      ...customClass
    },
    timer: 5000
  }).then((result) => {
    if (
      result.dismiss === 'close' ||
      result.dismiss === 'timer'
    ) {
      callback.close()
    } else if (result.isConfirmed) {
      callback.login()
    } else {
      callback.signup()
    }
  })
}
export const getMaxLength = (maxLength, input, isMobile) => {
  /**
   * 
   * Textareaの仕様で、value(入力したテキスト)の扱いがに以下の種類がある
   * 
   * *** 注：モバイルのWebkitのみの仕様 ***
   *
   *  - raw value        : ユーザーが入力したそのままの値
   *  - API value        : jsエンジンが触れる値(textarea.valueと同じ)で、改行コードはLFになってる
   *  - submission value : HTMLがフォームデータとして保持する値で改行コードはCR/LFになり、そのほかwrap属性も加味
   * 
   * つまり this.review の値は API value となり、改行コードがLFの1文字分である一方、Textarea自身が見ている値は
   * submission value となり、改行が CR/LF で2文字分のカウントとなる
   * 
   * この関数の処理では、exceptions はスペースと改行をすべて含む配列となり、exceptions.length を取ると改行の
   * カウントが１となる（要素数を撮っているだけなので、LF or CR/LF は気にしていない）
   * 
   * よってブラウザがWebkit系の場合のみ、改行を２文字としてカウントする（改行の数を抜き出してトータルに足す）
   * 
   * FIXME: 本来は AppTextare の責務であるため、本機能を AppTextarea に組み込むこと（AppUserObjectionにも重複する処理がある）
   * 
   */
  const exceptions = input.match(/[\s|\n|\r|\r\n]/g)
  if (exceptions) {
    if (isMobile) {
      if (window.navigator.userAgent.includes('WebKit')) {
        const lineBreaks = exceptions.filter(e => /\n/.test(e)).length
        return maxLength + (exceptions ? exceptions.length + lineBreaks : 0)
      } else {
        // Gecko(Firefox)
        return maxLength + (exceptions ? exceptions.length : 0)
      }
    } else {
      return maxLength + (exceptions ? exceptions.length : 0)
    }
  } else {
    return maxLength
  }
}