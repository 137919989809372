<template>
  <div>
    <b-modal
      ref="review-list"
      modal-class="common-modal"
      dialog-class="common-modal-dialog"
      content-class="review-modal-content"
      header-class="review-modal-header"
      body-class="common-modal-body"
      hide-header-close
      hide-footer
      hide-backdrop
      scrollable
      no-fade
    >
      <ReviewList
        :reviews="reviews"
        @show-objection="(review) => $_showObjectionModal(review)"
      />
      <template #modal-header>
        <Header
          @left-arrow-click="() => {
            $store.dispatch('modal/setModalId', 'space-detail')
            hideModal()
          }"
          @logo-click="() => {
            $store.dispatch('modal/setModalId', 'map')
            hideModal()
          }"
        />
      </template>
    </b-modal>

    <ObjectionModal ref="objection" />
  </div>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import ReviewList from '@/components/organisms/user/modals/contents/AppUserReviewList'
import ObjectionModal from '@/components/organisms/user/modals/AppUserObjectionModal'
import { getSpaceReviews } from '@/helper/firestore/review'
import { sortByDate } from '@/helper/common'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserReviewListModal',
  components: {
    Header,
    ReviewList,
    ObjectionModal
  },
  data() {
    return {
      userType: '',
      reviews: []
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    selectedPlaceId() {
      return this.$store.getters['place/selectedPlaceId']
    },
  },
  methods: {
    $_showObjectionModal(review) {
      this.$refs['objection'].showModal(review)
    },
    async $_setValidReviews() {
      const allReviews = await getSpaceReviews(this.selectedPlaceId)
      this.reviews = allReviews
        .filter(r => r.checked && r.publication)
        .sort(this.$_sortByDate)
    },
    $_sortByDate(a, b) {
      return sortByDate(a.createAt, b.createAt)
    },
    async showModal() {
      this.$store.dispatch('loadingMask/showLoadingMask')
      await this.$_setValidReviews()
      this.$store.dispatch('loadingMask/hideLoadingMask')
      this.$refs['review-list'].show()
    },
    hideModal() {
      this.$refs['review-list'].hide()
    },
  }
}
</script>
  
<style lang="scss">
.review-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.review-modal-header {
  flex-direction: column;
  align-items: center;
}
</style>