var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-review"},[_c('div',{staticClass:"review-list-header"},[_c('IconReview'),_c('p',{staticClass:"review-list-title-text"},[_vm._v("クチコミ一覧")]),_c('p',{staticClass:"review-list-title-count"},[_vm._v(_vm._s(_vm.reviews.length)+"件")])],1),_c('div',{staticClass:"review-list-body"},[_vm._l((_vm.itemsPerPage),function(review,index){return _c('div',{key:`review-${review.reviewId}`},[_c('div',{staticClass:"review-title-container",class:{ 'top-border': index === 0}},[_c('p',{staticClass:"review-post-user-type",class:_vm.$_getUserTypeClass(review.postUserType)},[_vm._v(" "+_vm._s(review.postUserType)+" ")]),_c('p',{staticClass:"review-create-at"},[_vm._v(" "+_vm._s(_vm.$_formatDate(review.createAt))+" ")])]),_c('CommonTextarea',{attrs:{"prefix":'user-review-list',"readonly":true,"remarks":review.review,"shown":_vm.shown,"userStyle":{
          width: '-webkit-fill-available',
          fontSize: '16px',
          margin: '8px 16px',
        }}}),_c('p',{staticClass:"objection-link",on:{"click":function($event){return _vm.$emit('show-objection', review)}}},[_vm._v(" 通報する ")]),(index > 0 && !((index + 1) % 3))?_c('div',[(_vm.showAds)?_c('div',{style:({
            width: '100%',
            margin: '16px 0',
            paddingBottom: '16px',
            borderBottom: 'solid 1px #C6C6C6'
          })},[_c('img',{attrs:{"width":"100%","src":require("@/assets/image/ad_sample.png")}})]):_vm._e()]):_vm._e()],1)}),(_vm.reviews.length < 3)?_c('div',[(_vm.showAds)?_c('div',{style:({
          width: '100%',
          margin: '16px 0'
        })},[_c('img',{attrs:{"width":"100%","src":require("@/assets/image/ad_sample.png")}})]):_vm._e()]):_vm._e(),(_vm.reviews.length)?_c('b-pagination',{staticClass:"review-list-pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_c('div',{staticClass:"review-button-container"},[_c('button',{staticClass:"review-button",on:{"click":() => {
          _vm.$store.dispatch('modal/setModalHistory', this.modalId)
          _vm.$store.dispatch('modal/setModalId', 'review')
        }}},[_vm._v(" クチコミを投稿する ")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }