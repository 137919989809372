<template>
  <div>
    <b-modal
      ref="objection"
      modal-class="common-modal"
      dialog-class="common-modal-dialog"
      content-class="review-modal-content"
      header-class="review-modal-header"
      body-class="common-modal-body"
      hide-header-close
      hide-footer
      hide-backdrop
      scrollable
      no-fade
    >
      <Objection
        :reviewForObjection="reviewForObjection"
        @send-objection="$_onSendObjection"
        @back-to-review-list-click="() => {
          $store.dispatch('modal/setModalId', 'review-list')
          hideModal()
        }"
      />
      <template #modal-header>
        <Header
          @left-arrow-click="() => {
            $store.dispatch('modal/setModalId', 'review-list')
            hideModal()
          }"
          @logo-click="() => {
            $store.dispatch('modal/setModalId', 'map')
            hideModal()
          }"
        />
      </template>
    </b-modal>
  </div>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import Objection from '@/components/organisms/user/modals/contents/AppUserObjection'
import { postObjection } from '@/helper/firestore/objection'
import { showInfoPopupAlert, showConfirmPopup, updateLocalChunk } from '@/helper/common'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserObjectionModal',
  components: {
    Header,
    Objection,
  },
  data() {
    return {
      reviewForObjection: {},
      objectionType: '',
      reviewId: ''
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    selectedPlace() { // Chunkデータ
      return this.$store.getters['place/selectedPlace']
    },
  },
  methods: {
    $_setValue(value) {
      this.objectionType = value.objectionType
    },
    async $_onSendObjection(objection) {
      const result = await showConfirmPopup(
        '通報しますか？',
        '通報する',
        `編集画面に戻る`,
        { customClass: { actions: 'custom-actions' }}
      )

     if (!result.isConfirmed) return

      this.$store.dispatch('loadingMask/showLoadingMask')

      try {
        const postResult = await postObjection(
          {
            category: objection.type,
            reason: objection.reason,
            reviewId: this.reviewForObjection.docId,
            spaceId: this.reviewForObjection.spaceId,
            spaceName: this.reviewForObjection.spaceName
          },
          // chunk
          this.selectedPlace
        )
        // 通報上限に達してクチコミが非公開化された場合、リモートのchunk.reviewが書き換わっている（-1）
        // ローカルでChunksをすべて取り直すのはリソースの無駄なので、対象Chunkデータのみ更新する
        if (postResult.reviewClosed) {
          await updateLocalChunk(
            this.selectedPlace.placeId,
            { review: Math.max(0, this.selectedPlace.review - 1) }
          )
        }

        showInfoPopupAlert(
          '',
          false,
          {
            html: `<img src="${require('@/assets/image/icon_action_result.svg')}" /><p style="margin-top: 2.28rem;">通報を受け付けました。</p>`,
            customClass: {
              popup: 'objection-action-result',
              actions: 'custom-actions'
            }
          }
        )

        this.$store.dispatch('modal/setModalId', 'space-detail')
        this.hideModal()
      } catch(error) {
        alert(`通報に失敗しました。${error}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    },
    showModal(review) {
      this.reviewForObjection = review
      this.$refs['objection'].show()
    },
    hideModal() {
      this.$refs['objection'].hide()
    }
  }
}
</script>
  
<style lang="scss">
.review-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.review-modal-header {
  flex-direction: column;
  align-items: center;
}
.custom-actions {
  flex-direction: column;
  width: 100% !important;
  button {
    width: 80%;
  }
}
.objection-action-result {
  padding: 48px 0 !important;
}
</style>