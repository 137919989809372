<template>
  <div class="app-user-likes">
    <div id="scroll-anchor" />

    <!-- Header -->
    <div class="bookmark-title">
      <IconFav class="bookmark-fav-icon" />
      <div class="bookmark-title-text">
        お気に入り一覧
      </div>
    </div>

    <p
      class="no-bookmark-title"
      v-if="!bookmarks || !bookmarks.length"
    >
      お気に入りスペース、イベントが<br />登録されていません
    </p>

    <!-- Card List -->
    <div
      v-for="(datum, index) in bookmarksParPage"
      :key="`bookmark-${datum.type}-${datum.placeId}`"
      class="bookmark-scroll-container"
    >
      <CarouselCard
        v-bind:placeId="datum.placeId"
        v-bind:imageHeight="100"
        v-bind:textHeight="100"
        v-bind:direction="'column'"
        v-on:card-touch-start="$_onCardTouchStart"
        v-on:card-touch-move="$_onCardTouchMove"
        v-on:card-touch-end="$_onCardTouchEnd"
        v-on:card-click="$_onCardClick"
      />
      <div v-if="index > 0 && !((index + 1) % 3)">
        <!-- AD -->
        <div
          v-if="showAds"
          :style="{
            width: '100%',
            margin: '16px 0'
          }"
        >
          <img width="100%" src="@/assets/image/ad_sample.png" />
        </div>
      </div>
    </div>

    <!-- 登録が0〜2件の場合も広告は1個表示 -->
    <div v-if="bookmarks.length < 3">
      <!-- AD -->
      <div
        v-if="showAds"
        :style="{ margin: '16px' }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>

    <b-pagination
      v-if="bookmarks.length"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
import CarouselCard from '@/components/organisms/user/AppUserCarouselCard.vue'
import IconFav from "@/assets/image/icon_bookmark_fav.svg?component"
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserLikes',
  components: {
    CarouselCard,
    IconFav,
  },
  data() {
    return {
      places: [],
      perPage: 12,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    likesPlaceIds: function () {
      // return this.$store.getters.bookmarkedPlaceIds
      return this.$store.getters['user/likes'] || []
    },
    bookmarks: function () {
      // pagenationに必要なlistIndexを埋め込み
      return this.places.map((d, index) => ({
        ...d,
        listIndex: index
      }))
    },
    bookmarksParPage: function () {
      const begin = (this.currentPage - 1) * this.perPage

      return this.bookmarks.slice(
        begin,
        begin + this.perPage
      )
    },
    rows: function () {
      return this.bookmarks ? this.bookmarks.length : 0
    },
  },
  watch: {
    likesPlaceIds: async function () {
      this.places = this.$store.getters['place/validChunk']
        .filter(p => this.likesPlaceIds.includes(p.placeId))
    },
    currentPage: function () {
      // スクロールを戻す
      document.getElementsByClassName('modal-body')[0].scrollTo(0, 0)
    },
  },
  mounted: async function(){
    this.$store.dispatch('loadingMask/showLoadingMask')

    // enable pull down bounce
    inobounce.disable()

    if (this.likesPlaceIds.length) {
      this.places = this.$store.getters['place/validChunk']
        .filter(p => this.likesPlaceIds.includes(p.placeId))
    }

    await this.$nextTick()
    this.$store.dispatch('loadingMask/hideLoadingMask')
  },
  methods: {
    /**
     * カードが選択された際にスクロール位置を記録
     */
    $_onCardClick: async function (target) {
      this.$store.dispatch('place/setSelectedPlace', target.placeId)
      // modalID更新前に履歴保存
      this.$store.dispatch('modal/setModalHistory', this.modalId)
      this.$store.dispatch('modal/setModalId', `${target.type}-detail`)
    },
    $_onCardTouchStart: function (event) {
      console.log(event)
    },
    $_onCardTouchMove: function (event) {
      console.log(event)
    },
    $_onCardTouchEnd: function (event) {
      console.log(event)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-likes {
  width: 100%;
  height: 100%;
}
.bookmark-scroll-container {
  margin: 1px 1rem 0 1rem;
}

/*
 * Header
 */
.bookmark-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 1rem;
  position: sticky;
  top: -1px;
  z-index: 999;
  background-color: #F5F5F5;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;

  .bookmark-fav-icon {
    margin-right: 8px;
  }
  .bookmark-title-text {
    color: #707070;
    font-size: 20px;
  }
}
.no-bookmark-title {
  color: #707070;
  font-size: 12px;
  text-align: center;
  margin: 16px 0 32px 0;
}
/* overwrite pagenation style */
::v-deep(.page-item) {
  margin: 0px 6px;
}
::v-deep(.page-link) {
  color: #12B6D4 !important;
  border: solid 1px #12B6D4;
  background-color: #fff !important;
}
::v-deep(.active) {
  .page-link {
    color: #fff !important;
    border: solid 1px #12B6D4 !important;
    background-color: #12B6D4 !important;
  }
}
::v-deep(.disabled) {
  display: none;
}
</style>