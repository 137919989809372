<template>
  <b-modal
    ref="event-detail"
    modal-class="common-modal"
    header-class="'event-detail-modal-header'"
    dialog-class="common-modal-dialog"
    body-class="common-modal-body"
    content-class="event-detail-modal-content"
    hide-header-close
    hide-footer
    hide-backdrop
    scrollable
    no-fade
  >
    <EventDetail :target="selectedPlace"/>
    <template #modal-header="{ close }">
      <Header
        @left-arrow-click="() => {
          if (modalHistory && modalHistory[0] === 'list') {
            $store.dispatch('modal/setModalId', 'list')
          } else if (modalHistory && modalHistory[0] === 'likes') {
            $store.dispatch('modal/setModalId', 'likes')
          } else {
            $store.dispatch('modal/setModalId', 'map')
          }
          close()
        }"
        @logo-click="() => {
          if (modalHistory && modalHistory[0] === 'list') {
            $store.dispatch('modal/setModalId', 'list')
          } else if (modalHistory && modalHistory[0] === 'likes') {
            $store.dispatch('modal/setModalId', 'likes')
          } else {
            $store.dispatch('modal/setModalId', 'map')
          }
          close()
        }"
      />
    </template>
  </b-modal>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import EventDetail from '@/components/organisms/user/modals/contents/AppUserEventDetail' 
import { mapGetters } from 'vuex' 

export default {
  name: 'AppUserEventDetailModal',
  components: {
    Header,
    EventDetail
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalHistory' ]
    ),
    selectedPlace() {
      return this.$store.getters['place/selectedPlace']
    },
  },
  methods: {
    showModal() {
      this.$refs['event-detail'].show()
    },
    hideModal() {
      this.$refs['event-detail'].hide()
    }
  }
}
</script>
  
<style lang="scss">
.event-detail-modal-header {
  align-items: center;
}
.event-detail-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
</style>