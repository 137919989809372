<template>
  <b-modal
    ref="others"
    modal-class="common-modal"
    dialog-class="common-modal-dialog"
    content-class="others-modal-content"
    header-class="others-modal-header"
    body-class="common-modal-body"
    hide-header-close
    hide-footer
    hide-backdrop
    scrollable
    no-fade
  >
    <Others
      :targetPage="othersPage"
      @change-page="(page) => { $emit('others-page-change', page) }"
    />
    <template #modal-header>
      <Header
        @left-arrow-click="() => {
          if (modalHistory.length && modalHistory[0] === 'review') {
            // review(space detail) -> review guide line -> review(space detail)
            const history = [...modalHistory]
            $store.dispatch('modal/setModalId', history.shift()) 
            $store.dispatch('modal/replaceModalHistory', history)
            hideModal()
          } else {
            $emit('others-left-arrow-clicke')
          }
        }"
        @logo-click="() => { $emit('others-logo-click', 'map') }"
      />
    </template>
  </b-modal>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import Others from '@/components/organisms/user/modals/contents/AppUserOthers'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserOthersModal',
  props: {
    othersPage: {
      type: String,
      default: 'others'
    }
  },
  components: {
    Header,
    Others
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId', 'modalHistory' ]
    ),
  },
  methods: {
    showModal() {
      this.$refs['others'].show()
    },
    hideModal() {
      this.$refs['others'].hide()
    }
  }
}
</script>
  
<style lang="scss">
.others-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.others-modal-header {
  flex-direction: column;
  align-items: center;
}
</style>