export const initialState = {
  /* Account Info Stored Firestore */
  accessCount: 0,
  lastAccessDate: null,
  /* Calender */
  selectedDates: [],
  /* Chip */
  distanceFilterDirection: 'init',
  likeFilterDirection: 'off',
  /* Common (includes not classified)*/
  bodyContentsName: '',
  showLoadingMask: false,
  scrollPosition: 0,
  bookmarkedPlaceIds: [],
  checkedNewsIds: [],
  currentListPage: 1,
}