import { db, doc, getDoc, updateDoc } from '@/firebase/config'
import { USER_DOCS_NAME } from '@/constants/firestore'
import { projectFunctions, httpsCallable  } from '@/firebase/config'

/********************************
 * Account (Error: 1**)
 *******************************/

/**
 * ユーザー情報取得
 * @param {String} uid 
 */
export const getAccount = async (uid) => {
  try {
    if (!uid) throw new Error ('100')

    const docRef = doc(db, USER_DOCS_NAME, uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) return docSnap.data()

    throw new Error ('101')
  } catch (error) {
    // throw new Error(`アカウントデータ取得失敗<br />${error}`)
    console.log(`アカウントデータ取得失敗<br />${error}`)
  }
}
/**
 * ユーザーデータを更新（Firebase Authenticationとは別のFirestoreのデータ）
 * @param {String} uid 
 * @param {Object} data 
 * @returns Object
 */
export const updateUser = async (uid, data) => {
  if (!uid || !data) throw new Error ('101')
  
  try {
    const docRef = doc(db, USER_DOCS_NAME, uid)
    await updateDoc(docRef, data)
    return { status: 'success' }
  } catch (error) {
    throw new Error(`ユーザーデータ更新失敗<br />${error}`)
  } 
}

/**
 * 通報受付メール送信
 * @param {String} spaceName 
 * @param {String} category 
 * @returns 
 */
export const sendObjectionNotification = async (spaceName, category) => {
  try {
    const sendObjectionNotificationMail = httpsCallable(
      projectFunctions,
      'sendObjectionNotificationMail'
    )
    const rs = await sendObjectionNotificationMail({
      env: process.env.NODE_ENV,
      objectiosn: {
        spaceName: spaceName,
        category: category 
      }
    })
    if (rs.data.response.includes('OK')) {
      // success
      return
    } else {
      throw new Error(`通報受付メール送信失敗1<br />${rs.data.response}`)
    }
  } catch(error) {
    throw new Error(`通報受付メール送信失敗2<br />${error}`)
  }
}

/**
 * 会員登録完了メール送信
 * @param {String} to 宛先メールアドレス
 * @returns 
 */
export const sendRegistrationCompletionEmail = async (to) => {
  try {
    const sendRegistrationCompletionEmail = httpsCallable(
      projectFunctions,
      'sendRegistrationCompletionEmail'
    )
    const rs = await sendRegistrationCompletionEmail({to: to})
    if (rs.data.response.includes('OK')) {
      // success
      return
    } else {
      throw new Error(`会員登録完了メール送信失敗1<br />${rs.data.response}`)
    }
  } catch(error) {
    throw new Error(`会員登録完了メール送信失敗2<br />${error}`)
  }
}