<template>
  <div class="app-user-review">
    <!-- Header -->
    <div class="review-list-header">
      <IconReview />
      <p class="review-list-title-text">クチコミ一覧</p>
      <p class="review-list-title-count">{{ reviews.length }}件</p>
    </div>

    <!-- Body -->
    <div class="review-list-body">
      <div
        v-for="review, index in itemsPerPage"
        :key="`review-${review.reviewId}`"
      >
        <!-- Review header -->
        <div
          class="review-title-container"
          :class="{ 'top-border': index === 0}"
        >
          <p
            class="review-post-user-type"
            :class="$_getUserTypeClass(review.postUserType)"
          >
            {{ review.postUserType }}
          </p>
          <p class="review-create-at">
            {{ $_formatDate(review.createAt) }}
          </p>
        </div>

        <!-- Review  -->
        <CommonTextarea
          :prefix="'user-review-list'"
          :readonly="true"
          :remarks="review.review"
          :shown="shown"
          :userStyle="{
            width: '-webkit-fill-available',
            fontSize: '16px',
            margin: '8px 16px',
          }"
        />

        <!-- Objection link  -->
        <p
          class="objection-link"
          @click="$emit('show-objection', review)"
        >
          通報する
        </p>

        <div v-if="index > 0 && !((index + 1) % 3)">
          <!-- AD -->
          <div
            v-if="showAds"
            :style="{
              width: '100%',
              margin: '16px 0',
              paddingBottom: '16px',
              borderBottom: 'solid 1px #C6C6C6'
            }"
          >
            <img width="100%" src="@/assets/image/ad_sample.png" />
          </div>
        </div>
      </div>

      <!-- クチコミが0〜2件の場合も広告は1個表示 -->
      <div v-if="reviews.length < 3">
        <!-- AD -->
        <div
          v-if="showAds"
          :style="{
            width: '100%',
            margin: '16px 0'
          }"
        >
          <img width="100%" src="@/assets/image/ad_sample.png" />
        </div>
      </div>

      <b-pagination
        v-if="reviews.length"
        v-model="currentPage"
        class="review-list-pagination"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
      ></b-pagination>

      <!-- Review button -->
      <div class="review-button-container">
        <button
          class="review-button"
          @click="() => {
            $store.dispatch('modal/setModalHistory', this.modalId)
            $store.dispatch('modal/setModalId', 'review')
          }"
        >
          クチコミを投稿する
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import IconReview from '@/assets/image/icon_review.svg?component'
import moment from 'moment'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

// 一ページ毎の表示件数
const PER_PAGE_ITEM_NUM = 9

export default {
  name: 'AppUserReviewList',
  components: {
    CommonTextarea,
    IconReview,
  },
  props: {
    reviews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemsPerPage: [],
      perPage: PER_PAGE_ITEM_NUM,
      currentPage: 1,
      shown: false
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    rows: function () {
      return this.reviews ? this.reviews.length : 0
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
  watch: {
    currentPage() {
      this.$_setItemsPerPage()
    },
  },
  mounted: async function() {
    this.$_setItemsPerPage()
    // enable pull down bounce
    inobounce.disable()
    //FIXME: 一回だと textarea の scrollHeight がとれない
    // v-for で textarea をたくさん作っているから？
    await this.$nextTick()
    await this.$nextTick()
    await this.$nextTick()
    await this.$nextTick()
    this.shown = true
  },
  methods: {
    $_formatDate(timeStamp) {
      return timeStamp
        ? moment(timeStamp.seconds * 1000).format('YYYY.MM.DD HH:mm')
        : ''
    },
    $_getUserTypeClass(postUserType) {
      switch(postUserType) {
        case '移動販売事業者':
          return 'mobile-vendor-user-type'
        case '利用客':
          return 'customer-user-type'
        case 'その他':
          return 'other-user-type'
        default:
          return ''
      }
    },
    $_setItemsPerPage() {
      // pagenation のサイズに切り取り
      const begin = (this.currentPage - 1) * this.perPage
      this.itemsPerPage = this.reviews.filter((b, index) => (
        index >= begin &&
        index < begin + this.perPage
      ))
    },
  }
}
</script>

<style lang="scss" scoped>
.app-user-review {
  width: 100%;
  height: auto;
  padding: 0;
  overflow-y: auto;
  .review-list-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 14px;
    padding: 62px 16px 36px 16px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
    .review-list-title-text {
      color: #707070;
      font-size: 20px;
      margin-bottom: 0;
    }
    .review-list-title-count {
      color: #12B6D4;
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .review-list-body {
    padding: 0 20px 20px 20px;
    .top-border {
      border-top: solid 1px #C6C6C6;
      padding-top: 16px !important;
    }
    .review-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      .review-create-at {
        color: #C6C6C6;
        margin-bottom: 0;
        font-size: 12px;
      }
    }
    .review-post-user-type {
      color: #707070;
      font-size: 14px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-weight: bold;
      &:before {
        border-radius: 50%;
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        margin-right: 4px;
      }
    }
    .mobile-vendor-user-type {
      &:before {
        background: #12B6D4;
      }
    }
    .customer-user-type {
      &:before {
        background: #FFDA0B;
      }
    }
    .other-user-type {
      &:before {
        background: #95BB00;
      }
    }
    .objection-link {
      color: #C6C6C6;
      text-align: right;
      font-size: 12px;
      text-decoration: underline;
      padding: 0 16px 16px 0;
      border-bottom: solid 1px #C6C6C6;
    }
    .review-list-pagination {
      margin: 28px 0 32px 0;
    }
    .review-button-container {
        .review-button {
          font-size: 16px;
          width: 100%;
          min-height: 24px;
          padding: 1.5vh;
          margin-bottom: 0;
          border-radius: 5px;
          border: none;
          background-color: #12b6d4;
          color: white;
        }
    }
  }
}
/* overwrite pagenation style */
::v-deep(.page-item) {
  margin: 0px 6px;
}
::v-deep(.page-link) {
  color: #12B6D4 !important;
  border: solid 1px #12B6D4;
  background-color: #fff !important;
}
::v-deep(.active) {
  .page-link {
    color: #fff !important;
    border: solid 1px #12B6D4 !important;
    background-color: #12B6D4 !important;
  }
}
::v-deep(.disabled) {
  display: none;
}
</style>