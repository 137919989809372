<template>
  <b-modal
    ref="news"
    modal-class="common-modal"
    dialog-class="common-modal-dialog"
    content-class="news-modal-content"
    header-class="news-modal-header"
    body-class="common-modal-body"
    hide-header-close
    hide-backdrop
    hide-footer
    scrollable
    no-fade
    @close="() => $store.dispatch('modal/setModalId', 'map')"
  >
    <News />
    <template #modal-header="{ close }">
      <Header
        @left-arrow-click="() => { close() }"
        @logo-click="() => { close() }"
      />
    </template>
  </b-modal>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import News from '@/components/organisms/user/modals/contents/AppUserNews'

export default {
  name: 'AppUserNewsModal',
  components: {
    Header,
    News
  },
  methods: {
    showModal() {
      this.$refs['news'].show()
    },
    hideModal() {
      this.$refs['news'].hide()
    }
  }
}
</script>
  
<style lang="scss">
.news-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100%;
}
.news-modal-header {
  flex-direction: column;
  align-items: center;
}
</style>