import {
  db,
  collection, doc,
  getDoc, getDocs, addDoc, setDoc,
  updateDoc, deleteDoc,
  deleteField, increment,
  where, query,
  serverTimestamp,
  writeBatch, arrayUnion,
  arrayRemove
} from '@/firebase/config'
import {
  SPACE_DOCS_NAME,
  EVENT_DOCS_NAME,
  OBJECTION_DOCS_NAME,
  REVIEW_DOCS_NAME,
  CHUNK_DOCS_NAME,
  USERS_DOCS_NAME,
  CHUNK_DOC_FIELD_LIMIT
} from '@/constants/firestore'
import { putChunk } from '@/helper/firestore/chunk'

/**
 * Firestoreの処理を実行、結果を返却
 * データ操作のみが責務
 * エラーは呼び出す側でキャッチすること
 * 引数は呼び出し側を信用する（仮）
 */

/**
 * 特定クチコミの通報を取得
 * 内部でも参照したいので、スコープ外で宣言
 * @param {String} reviewId 
 * @return {Array.<Object>} 通報日時でソート(昇順)した配列
 */
const getObjectionByReviewId = async reviewId => {
  const collectionRef = collection(db, OBJECTION_DOCS_NAME)
  const q = query(collectionRef, where('reviewId', '==', reviewId))
  const querySnapshot = await getDocs(q)
  const result = await Promise.all(
    querySnapshot.docs.map(async doc => ({
      ...doc.data(),
      docId: doc.id
    }))
  )
  return result.sort((a, b) => {
    if (a.createAt.seconds > b.createAt.seconds) {
      return 1
    } else if (a.createAt.seconds < b.createAt.seconds) {
      return -1
    } else {
      return 0
    }
  })
}

/**
 * 登録日時が欠けているchunkデータを補間する
 * @param {Object} chunk 
 * @returns {Object} chunk 
 */
const interpolateCreateAt = async chunk => {
  // collection name
  let collectionName
  if (chunk.type === 'space') {
    collectionName = SPACE_DOCS_NAME
  } else if (chunk.type === 'event') {
    collectionName = EVENT_DOCS_NAME
  } else {
    return
  }
  // get place data
  const docRef = doc(db, collectionName, chunk.placeId)
  const docSnap = await getDoc(docRef)
  if (docSnap) {
    // update chunk
    const placdData = docSnap.data()
    const value = {
      ...chunk,
      createAt: placdData.createAt
    }

    // update remote
    await putChunk(chunk.docId, docSnap.id, value)

    return value
  } else {
    return chunk
  }
}

export default {
  // 基本的な CRUD + batch 処理 --------

  /**
   * 取得処理
   * @param {String} collectionName コレクション名
   * @param {String} id ドキュメントID 指定がなければコレクション全体を取得
   * @return {Array.<Object>} ヒットしなかった場合は空配列を返却
   * 必ずドキュメントIDを埋め込んだオブジェクトを返す
   * データベースの値にいちいちドキュメントIDを埋め込むより、
   * 取得処理を集約してここでドキュメントIDの埋め込みの責任を持つ
   */
  get: async (collectionName, id) => {
    if (id) {
      const docRef = doc(db, collectionName, id)
      const docSnap = await getDoc(docRef)
      return docSnap
       ? { ...docSnap.data(), docId: docSnap.id }
       : []
    } else {
      const collectionRef = collection(db, collectionName)
      const q = query(collectionRef)
      const querySnapshot = await getDocs(q)
      const result = await Promise.all(
        querySnapshot.docs.map(async doc => ({
          ...doc.data(),
          docId: doc.id
        })
      ))

      return result
    }
  },

  /**
   * 更新処理
   * @param {String} collectionName コレクション名 
   * @param {String} id ドキュメントID 
   * @param {Object} value 更新データ
   * @return {Object} 成功時のみ返却
   */
  put: async (collectionName, id, value) => {
    const docRef = doc(db, collectionName, id)
    await updateDoc(
      docRef,
      { ...value, updateAt: serverTimestamp()}
    )
    return { status: 'success' }
  },

  /**
   * 登録処理
   * @param {String} collectionName 
   * @param {Object} value 
   * @return {Object} 成功時のみ返却
   */
  post: async (collectionName, value) => {
    const docRef = await addDoc(
      collection(db, collectionName),
      { ...value, createAt: serverTimestamp() }
    )
    return { status: 'success', docId: docRef.id }
  },

  /**
   * 削除処理
   * chunkデータを同時に更新する場合はバッチで処理
   * @param {String} collectionName 
   * @param {String} id 
   * @param {String} chunkId ChunkID (Chunkデータ更新フラグ)
   * @return {Object} 成功時のみ返却
   */
  delete: async (collectionName, id, chunkId) => {
    if (chunkId) {
      const batch = writeBatch(db)

      // 対象ドキュメント削除
      batch.delete(doc(db, collectionName, id))
      // chunkデータ更新
      batch.update(
        doc(db, CHUNK_DOCS_NAME, chunkId),
        {
          [id]: deleteField(),
          count: increment(-1),
        }
      )

      await batch.commit()
    } else {
      await deleteDoc(doc(db, collectionName, id))
    }
    return { status: 'success' }
  },

  /**
   * バッチ処理実行
   * @param {Array.<Object>} steps バッチ処理の各処理単位の配列
   * 例：
   * { // Space or Event
   *    collectionName: collectionName,
   *    id: placeId,
   *    condition: { addUpdateDate: true },
   *    value: { likes: newLikesCount },
   *    method: 'update'
   *  },
   *  { // Chunk
   *    collectionName: CHUNK_DOCS_NAME,
   *    id: chunkId,
   *    condition: {
   *      addUpdateDate: { key: placeId },
   *      deleteField: { key: placeId }
   *    },
   *    value: { [placeId]: { ...value, likes: newLikesCount }},
   *    method: 'update',
   *    option: { merge: true }
   *  },
   *  { // Users (update likes)
   *    collectionName: USER_DOCS_NAME,
   *    id: uid,
   *    condition: {
   *      arrayUnion: { key: likes } or removeUnion: { key: likes }
   *    },
   *    value: { likes: placeId },
   *    method: 'update',
   *  },
   * }
   * @return {Array.<Object>} 処理結果配列
   * {
   *   method: 実行メソッド,
   *   docId: 処理対象ドキュメントID,
   *   collection: 対象コレクション
   * }
   */
  batch: async (steps) => {
    const batch = writeBatch(db)

    let result = []
    for (let i = 0; i < steps.length; i++) {
      const {
        collectionName,
        id,
        method,
        condition = {},
        value = {},
        option = {}
      } = steps[i]

      // get reference(collection or document)
      let docRef
      if (id) {
        docRef = doc(db, collectionName, id)
      } else {
        // Method 'set' has no doc ID. So, get doc ID first
        // then set docRef with new doc ID.
        const colRef = doc(db, collectionName)
        const docId = doc(colRef).id
        docRef = doc(db, collectionName, docId)
      }

      // set serverTimeStamp
      if (condition.addCreateDate) {
        condition.addCreateDate.key
          ? value[condition.addCreateDate.key].createAt = serverTimestamp()
          : value.createAt = serverTimestamp()
      }
      if (condition.addUpdateDate) {
        condition.addUpdateDate.key
          ? value[condition.addUpdateDate.key].updateAt = serverTimestamp()
          : value.updateAt = serverTimestamp()
      }

      // update array
      if (condition.arrayUnion) {
        value[condition.arrayUnion.key] = arrayUnion(value[condition.arrayUnion.key])
      }
      if (condition.arrayRemove) {
        value[condition.arrayRemove.key] = arrayRemove(value[condition.arrayRemove.key])
      }

      // delete field
      if (condition.deleteField) {
        value[condition.deleteField.key] = deleteField()
      }

      // add batch method
      method === 'delete'
        ? batch[method](docRef)
        : batch[method](docRef, value, option)

      result.push({
        method: method,
        docId: docRef.id,
        collection: collectionName
      })
    }
    await batch.commit() 
    return result
  },

  /**
   * 単純なCRUD以外のカスタム処理 --------
   */

  /**
   * 対象メールアドレスのUser情報を取得
   * @param {String} email 
   * @returns 
   */
  getUserByEmail: async email => {
    const collectionRef = collection(db, USERS_DOCS_NAME)
    const q = query(collectionRef, where('email', '==', email))
    const querySnapshot = await getDocs(q)
    const results = await Promise.all(
      querySnapshot.docs.map(async d => ({
        ...d.data(),
        docId: d.id
      }))
    )
    return results
  },

  /**
   * ユーザーをUserコレクションに追加
   * Authが返すuidをドキュメントIDに使うためsetDocメソッドを使用
   * @param {String} id Auth が返すuid
   * @param {Object} value 
   * @returns 
   */
  postUser: async (id, value) => {
    const docRef = doc(db, USERS_DOCS_NAME, id)
    await setDoc(
      docRef,
      { ...value, createAt: serverTimestamp() }
    )
    return { status: 'success', docId: docRef.id }
  },

  /**
   * ログイン日時を更新
   * @param {String} uid 
   * @returns 
   */
  updateLogInDate: async (id) => {
    const docRef = doc(db, USERS_DOCS_NAME, id)
    await updateDoc(
      docRef,
      { lastLogInAt: serverTimestamp() }
    )
    return { status: 'success', docId: docRef.id }
  },

  /**
   * Chunkドキュメントにプレイス情報をを登録
   * ドキュメント内にマップを追加するため、setDoc(..., { merge: true }) を指定する
   * @param {Object} value chunk用プレイスデータ
   * @param {String} chunkId 
   * @param {String} placeId 
   * @return 処理結果とChunkドキュメントID
   */
  postChunk: async (value, chunkId, placeId) => {
    const docRef = doc(db, CHUNK_DOCS_NAME, chunkId)
    await setDoc(
      docRef,
      {
        count: increment(1),
        [placeId]: { ...value, createAt: serverTimestamp() }
      },
      { merge: true }
    )
    return { status: 'success', docId: docRef.id }
  },

  /**
   * 有効化（disabled === false）状態のドキュメントを取得
   * この関数を呼ぶ際に指定するコレクションの各ドキュメントは、
   * 公開設定を以下の形式で指定する
   * { disabled: true/false }
   * @param {String} collectionName コレクション名
   * @return {Array.<Object>} ドキュメントIDを埋め込んだデータ配列
   */
  getEnabled: async (collectionName) => {
    const collectionRef = collection(db, collectionName)
    const q = query(collectionRef, where('disabled', '==', false))
    const querySnapshot = await getDocs(q)
    return await Promise.all(
      querySnapshot.docs.map(async d => ({
        ...d.data(),
        docId: d.id
      }))
    )
  },

  /**
   * 書き込み可能なChunkドキュメント（count < 50）のIDを取得
   * @return {Object.<String>} 書き込み可能なChunkドキュメント
   */
  getAvailableChunkDocIds: async () => {
    const collectionRef = collection(db, CHUNK_DOCS_NAME)
    const q = query(collectionRef, where('count', '<', CHUNK_DOC_FIELD_LIMIT))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.size) {
      return await Promise.all(
        querySnapshot.docs.map(async d => d.id)
      )
    } else {
      // count < 50 のドキュメントが無いため新規ドキュメント作成
      const docRef = await addDoc(
        collection(db, CHUNK_DOCS_NAME),
        { count: 0 }
      )
      return [ docRef.id ]
    }
  },

  /**
   * 対象クチコミの通報件数を取得
   * @param {String} reviewId 
   * @return {Number} 通報件数
   */
  getObjectionCount: async reviewId => {
    const collectionRef = collection(db, OBJECTION_DOCS_NAME)
    const q = query(
      collectionRef,
      where('reviewId', '==', reviewId),
    )
    const querySnapshot = await getDocs(q)
    return querySnapshot.size ? querySnapshot.docs.length : 0
  },

  /**
   * 特定スペースのクチコミを取得
   * @param {String} spaceId 
   * @return {Array.<Object>} 通報配列を埋め込んだクチコミ配列
   */
  getSpaceReviews: async spaceId => {
    const collectionRef = collection(db, REVIEW_DOCS_NAME)
    const q = query(collectionRef, where('spaceId', '==', spaceId))
    const querySnapshot = await getDocs(q)
    const results = await Promise.all(
      querySnapshot.docs.map(async doc => ({
        ...doc.data(),
        docId: doc.id,
        objections: await getObjectionByReviewId(doc.id)
      }))
    )
    return results
  },

  /**
   * 対象スペースIDの最新クチコミを１件取得
   * @param {String} spaceId 
   * @return {Object} 最新クチコミ
   */
  getFirstReview: async spaceId => {
    const collectionRef = collection(db, REVIEW_DOCS_NAME)
    /**
     * Using complex index
     */
    const q = query(
      collectionRef,
      where('spaceId', '==', spaceId),
      where('publication', '==', true),
      where('checked', '==', true),
      // orderBy('createAt')
      // limit(1)
    )
    const querySnapshot = await getDocs(q)
    const result = await Promise.all(
      querySnapshot.docs.map(async doc => ({
        ...doc.data(),
        docId: doc.id
      }))
    )

    // Sort by createAt
    // FIXME: where句と異なるフィールドでorderBy句が使えないため、
    // 余計なソートが発生している
    return result.sort((a, b) => (
      b.createAt.seconds - a.createAt.seconds
    )).shift()
  },

  /**
   * 最新バージョンを取得
   * @returns 用途は比較のみなのでDate型で返却
   */
  getAppVersion: async () => {
    const docRef = doc(db, 'app', 'version')
    const docSnap = await getDoc(docRef)
    return docSnap.data().latest
  },

  getObjectionByReviewId: getObjectionByReviewId,
  interpolateCreateAt: interpolateCreateAt
}

