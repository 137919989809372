<template>
  <b-modal
    ref="likes"
    modal-class="common-modal"
    dialog-class="common-modal-dialog"
    content-class="likes-modal-content"
    header-class="likes-modal-header"
    body-class="likes-modal-body"
    hide-header-close
    hide-backdrop
    hide-footer
    scrollable
    no-fade
    @close="() => $store.dispatch('modal/setModalId', 'map')"
  >
    <Likes />
    <template #modal-header="{ close }">
      <Header
        @left-arrow-click="() => { close() }"
        @logo-click="() => { close() }"
      />
    </template>
  </b-modal>
</template>
  
<script>
import Header from '@/components/molecules/user/AppUserHeader'
import Likes from '@/components/organisms/user/modals/contents/AppUserLikes'

export default {
  name: 'AppUserLikesModal',
  components: {
    Header,
    Likes
  },
  methods: {
    showModal() {
      this.$refs['likes'].show()
    },
    hideModal() {
      this.$refs['likes'].hide()
    }
  }
}
</script>
  
<style lang="scss">
.likes-modal-content {
  border: none !important;
  border-radius: 0 !important;
  height: 100% !important;
}
.likes-modal-header {
  align-items: center;
  flex-direction: column;
}
.likes-modal-body {
  padding: 0 !important;
  background-color: #F5F5F5;
}
</style>