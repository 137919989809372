export default {
  namespaced: true,
  state: {
    show: false,
    partialId: null,
    count: 0,
    total: 0,
    subText: ''
  },
  mutations: {
    resetState(state) {
      state.show = false
      state.partialId = null
      state.count = 0
      state.total = 0
      state.subText = ''
    },
    setShow: (state, value) => {
      state.show = value
    },
    setCount: (state, value) => {
      state.count = value
    },
    setTotal: (state, value) => {
      state.total = value
    },
    setSubText: (state, value) => {
      state.subText = value
    },
    setPartialId: (state, value) => {
      state.partialId = value
    },
  },
  getters: {
    loadingMaskState: (state) => {
      return {
        show: state.show,
        count: state.count,
        total: state.total,
        subText: state.subText
      }
    },
    show: (state) => {
      return state.show
    },
    count: (state) => {
      return state.count
    },
    total: (state) => {
      return state.total
    },
    subText: (state) => {
      return state.subText
    },
    partialId: (state) => {
      return state.partialId
    },
  },
  actions: {
    showLoadingMask: ({ commit }) => {
      commit('setShow', true)
    },
    hideLoadingMask: ({ commit }) => {
      commit('resetState')
    },
    resetState({ commit }) {
      commit('resetState')
    },
    setShow: ({ commit }, value) => {
      commit('setShow', value)
    },
    setCount: ({ commit }, value) => {
      commit('setCount', value)
    },
    setTotal: ({ commit }, value) => {
      commit('setTotal', value)
    },
    setSubText: ({ commit }, value) => {
      commit('setSubText', value)
    },
    setPartialId: ({ commit }, value) => {
      commit('setPartialId', value)
    },
  }
}
