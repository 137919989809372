<template>
  <div class="app-user-review">
    <!-- Header -->
    <div class="review-list-title">
      <IconReview class="review-list-title-icon"/>
      <div class="review-list-title-text">クチコミ投稿 内容確認</div>
    </div>

    <!-- Body -->
    <div class="review-body">
      <div class="sub-title">あなたの属性</div>
      <p class="user-type">{{ userType }}</p>

      <div class="sub-title mt-4 mb-2">クチコミ内容</div>
      <CommonTextarea
        :prefix="'user-review-preview'"
        :maxlength="'140'"
        :readonly="true"
        :remarks="review"
        :shown="shown"
        :userStyle="{
          fontSize: '16px',
          color: '#A5A5A5',
          padding: '0 16px 16px 16px'
        }"
      />

      <!-- Action button -->
      <div class="pt-3">
        <button
          id="submit-button"
          @click="$_saveReview"
        >
          上記の内容で送信する
        </button>
        <button
          id="back-button"
          class="mb-3"
          @click="$emit('hide-modal-click')"
        >
          編集にもどる
        </button>
      </div>

      <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '32px 0 0 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import IconReview from '@/assets/image/icon_review.svg?component'
import inobounce from 'inobounce'

export default {
  name: 'AppUserReviewPreview',
  components: {
    CommonTextarea,
    IconReview,
  },
  data() {
    return {
      shown: false
    }
  },
  props: {
    userType: {
      type: String,
      default: ''
    },
    review: {
      type: String,
      default: ''
    }
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()
    await this.$nextTick()
    // CommonTextarea に表示されたタイミングを通知するため
    this.shown = true
  },
  updated() {
  },
  methods: {
    async $_saveReview() {
      this.$emit('send-review', {
        postUserType: this.userType,
        review: this.review,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-review {
  width: 100%;
  height: auto;
  padding: 0;
  overflow-y: auto;
}
.review-list-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.review-list-title-icon {
  margin-right: 16px;
}
.review-list-title-text {
  color: #707070;
  font-size: 20px;
}
.review-body {
  padding: 20px;
  .sub-title {
    color: #707070;
    font-size: 16px;
  }
  .user-type {
    color: #707070;
    margin: 12px;
  }
}
#back-button {
  width: 100%;
  font-size: 16px;
  width: 100%;
  min-height: 24px;
  padding: 1.5vh;
  margin-bottom: 0;
  border-radius: 5px;
  border: none;
  background-color: #707070;
  color: white;
}
#submit-button {
  width: 100%;
}
</style>