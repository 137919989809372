<template>
  <div class="app-user-news-card">
    <div
      class="toggle-button"
      :class="{ checked: !isNew }"
      v-b-toggle="`collapse-${newsId}`"
      @click="$_onClickToggle"
    >
      <div class="news-date">{{ date }}</div>
      <div class="news-title">{{ title }}</div>
      <div class="toggle-icon-wrapper">
        <component
          class="toggle-icon"
          :is="isOpen ? 'IconCloseArrow' : 'IconOpenArrow'"
        />
      </div>
    </div>

    <b-collapse
      class="toggle-contents mt-2"
      :id="`collapse-${newsId}`"
    >
      <template
        v-for="line, index in lineBreakedContents"
      >
        <br v-if="!line.value" :key="`${newsId}-news-line-break-${index}`"/>
        <a
          v-else-if="line.type === 'link'"
          :key="`${newsId}-news-link-${index}`"
          :href="line.value"
          :style="{ marginBottom: '0' }"
          rel="noopener noreferrer"
          target="_blank"
        >
          {{ line.value }}
        </a>
        <p
          v-else
          :key="`${newsId}-news-contents-${index}`"
          :style="{ marginBottom: '0' }"
        >
          {{ line.value }}
        </p>
      </template>
    </b-collapse>
  </div>
</template>

<script>
import IconOpenArrow from '@/assets/image/icon_open_arrow.svg?component'
import IconCloseArrow from '@/assets/image/icon_close_arrow.svg?component'
import { showBottomInfoToast } from '@/helper/common'

export default {
  name: 'AppUserNewsCard',
  components: {
    IconOpenArrow,
    IconCloseArrow
  },
  props: {
    newsId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    contents: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isOpen: false,
      isNew: false
    }
  },
  computed: {
    checkedNewsIds: function () {
      return this.$store.getters['news/checkedNewsIds']
    },
    lineBreakedContents: function () {
      return this.contents.split('\\n').map(line => ({
        type: this.$_isUrlString(line) ? 'link' : 'text',
        value: line
      }))
    }
  },
  watch: {
    checkedNewsIds: function () {
      // お知らせの既読状態が変更されたので新規フラグを更新
      this.isNew = this.checkedNewsIds
        ? !this.checkedNewsIds.includes(this.newsId)
        : false
    }
  },
  mounted: function () {
    this.isNew = this.checkedNewsIds
      ? !this.checkedNewsIds.includes(this.newsId)
      : false
  },
  methods: {
    /**
     * URL文字列か判定
     * @param {String} text 対象文字列
     */
    $_isUrlString: function (text) {
      // URL判定正規表現
      var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g
      return regexp_url.test(text)
    },
    /**
     * 子要素を展開し、既読状態を更新する
     */
    $_onClickToggle: async function () {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        try {
          const mod = [...this.checkedNewsIds]
          if (!mod.includes(this.newsId)){
            mod.push(this.newsId)
            // ストアの確認済みお知らせID更新(ユニーク)
            this.$store.dispatch('news/setCheckedNewsIds', mod)
          }
        } catch (error) {
          showBottomInfoToast('error', error.message, 150000)
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-news-card {
  padding-top: 8px;
  .toggle-button {
    border: solid 1px #eeeeee !important;
    background-color: #fff;
    border-radius: 12px;
    padding: 16px;
    display: grid;
    width: 100%;
    grid-template-rows: 20px auto auto;
    grid-template-columns: 1fr 20px;
    grid-template-areas:
      "areaA areaC"
      "areaB areaC";
  }
  .checked {
    background-color: #e8e8e8;
  }
  .news-date {
    grid-area: areaA;
    color: #A5A5A5;
    font-size: 10px;
    text-align: left;
  }
  .news-title {
    overflow: scroll;
    grid-area: areaB;
    color: #707070;
    font-size: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
  }
 .toggle-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: areaC;
    height: 100%;
  }
  .toggle-contents {
    color: #707070; 
    font-size: 14px;
    padding: 8px 16px !important;
    overflow-wrap: anywhere; 
  }
}
/* overwrite */
.show {
  padding: 8px;
}
</style>

<style>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
  margin-bottom: 0;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  font-weight: bold;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #46C7DE;
}
input:focus + .slider {
  box-shadow: 0 0 1px #46C7DE;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
