<template>
  <div class="app-user-add-to-homescreen">
    <!-- Header -->
    <div class="account-list-title">
      <div class="account-list-title-text">ホーム画面への登録方法</div>
    </div>

    <!-- iPhone -->
    <div class="posh-info-item">
      iPhoneの場合
    </div>
    <!-- Images -->
    <div
      :style="{ display: 'flex', flexWrap: 'wrap', margin: '0 20px' }"
    >
      <div :style="{ width: '50%', textAlign: 'center' }">
        <img src="@/assets/image/add-homescreen/add_homescreen_01.png" :style="{ width: '100%', padding: '8px' }" />
        <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
          ①Safariで「<a href="https://poshspace.jp">https://poshspace.jp</a>」を開き、画面下の共有ボタンをタップします。
        </p>
      </div>
      <div :style="{ width: '50%', textAlign: 'center' }">
        <img src="@/assets/image/add-homescreen/add_homescreen_02.png" :style="{ width: '100%', padding: '8px' }"  />
         <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
          ②「ホーム画面に追加」をタップします。
         </p>
      </div>
      <div :style="{ width: '50%', textAlign: 'center' }">
        <img src="@/assets/image/add-homescreen/add_homescreen_03.png" :style="{ width: '100%', padding: '8px' }"  />
        <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
          ③アイコン用の名前を設定し、右上の「追加」をタップします。
        </p>
      </div>
      <div :style="{ width: '50%', textAlign: 'center' }">
        <img src="@/assets/image/add-homescreen/add_homescreen_04.png" :style="{ width: '100%', padding: '8px' }"  />
        <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
          ④ホーム画面にアイコンが追加され完了です。
        </p>
      </div>
    </div>
    <!-- Android -->
    <div class="posh-info-item" :style="{ marginTop: '64px' }">
      Androidの場合
    </div>
    <div class="posh-info-item">
      機種により登録方法が異なります。一例を掲載しますので、ご参照ください。<br />
      <br />
      ①標準ブラウザで「<a href="https://poshspace.jp">https://poshspace.jp</a>」を開き、ブックマークしてください。<br />
      ②ブックマークアイコンをタップします。<br />
      ③ブックマークしたページを長押しします。<br />
      ④「ショートカットを作成」をタップします。<br />
      ※機種によっては「ショートカットをホーム画面に追加」と表示される場合があります。<br />
      ⑤ホーム画面にアイコンが追加され完了です。
    </div>

    <div :style="{padding: '20px'}">
      <button id="submit-button" @click="$store.dispatch('modal/setModalId', 'map')">
        トップへ
      </button>
    </div>
  </div>
</template>

<script>
import { getPageFlameState } from '@/helper/common'

export default {
  name: 'AppUserAddToHomeScreenDetail',
}
</script>

<style lang="scss" scoped>
.app-user-add-to-homescreen {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.account-list-title {
  display: flex;
  justify-content: flex-start;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.account-list-title-text {
  color: #707070;
  font-size: 20px;
}
.posh-info-item {
  font-size: 16px;
  color: #707070;
  margin: 0 20px 16px 20px ;
  line-height: 2;
}
#submit-button {
  font-size: 16px;
  width: 100%;
  min-height: 24px;
  padding: 1.5vh;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
</style>