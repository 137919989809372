export default {
  namespaced: true,
  state: {
    adminNews: [],
    userNews: [],
    checkedNewsIds: []
  },
  mutations: {
    resetState(state) {
      state.adminNews = []
      state.userNews = []
      state.checkedNewsIds = []
    },
    setAdminNews(state, value) {
      state.adminNews= value
    },
    setUserNews(state, value) {
      state.userNews = value
    },
    setCheckedNewsIds:(state, value) => {
      state.checkedNewsIds = value
    },
  },
  getters: {
    // フィルタリングしていない全データ
    adminNews: (state) => {
      return state.adminNews
    },
    // 公開設定でフィルタリングしたデータ
    userNews: (state) => {
      return state.userNews
    },
    checkedNewsIds:(state) => {
      return state.checkedNewsIds
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    setAdminNews(context, value) {
      context.commit('setAdminNews', value)
    },
    setUserNews(context, value) {
      context.commit('setUserNews', value)
    },
    setCheckedNewsIds:(context, value) => {
      context.commit('setCheckedNewsIds', value)
    },
  }
}
