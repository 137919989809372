var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-list"},[_c('div',{staticClass:"scroll-wrapper"},[_c('div',{attrs:{"id":"scroll-anchor"}}),_vm._l((_vm.itemsPerPage),function(item,index){return _c('div',{key:`list-item-${item.placeId}`,attrs:{"id":"list-scroll-container"}},[_c('CarouselCard',{attrs:{"placeId":item.placeId,"imageHeight":100,"imageWidth":136,"width":'100%',"direction":'column'},on:{"card-click":_vm.$_onCardClick}}),(index > 0 && !((index + 1) % 3))?_c('div',[(_vm.showAds)?_c('div',{style:({
            width: '100%',
            margin: '16px 0'
          })},[_c('img',{attrs:{"width":"100%","src":require("@/assets/image/ad_sample.png")}})]):_vm._e()]):_vm._e()],1)})],2),(_vm.items.length < 3)?_c('div',[(_vm.showAds)?_c('div',{style:({
        width: '100%',
        margin: '16px 0'
      })},[_c('img',{attrs:{"width":"100%","src":require("@/assets/image/ad_sample.png")}})]):_vm._e()]):_vm._e(),(_vm.items.length)?_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }