import common from './common'
import {
  SPACE_DOCS_NAME,
  EVENT_DOCS_NAME,
  CHUNK_DOCS_NAME
} from '@/constants/firestore'
import { USERS_DOCS_NAME } from '@/constants/firestore'

/**
 * いいね更新処理(likesを必ずNumberで書き込む責務を持つ)
 * @param {Object} value Chunkデータと同じ形式
 * @param {Number} count お気に入りの増減数（1/-1）
 * @param {String} uid お気に入り操作を行ったユーザーID
 * @return {Object|Array.<Object>} 更新結果（バッチ処理の場合はそれぞれの処理結果配列）
 */
const putLikes = async (value, count, uid) => {
  try {
    const { placeId, docId, likes, type } = value

    if (!placeId) throw new Error('Space ID is not provided.')
    if (!docId) throw new Error('Chunk ID is not provided.')
    if (isNaN(Number(likes))) throw new Error(`likes is invalid(${likes}).`)
    if (!uid) throw new Error('Space ID is not provided.')
    if (count !== 1 && count !== -1) throw new Error(`count is invalid(${count}).`)

    const documentName = type === 'space' ? SPACE_DOCS_NAME : EVENT_DOCS_NAME
    const newLikesCount = Math.max(0, Number(likes) + count)

    await common.batch([
      { // Space or Event
        collectionName: documentName,
        id: placeId,
        condition: { addUpdateDate: true },
        value: { likes: newLikesCount },
        method: 'update'
      },
      { // Chunk
        collectionName: CHUNK_DOCS_NAME,
        id: docId,
        condition: { addUpdateDate: { key: `${placeId}` }},
        value: { [placeId]: { ...value, likes: newLikesCount }},
        method: 'update'
      },
      { // User
        collectionName: USERS_DOCS_NAME,
        id: uid,
        condition: count === 1
          ? { arrayUnion: { key: 'likes' } }
          : { arrayRemove: { key: 'likes' } },
        value: { likes: placeId },
        method: 'update'
      },
    ])
  } catch (e) {
    throw new Error(`putLikes => ${e}`)
  }
} 

const replaceLikes = async (uid, value) => {
  try {
    if (!uid) throw new Error('User ID is not provided.')
    if (!Array.isArray(value)) throw new Error('Value is not array.')

    await common.put(USERS_DOCS_NAME, uid, { likes: value })
  } catch (e) {
    throw new Error(`replaceLikes => ${e}`)
  }
} 

export {
  putLikes,
  replaceLikes
}