import common from './common'
import { REVIEW_DOCS_NAME, CHUNK_DOCS_NAME } from '@/constants/firestore'
import { isEmptyObject } from '@/helper/util'

/**
 * reviewId かちゃんとバリデーションすること
 * Firestoreの処理自体は./common.jsにまとめること
 * このjsのやることは、要求された処理とReviewドキュメントへの参照、
 *  必要な引数をcommon.jsの処理に渡し、エラーを監視し、完了したら値を返すこと
 *   commonのメソッド呼び出しと、値に対する操作が責務
 */

/**
 * 特定ドキュメント取得
 * @param {String} reviewId 
 * @return {Array.<Object>} クチコミ配列
 */
const getReview = async (reviewId) => {
  try {
    if (!reviewId) throw new Error('Review document ID is not provided.')  
    return await common.get(REVIEW_DOCS_NAME, reviewId)
  } catch (e) {
    throw new Error(`getReview => ${e}`)
  }
}

/**
 * reviewsコレクションの全ドキュメント取得
 * @return {Array.<Object>} クチコミ配列 
 */
const getAllReviews = async () => {
  try {
    return await common.get(REVIEW_DOCS_NAME)
  } catch (e) {
    throw new Error(`getAllReviews => ${e}`)
  }
}

/**
 * 特定スペースのクチコミを取得
 * @param {String} spaceId 
 * @return {Array.<Object>} クチコミ配列
 */
const getSpaceReviews = async spaceId => {
  try {
    if (!spaceId) throw new Error('Space document ID is not provided.')  
    return await common.getSpaceReviews(spaceId)
  } catch (e) {
    throw new Error(`getSpaceReviews => ${e}`)
  }
}

/**
 * 特定スペースのクチコミの内、公開状態かつ投稿日時が一番新しいクチコミを取得
 * @param {String} spaceId 
 * @return {Object} クチコミデータ
 */
const getFirstReview = async spaceId => {
  try {
    if (!spaceId) throw new Error('Space document ID is not provided.')  
    return await common.getFirstReview(spaceId)
  } catch (e) {
    throw new Error(`getFirstReview => ${e}`)
  }
}

/**
 * クチコミ更新処理
 * @param {Object} value 以下必須項目を含む
 * Review ID、Space ID、Chunk ID、更新オブジェクト（review）
 * @return {Object|Array.<Object>} 更新結果（バッチ処理の場合はそれぞれの処理結果配列）
 */
const putReview = async (value) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')
    if (isEmptyObject(value.review)) throw new Error('Value.review is not provided.')
    if (!value.reviewId) throw new Error('Review document ID is not provided.')
    if (!value.spaceId) throw new Error('Space document ID is not provided.')

    const { reviewId, spaceId, chunk, review } = value

    if (chunk) {
      // クチコミの公開/非公開でChunkのReview数は増減する
      // publication の更新がなければChunkのReview数は変わらない
      const count = Object.keys(review).includes('publication')
        ? review.publication ? 1 : -1
        : 0
      const reviewCount = Math.max(0, chunk.review + count)
      
      return await common.batch([
        { // Review
          collectionName: REVIEW_DOCS_NAME,
          id: reviewId,
          condition: { addUpdateDate: true },
          value: review,
          method: 'update'
        },
        { // Chunk
          collectionName: CHUNK_DOCS_NAME,
          id: chunk.chunkId,
          condition: { addUpdateDate: { key: spaceId }},
          value: { [spaceId]: { ...chunk, review: reviewCount } },
          method: 'update'
        },
      ])
    } else {
      return await common.put(REVIEW_DOCS_NAME, reviewId, review)
    }
  } catch (e) {
    throw new Error(`putReview => ${e}`)
  }
} 

/**
 * クチコミ登録処理
 * クチコミは公開/非公開判定を管理者が行なったタイミングの
 * put時にpublicationが変化した場合にChunkを更新する
 * @param {Object} value 
 * @return {Object} 成功時オブジェクト
 * { statue: 'success', docId: 登録したreviewドキュメントID }
 */
const postReview = async (value) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')  

    // 新規クチコミ追加なので、初期値は「未確認」「非公開」とする
    value.checked = false
    value.publication = false
    common.post(REVIEW_DOCS_NAME, value)
  } catch (e) {
    throw new Error(`postReview => ${e}`)
  }
} 

/**
 * クチコミ削除処理
 * @param {String} reviewId 
 * @param {String} chunkId 
 * @return {Array.<Object>} バッチ処理のそれぞれの処理結果配列
 */
const deleteReview = async (reviewId, chunkId)  => {
  try {
    if (!reviewId) throw new Error('Review document ID is not provided.')  
    if (!chunkId) throw new Error('Chunk document ID is not provided.')  

    return await common.batch([
      { // Review
        collectionName: REVIEW_DOCS_NAME,
        id: reviewId,
        method: 'delete'
      },
      { // Chunk
        collectionName: CHUNK_DOCS_NAME,
        id: chunkId,
        method: 'delete'
      },
    ])
  } catch (e) {
    throw new Error(`deleteReview => ${e}`)
  }
}

export {
  getReview,
  getAllReviews,
  getSpaceReviews,
  getFirstReview,
  putReview,
  postReview,
  deleteReview,
}