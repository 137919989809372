import { getAllChunks, getChunk } from '@/helper/firestore/chunk'
import { expiredDisplayDate } from '@/helper/place'

// 主にChunkデータを扱う
export default {
  namespaced: true,
  state: {
    chunks: [],
    selectedPlace: {},
  },
  mutations: {
    resetState(state) {
      state.chunks = []
      state.selectedPlace = {}
    },
    setChunks(state, value) {
      state.chunks = value
    },
    setSelectedPlace(state, value) {
      if (!value) state.selectedPlace = value
      const target = state.chunks.find(p => p.placeId === value)
      state.selectedPlace = target || null
    },
  },
  getters: {
    chunks: (state) => {
      return state.chunks
    },
    validChunk: (state) => {
      return state.chunks.filter(p => validateChunk(p))
    },
    validSpaces: (state, getters) => {
      return getters.validChunk.filter(p => p && p.type === 'space')
    },
    validEvents: (state, getters) => {
      return getters.validChunk.filter(p => p && p.type === 'event')
    },
    validEventsForAdmin: (state, getters) => {
      // 非公開も含める and 掲載終了日を超過していないイベント
      return getters.chunks.filter(c => (
        c && c.type === 'event' &&
        !expiredDisplayDate(c.endOfPublicationDate)
      ))
    },
    selectedPlace: (state) => {
      return state.selectedPlace
    },
    selectedPlaceId: (state) => {
      return state.selectedPlace
        ? state.selectedPlace.placeId
        : null
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    async setChunks(context, value) {
      if (!value) {
        try {
          const chunks = await getAllChunks()
          context.commit('setChunks', chunks)
        } catch (e) {
          console.log(e)
        }
      } else {
        context.commit('setChunks', value)
      }
    },
    async updateSpecificChunk(context, value) {
      const chunkId = value
      if (!chunkId) {
        console.log('no chunk id is specified.')
        return
      }

      try {
        const chunkArray = await getChunk(chunkId)
        context.commit(
          'setChunks',
          [
            ...context.state.chunks.filter(c => c.docId !== chunkId),
            ...chunkArray
          ]
        )
      } catch (e) {
        alert(e)
      }
    },
    setSelectedPlace(context, value) {
      context.commit('setSelectedPlace', value)
    },
  }
}

/**
 * 
 * @param {*} chunk 
 * @returns 
 */
const validateChunk = chunk => {
  // 公開チェック
  if (!chunk.publish || chunk.publish === '非公開') return false

  return chunk.type === 'event'
    ? !expiredDisplayDate(chunk.endOfPublicationDate)
    : true
}