export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

export const isNumber = value => {
  return ((typeof value === 'number') && (isFinite(value)))
}

export const isNull = value => {
  return value === null
}

export const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}