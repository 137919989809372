var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-user-modal-mananger"},[_c('IntroductionModal',{ref:"introduction"}),_c('AddToHomescreenModal',{ref:"add-to-homescreen-modal",on:{"change-button-clicke":() => {
      _vm.$store.dispatch('modal/setModalId', 'add-to-homescreen')
    }}}),_c('AddPlaceIntroductionModal',{ref:"add-place-introduction-modal"}),_c('FailedGetLocationModal',{ref:"failed-get-location",on:{"change-button-clicke":() => {
      _vm.$store.dispatch('modal/setModalId', 'location-setting')
    }}}),_c('SpaceDetailModal',{ref:"space-detail"}),_c('ReviewModal',{ref:"review"}),_c('ReviewListModal',{ref:"review-list"}),_c('EventDetailModal',{ref:"event-detail"}),_c('ListModal',{ref:"list"}),_c('LikesModal',{ref:"likes"}),_c('NewsModal',{ref:"news"}),_c('OthersModal',{ref:"others",attrs:{"othersPage":_vm.othersPage},on:{"others-page-change":(page) => {
      // modalID更新前に履歴保存
      _vm.$store.dispatch('modal/setModalHistory', this.modalId)
      _vm.$store.dispatch('modal/setModalId', page)
    },"others-left-arrow-clicke":() => {
      if (_vm.othersPage === 'others') {
        _vm.$store.dispatch('modal/setModalId', 'map')
      } else {
        _vm.othersPage = 'others'
      }
    },"others-logo-click":() => {
      _vm.$store.dispatch('modal/setModalId', 'map')
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }