<template>
  <div class="app-user-user-guideline-contents">
    <!-- Header -->
    <div class="user-guideline-title">
      <div class="user-guideline-title-text">利用規約</div>
    </div>

    <!-- User Guideline -->
    <div class="user-guideline-inner">
      <div
        v-for="(item, index) in userGuideline"
        :key="`${item.page}-user-guideline-${index}`"
      >
        <div v-if="currentPage === item.page" class="mb-5">
          <div
            v-if="item.page === 1"
            class="user-guideline-item mt-4"
          >
            Poshspace（以下 「本サービス」といいます。）のご利用にあたり、このPoshspace利用規約 （以下 「本規約」といいます。）のすべての条項をお読みいただき、同意していただく必要がございます。
          </div>

          <div
            v-for="(content, index) in item.contents"
            :key="`${item.page}-user-guideline-item-wrapper-${index}`"
          >
            <div class="user-guideline-item-title-sub">{{ content.title }}</div>
            <div class="user-guideline-item">
              <p
                style="marginBottom: 0;"
                v-for="(line, index) in content.text"
                :key="`${item.page}-user-guideline-item-${index}`"
              >
                {{ line || '&nbsp;' }}
              </p>
            </div>
          </div>
          <div v-if="item.page === maxPage">
            <div class="user-guideline-item mt-5 mb-5">
              {{ fixDateText }}
            </div>
            <div class="user-guideline-item mt-5">
              運営者<br />
              株式会社ティーピーズ
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 page-button-wrapper">
      <b-button
        :class="{ 'hide-button': isFirstPage }"
        variant="outline-secondary"
        @click="currentPage = Math.max(1, --currentPage)"
      >
        <b-icon icon="chevron-left" aria-label="Help"></b-icon>
      </b-button>
      {{ currentPage }} / {{ maxPage }}
      <b-button
        :class="{ 'hide-button': isLastPage }"
        variant="outline-secondary"
        @click="currentPage = Math.min(maxPage, ++currentPage)"
      >
        <b-icon icon="chevron-right" aria-label="Help"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import { USER_GUIDELINE, USER_GUIDELINE_FIXED_DATE } from '@/constants/user'

const MAX_PAGE = 5

export default {
  name: 'AppUserUserGuidelineContents',
  data() {
    return {
      currentPage: 1,
      maxPage: MAX_PAGE,
      userGuideline: USER_GUIDELINE,
      fixDateText: USER_GUIDELINE_FIXED_DATE,
    }
  },
  watch: {
    currentPage() {
      // スクロールを戻す
      document.getElementsByClassName('modal-body')[0].scrollTo(0, 0)
    },
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
    isLastPage() {
      return this.currentPage === this.maxPage
    },
    isFirstPage() {
      return this.currentPage === 1
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-user-guideline-contents {
  // control map size
  width: 100%;
  height: 100%;
  /*
  * Header
  */
  .user-guideline-title {
    display: flex;
    justify-content: flex-start;
    padding: 62px 20px 0 20px;
    box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
  }
  .user-guideline-title-text {
    color: #707070;
    font-size: 20px;
  }

  /*
  * Body
  */
  .user-guideline-inner {
    padding: 0 20px 20px 20px;
  }
  .user-guideline-item-title-sub {
    font-size: 16px;
    color: #707070;
    margin: 48px 0 12px 0;
  }
  .user-guideline-item {
    font-size: 14px;
    color: #707070;
    p {
      word-break: break-all;
    }
  }
  .page-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    font-size: 16px;
    button {
      width: 100px;
    }
  }
  .hide-button {
    visibility: hidden;
  }
}
</style>