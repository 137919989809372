<template>
  <textarea
    class="app-common-textarea"
    :ref="refPrefix"
    :class="{
      'no-resize': !resize,
      'no-pointer-events': readonly
    }"
    :readonly="readonly"
    :rows="rows"
    :maxlength="maxlength"
    :placeholder="placeholder"
    :disabled="disabled"
    :style="userStyle"
    :value="remarks"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'AppTextarea',
  data() {
    return {
      modelValue: ''
    }
  },
  // 利用側が v-model を使えるための指定
  model: {
    prop: 'remarks',
    event: 'input',
  },
  props: {  
    shown: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    remarks: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rows: {
      type: String,
      default: null
    },
    maxlength: {
      type: String,
      default: null
    },
    userStyle: {
      type: Object,
      default: () => {}
    },
    showAllRows: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$nextTick(() => this.$_setTextareaHeight())
  },
  // scrollHeightが増加する分には正しいが、一度増加すると減少時に縮まらない謎
  // mounted() {
  //   const observer = new ResizeObserver((entries) => {
  //     entries.forEach(() => {
  //       this.$nextTick(() => this.$_setTextareaHeight())
  //     })
  //   })
  //   observer.observe(this.$refs[this.refPrefix])
  // },
  updated() {
    this.$nextTick(() => this.$_setTextareaHeight())
  },
  computed: {
    refPrefix() {
      return `${this.prefix}-textarea`
    }
  },
  watch:{
    shown() {
      // textarea自体がmount時に非表示の可能性があり、その場合scrollHeightがとれない
      // props で表示状態を受け取り、表示された際に高さを設定する
      if (this.shown) {
        this.$nextTick(() => this.$_setTextareaHeight())
      }
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    $_setTextareaHeight() {
      // rowsが指定されている場合はrowsを優先
      if (!this.showAllRows || this.rows) return

      // 改行のない備考テキストも全部表示するために高さを設定
      this.$refs[this.refPrefix].style.height = `${this.$refs[this.refPrefix].scrollHeight}px`
    },
  }
}
</script>

<style lang="scss" scoped>
  .app-common-textarea {
    color: #707070;
    font-size: 12px;
    width: 100%;
    border: none;
    margin-top: 4px;
  }
  .no-resize {
    resize: none;
  }
  .no-pointer-events {
    pointer-events: none;
  }
</style>