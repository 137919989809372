import { INITIAL_CONDITIONS } from '@/constants/user'

export default {
  namespaced: true,
  state: {
      showSpace: INITIAL_CONDITIONS.SHOW_SPACE,
      showEvent: INITIAL_CONDITIONS.SHOW_EVENT,
      keyword: INITIAL_CONDITIONS.KEYWORD,
      likesCount: INITIAL_CONDITIONS.LIKES_COUNT,
      reviewCount: INITIAL_CONDITIONS.REVIEW_COUNT,
      distance: INITIAL_CONDITIONS.DISTANCE,
      prefecture: INITIAL_CONDITIONS.PREFECTURE,
      heldDates: INITIAL_CONDITIONS.HELD_DATES,
      prevConditions: INITIAL_CONDITIONS.PREV_CONDITIONS,
    },
  mutations: {
    resetState(state) {
      state.showSpace = INITIAL_CONDITIONS.SHOW_SPACE
      state.showEvent = INITIAL_CONDITIONS.SHOW_EVENT
      state.keyword = INITIAL_CONDITIONS.KEYWORD
      state.likesCount = INITIAL_CONDITIONS.LIKES_COUNT
      state.reviewCount = INITIAL_CONDITIONS.REVIEW_COUNT
      state.distance = INITIAL_CONDITIONS.DISTANCE
      state.prefecture = INITIAL_CONDITIONS.PREFECTURE
      state.heldDates = INITIAL_CONDITIONS.HELD_DATES
      state.prevConditions = { ...state }
    },
    storePrevCondition(state) {
      state.prevConditions = { ...state }
    },
    setConditions(state, value) {
      state.showSpace = value.showSpace
      state.showEvent = value.showEvent
      state.keyword = value.keyword
      state.likesCount = value.likesCount
      state.reviewCount = value.reviewCount
      state.distance = value.distance
      state.prefecture = value.prefecture
      state.heldDates = value.heldDates
    },
  },
  getters: {
    conditionNames: (state) => {
      return Object.keys(state)
    },
    conditions: (state) => {
      return {
        showSpace: state.showSpace,
        showEvent: state.showEvent,
        keyword: state.keyword,
        likesCount: state.likesCount,
        reviewCount: state.reviewCount,
        distance: state.distance,
        prefecture: state.prefecture,
        heldDates: state.heldDates,
      }
    },
    prevConditions: (state) => {
      return state.prevConditions
    },
    isConditionChanged: (state) => {
      if (!state.prevConditions) return false
      return (
        state.prevConditions.showSpace !== state.showSpace ||
        state.prevConditions.showEvent !== state.showEvent ||
        state.prevConditions.keyword !== state.keyword ||
        state.prevConditions.likesCount !== state.likesCount ||
        state.prevConditions.reviewCount !== state.reviewCount ||
        state.prevConditions.distance !== state.distance ||
        state.prevConditions.prefecture !== state.prefecture ||
        state.prevConditions.heldDates !== state.heldDates
      )
    },
    hasCondition: (state) => {
      return (
        state.showSpace !== INITIAL_CONDITIONS.SHOW_SPACE ||
        state.showEvent !== INITIAL_CONDITIONS.SHOW_EVENT ||
        state.keyword !== INITIAL_CONDITIONS.KEYWORD ||
        state.likesCount !== INITIAL_CONDITIONS.LIKES_COUNT ||
        state.reviewCount !== INITIAL_CONDITIONS.REVIEW_COUNT ||
        state.distance !== INITIAL_CONDITIONS.DISTANCE ||
        state.prefecture !== INITIAL_CONDITIONS.PREFECTURE ||
        state.heldDates !== INITIAL_CONDITIONS.HELD_DATES
      )
    },
    showSpace: (state) => {
      return state.showSpace
    },
    showEvent: (state) => {
      return state.showEvent
    },
    isOnlySpaceSelected: (state) => {
      return state.showSpace && !state.showEvent
    },
    isOnlyEventSelected: (state) => {
      return state.showEvent && !state.showSpace
    },
    keyword: (state) => {
      return state.keyword
    },
    likesCount: (state) => {
      return state.likesCount
    },
    reviewCount: (state) => {
      return state.reviewCount
    },
    distance: (state) => {
      return state.distance
    },
    prefecture: (state) => {
      return state.prefecture
    },
    heldDates: (state) => {
      return state.heldDates
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    setConditions(context, value) {
      // Filtering key check
      const validKeys = context.getters.conditionNames
      if (Object.keys(value).every(key => validKeys.includes(key))) {
        // Save state before update
        context.commit('storePrevCondition')
        // Update state
        context.commit('setConditions', { ...context.state, ...value })
      }
    },
  }
}