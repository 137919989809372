export default [
  /* Login */
  {
    path: '/admin/login',
    name: 'adminLogin',
    loadPage: 'admin/account/TheLogIn',
    meta: { appDomain: 'admin' },
  },
  {
    path: '/admin/logout',
    name: 'adminLogout',
    loadPage: 'admin/account/TheLogOut',
    meta: { appDomain: 'admin' }
  },
  /* Reset password */
  {
    path: '/admin/send_reset_password',
    name: 'adminSendResetPassword',
    loadPage: 'admin/account/TheSendResetPassword',
    meta: { appDomain: 'admin' }
  },
  {
    path: '/admin/reset_password',
    name: 'adminResetPassword',
    loadPage: 'admin/account/TheResetPassword',
    meta: { appDomain: 'admin' }
  },
  /* Space */
  {
    path: '/admin/space_list',
    name: 'adminSpaceList',
    loadPage: 'admin/space/TheSpaceList',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/space_detail',
    name: 'adminSpaceDetail',
    loadPage: 'admin/space/TheSpaceDetail',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/space_create',
    name: 'adminSpaceCreate',
    loadPage: 'admin/space/TheSpaceCreate',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/space_download_csv',
    name: 'adminSpaceDownloadCsv',
    loadPage: 'admin/space/TheSpaceDownloadCsv',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/space_upload_csv',
    name: 'adminSpaceUploadCsv',
    loadPage: 'admin/space/TheSpaceUploadCsv',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  /* Event */
  {
    path: '/admin/event_list',
    name: 'adminEventList',
    loadPage: 'admin/event/TheEventList',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/event_detail',
    name: 'adminEventDetail',
    loadPage: 'admin/event/TheEventDetail',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/event_create',
    name: 'adminEventCreate',
    loadPage: 'admin/event/TheEventCreate',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/event_download_csv',
    name: 'adminEventDownloadCsv',
    loadPage: 'admin/event/TheEventDownloadCsv',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/event_upload_csv',
    name: 'adminEventUploadCsv',
    loadPage: 'admin/event/TheEventUploadCsv',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  /* Review and Objection */
  {
    path: '/admin/review_list',
    name: 'adminReviewList',
    loadPage: 'admin/review/TheReviewList',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/review_handle',
    name: 'adminReviewHandle',
    loadPage: 'admin/review/TheReviewHandle',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/objection_handle',
    name: 'adminObjectionHandle',
    loadPage: 'admin/review/TheObjectionHandle',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  /* My page */
  {
    path: '/admin/my_page',
    name: 'adminMyPage',
    loadPage: 'admin/my-page/TheMyPage',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/my_page_edit_name_saved',
    name: 'adminMyPageEditNameSaved',
    loadPage: 'admin/my-page/TheMyPageEditNameSaved',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/my_page_edit_password_saved',
    name: 'adminMyPageEditPasswordSaved',
    loadPage: 'admin/my-page/TheMyPageEditPasswordSaved',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/my_page_edit_email_saved',
    name: 'adminMyPageEditEmailSaved',
    loadPage: 'admin/my-page/TheMyPageEditEmailSaved',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  /* News */
  {
    path: '/admin/news',
    name: 'adminNews',
    loadPage: 'admin/news/TheNewsList',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/news_edit',
    name: 'adminNewsEdit',
    loadPage: 'admin/news/TheNewsEdit',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/news_create',
    name: 'adminNewsCreate',
    loadPage: 'admin/news/TheNewsCreate',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/news_detail',
    name: 'adminNewsDetail',
    loadPage: 'admin/news/TheNewsDetail',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
  {
    path: '/admin/news_saved',
    name: 'adminNewsSaved',
    loadPage: 'admin/news/TheNewsSaved',
    meta: { appDomain: 'admin', requiredAuth: true, showHeader: true, showAside: true }
  },
]