<template>
  <div class="app-user-add-to-homescreen">
    <SlideIcon />
    <p class="introduction-title">スペースやイベントを投稿できます</p>
    <p class="introduction-description">
      マップからスペースやイベント情報を<br />
      投稿できます。<br />
      マップ画面右下の
      <img
        :src="require('@/assets/image/add-place/add_place_button.svg')"
        :style="{ width: '1.5rem', verticalAlign: 'middle' }"
      />
      ボタンから<br />
      投稿モードのオン/オフの切替が可能です。<br />
      ぜひ追加登録のご協力をお願いします。
    </p>
  </div>
</template>

<script>
import SlideIcon from '@/assets/image/add-place/add_place_introduction_icon.svg?component'

export default {
  name: 'AppUserAddPlaceIntroduction',
  components: {
    SlideIcon
  },
}
</script>

<style lang="scss" scoped>
.app-user-add-to-homescreen {
  text-align: center;
  .introduction-title {
    margin: 2rem 0 1rem 0;
    font-size: 18px;
    color: #707070;
    font-weight: bold;
  }
  .introduction-description {
    line-height: 2;
    font-size: 14px;
    color: #707070;
  }
}
</style>