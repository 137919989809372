import common from './common'
import { OBJECTION_DOCS_NAME, CHUNK_DOC_FIELD_LIMIT } from '@/constants/firestore'
import { putReview } from '@/helper/firestore/review'
import { sendObjectionNotification } from '@/helper/functions'

/**
 * objectionId かちゃんとバリデーションすること
 * Firestoreの処理自体は./common.jsにまとめること
 * このjsのやることは、要求された処理とObjectionドキュメントへの参照、
 *  必要な引数をcommon.jsの処理に渡し、エラーを監視し、完了したら値を返すこと
 *   commonのメソッド呼び出しと、値に対する操作が責務
 */

/**
 * 特定ドキュメント取得
 * @param {String} objectionId 
 * @return {Array.<Object>} 通報配列 
 */
const getObjection = async objectionId => {
  try {
    if (!objectionId) throw new Error('Objection document ID is not provided.')  
    return await common.get(OBJECTION_DOCS_NAME, objectionId)
  } catch (e) {
    throw new Error(`getObjection => ${e}`)
  }
}

/**
 * objectionsコレクションの全ドキュメント取得
 * @return {Array.<Object>} 通報配列
 */
const getAllObjections = async () => {
  try {
    return await common.get(OBJECTION_DOCS_NAME)
  } catch (e) {
    throw new Error(`getAllObjections => ${e}`)
  }
}

/**
 * 特定クチコミの通報を取得
 * @param {String} reviewId 
 * @return {Array.<Object>} 通報配列
 */
const getObjectionByReviewId = async reviewId => {
  try {
    if (!reviewId) throw new Error('Review document ID is not provided.')  
    return await common.getObjectionByReviewId(reviewId)
  } catch (e) {
    throw new Error(`getObjectionByReviewId => ${e}`)
  }
}

/**
 * 通報更新処理
 * @param {String} objectionId 
 * @param {Object} value 
 * @return {Object} 更新結果
 * { status: 'success' }
 */
const putObjection = async (objectionId, value) => {
  try {
    if (!objectionId) throw new Error('Objection document ID is not provided.')
    if (isEmptyObject(value)) throw new Error('Value is not provided.')

    return await common.put(OBJECTION_DOCS_NAME, objectionId, value)
  } catch (e) {
    throw new Error(`putObjection => ${e}`)
  }
} 

/**
 * 通報登録処理
 * 通報通知メールも同時に送信
 * Chunkデータも同時に作成
 * @param {Object} value 
 * @param {Object} chunk 通報上限到達によるクチコミを非公開化の際に更新するChunk
 * @returns {Object} 処理結果
 * {
 *    status: 'success',
 *    reviewClosed: 通報上限に達してクチコミを非公開化した場合は true
 * }
 */
const postObjection = async (value, chunk) => {
  try {
    if (isEmptyObject(value)) throw new Error('Value is not provided.')  

    // 初期値設定
    value.remarks = ''
    value.reviewPublication = false 

    // 通報データ登録(common.post失敗時は catch へ)
    await common.post(OBJECTION_DOCS_NAME, value)

    // 通報受付通知メール送信
    await sendObjectionNotification(value.spaceName, value.category)

    // この通報の対象クチコミに対する累計通報数が50を超えたらクチコミを非表示化する
    const obCount = await common.getObjectionCount(value.reviewId)

    if (obCount >= CHUNK_DOC_FIELD_LIMIT) {
      await putReview(
        {
          reviewId: value.reviewId,
          spaceId: value.spaceId,
          chunk: chunk,
          review: { publication: false }
        },
      )
      return { status: 'success', reviewClosed: true}
    } else {
      return { status: 'success', reviewClosed: false}
    }
  } catch (e) {
    throw new Error(`postObjection => ${e}`)
  }
} 

/**
 * 通報削除処理
 * @param {String} objectionId 
 * @return {Object} 処理結果 { status: 'success' }
 */
const deleteObjection = async objectionId  => {
  try {
    if (!objectionId) throw new Error('Objection document ID is not provided.')  
    return await common.delete(OBJECTION_DOCS_NAME, objectionId)
  } catch (e) {
    throw new Error(`deleteObjection => ${e}`)
  }
}

// util.js作って切れ
const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

export {
  getObjection,
  getAllObjections,
  getObjectionByReviewId,
  putObjection,
  postObjection,
  deleteObjection,
}