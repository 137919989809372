<template>
  <div class="app-user-modal-mananger">
    <IntroductionModal ref="introduction" />
    <AddToHomescreenModal
      ref="add-to-homescreen-modal"
      @change-button-clicke="() => {
        $store.dispatch('modal/setModalId', 'add-to-homescreen')
      }"
    />
    <AddPlaceIntroductionModal ref="add-place-introduction-modal" />
    <FailedGetLocationModal 
      ref="failed-get-location"
      @change-button-clicke="() => {
        $store.dispatch('modal/setModalId', 'location-setting')
      }"
    />
    <SpaceDetailModal ref="space-detail" />
    <ReviewModal ref="review" />
    <ReviewListModal ref="review-list" />
    <EventDetailModal ref="event-detail" />
    <ListModal ref="list" />
    <LikesModal ref="likes" />
    <NewsModal ref="news" />
    <OthersModal
      ref="others"
      :othersPage="othersPage"
      @others-page-change="(page) => {
        // modalID更新前に履歴保存
        $store.dispatch('modal/setModalHistory', this.modalId)
        $store.dispatch('modal/setModalId', page)
      }"
      @others-left-arrow-clicke="() => {
        if (othersPage === 'others') {
          $store.dispatch('modal/setModalId', 'map')
        } else {
          othersPage = 'others'
        }
      }"
      @others-logo-click="() => {
        $store.dispatch('modal/setModalId', 'map')
      }"
    />
  </div>
</template>

<script>
import IntroductionModal from '@/components/organisms/user/modals/AppUserIntroductionModal'
import SpaceDetailModal from '@/components/organisms/user/modals/AppUserSpaceDetailModal'
import EventDetailModal from '@/components/organisms/user/modals/AppUserEventDetailModal'
import ListModal from '@/components/organisms/user/modals/AppUserListModal'
import LikesModal from '@/components/organisms/user/modals/AppUserLikesModal'
import NewsModal from '@/components/organisms/user/modals/AppUserNewsModal'
import OthersModal from '@/components/organisms/user/modals/AppUserOthersModal'
import ReviewModal from '@/components/organisms/user/modals/AppUserReviewModal'
import ReviewListModal from '@/components/organisms/user/modals/AppUserReviewListModal'
import AddToHomescreenModal from '@/components/organisms/user/modals/AppUserAddToHomescreenModal'
import AddPlaceIntroductionModal from '@/components/organisms/user/modals/AppUserAddPlaceIntroductionModal'
import FailedGetLocationModal from '@/components/organisms/user/modals/AppUserFailedGetLocationModal'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserModalManager',
  components: {
    IntroductionModal,
    SpaceDetailModal,
    ReviewModal,
    ReviewListModal,
    EventDetailModal,
    ListModal,
    LikesModal,
    NewsModal,
    OthersModal,
    AddToHomescreenModal,
    AddPlaceIntroductionModal,
    FailedGetLocationModal
  },
  data: function () {
    return {
      othersPage: 'others',
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId', 'modalHistory' ]
    ),
  },
  watch: {
    modalId(value, oldValue) {
      // FIXMIE:
      // iPhoneの実機だと、スワイプバックした際にページの反応が一旦止まる問題対応
      // 表示対象以外のモーダルのhide処理が原因？
      // -> モーダル変更時にsetTimeoutを挟むと一応動作している

      Object.keys(this.$refs).forEach(key => {
        if (key === this.modalId) { // main modal
          // ブラウザバック検知用に履歴を追加
          window.history.pushState({}, '', '/')

          // その他ページへのアクセスの際は、サブページを初期化する
          if (this.modalId === 'others') {
            this.othersPage = 'others'
          }

          this.$refs[key].showModal()
        } else if (this.$_isSubModal(this.modalId)) {
          this.othersPage = this.modalId
          this.$refs['others'].showModal()
        } else {
          // close not target modals
          this.$refs[key].hideModal() 
        }
      }) 

      // ログイン画面等のURLでポップアップが表示されないよう判定
      if (this.$route.path === '/' && this.modalId === 'map') {
        this.$_checkAccessCount()
      }
    },
  },
  mounted: async function () {
    if(this.$route.name === 'map') {
      await this.$nextTick()
      this.$_checkAccessCount()
    }
  },
  methods: {
    $_isSubModal(id) {
      return [
        'about-posh',
        'posh-info',
        'location-setting',
        'add-to-homescreen',
        'review-guideline',
        'user-guideline',
        'privacy-policy',
      ].includes(id)
    },
    $_checkAccessCount() {
      // Display slideshow check
      const accessCount = this.$store.getters.accessCount

      if (+accessCount < 1) {
        this.$store.dispatch('modal/setModalId', 'introduction')
      }
      if (+accessCount === 3) {
        this.$store.dispatch('modal/setModalId', 'add-to-homescreen-modal')
      }

      // update access count (max 30 items)
      this.$store.dispatch('set_access_count', Math.min(+accessCount + 1, 30))
    },
  },
}
</script>

<style lang="scss">
/* Common */
.modal-header {
  border-bottom: none !important;
  height: $header-height;
}
.common-modal {
  height: calc(100% - #{$footer-height}) !important;
  .modal-dialog-scrollable {
    max-height: 100% !important;
    .modal-content {
      max-height: 100% !important;
    }
  }
}
.common-modal-dialog {
  margin: 0 !important;
  border: none;
  height: 100%;
}
.common-modal-body {
  padding: 0 !important;
}
/* overwrite */
.card-body {
  padding: 1.25rem 1.75rem;
}
.card-text {
  margin-bottom: 2rem;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>