<template>
  <div class="app-user-review">
    <!-- Header -->
    <div class="review-header-title">
      <IconReview class="review-header-title-icon"/>
      <div class="review-header-title-text">クチコミ投稿</div>
    </div>

    <!-- Body -->
    <div class="review-body">
      <div class="sub-title">あなたの属性を選択してください</div>
      <div class="radio-button-input-form">
        <input
          id="01-A"
          name="radio01"
          type="radio"
          value="移動販売事業者"
          v-model="userTypeValue"
        >
        <label for="01-A" class="radio01">移動販売事業者</label>
        <input
          id="01-B"
          name="radio01"
          type="radio"
          value="利用客"
          v-model="userTypeValue"
        >
        <label for="01-B" class="radio01">利用客</label>
        <input
          id="01-C"
          name="radio01"
          type="radio"
          value="その他"
          v-model="userTypeValue"
        >
        <label for="01-C" class="radio01">その他</label>
      </div>

      <div class="sub-title mt-4" :style="{ display: 'flex', alignItems: 'center' }">
        クチコミ内容<span :style="{ color: 'red', marginLeft: '8px', fontSize: '10px' }">必須</span>
      </div>
      <p class="review-limitation-text">
        140字以内でご入力ください。（現在：<span :style="{ color: reachLimitation ? 'red' : '#707070' }">{{ trimedReviewLength }}</span>文字）
      </p>
      <CommonTextarea
        v-model="review"
        :prefix="'user-review'"
        :userStyle="{
          color: '#A5A5A5',
          fontSize: '16px',
          border: '1px solid #A5A5A5',
          borderRadius: '4px',
          padding: '16px',
        }"
        :rows="'7'"
        :maxlength="String(maxReviewLength)"
      />
      <div class="remark-notes pt-2">
        Poshspaceでは、法令に違反するクチコミや誰かを傷つけたり、
        攻撃したりするようなクチコミの投稿を禁止しています。投稿の
        際は、Poshspaceが定めた
        <span
          class="review-guideline-link"
          @click="() => {
            $store.dispatch('modal/setModalHistory', this.modalId)
            $store.dispatch('modal/setModalId', 'review-guideline')
          }"
        >
          クチコミ投稿ガイドライン
        </span>をよくお読みいただいた上でご利用ください。
        <br />
        ガイドラインに違反するクチコミは、非表示や削除対応を行う場合があります。
      </div>

      <!-- Preview button -->
      <div class="pt-3">
        <button
          id="submit-button"
          :disabled="!trimedReviewLength"
          :class="{ 'disabled-button': !trimedReviewLength }"
          @click="$emit('show-preview-click')"
        >
          入力内容を確認する 
        </button>
      </div>

      <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '32px 0 0 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>
  </div>
</template>

<script>
import CommonTextarea from '@/components/atoms/common/AppTextarea'
import IconReview from '@/assets/image/icon_review.svg?component'
import { getMaxLength } from '@/helper/common'
import inobounce from 'inobounce'
import { mapGetters } from 'vuex'

const MAX_REVIEW_LENGTH = 140

export default {
  name: 'AppUserReview',
  components: {
    CommonTextarea,
    IconReview,
  },
  data() {
    return {
      userTypeValue: '移動販売事業者',
      review: '',
    }
  },
  computed: {
    ...mapGetters(
      'modal',
      [ 'modalId' ]
    ),
    maxReviewLength() {
      return getMaxLength(MAX_REVIEW_LENGTH, this.review, true)
    },
    reachLimitation() {
      return this.trimedReviewLength === MAX_REVIEW_LENGTH
    },
    trimedReviewLength() {
      return this.review.replace(/[\s|\n|\r|\r\n]/g, '').length
    },
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
  watch: {
    userTypeValue() {
      this.$emit('value-change', { userType: this.userTypeValue, review: this.review } )
    },
    review() {
      this.$emit('value-change', { userType: this.userTypeValue, review: this.review } )
    }
  },
  mounted: async function() {
    // enable pull down bounce
    inobounce.disable()
  },
}
</script>

<style lang="scss" scoped>
.app-user-review {
  width: 100%;
  height: auto;
  padding: 0;
  overflow-y: auto;
}
.review-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.review-header-title-icon {
  margin-right: 16px;
}
.review-header-title-text {
  color: #707070;
  font-size: 20px;
}
.review-body {
  padding: 20px;
  .sub-title {
    color: #707070;
    font-size: 16px;
  }
  .review-limitation-text {
    color: #707070;
    font-size: 12px;
    margin: 8px 0;
  }
  .review-over-limitation-text {
    color: #F56350;
    font-size: 12px;
    margin: 8px 0;
  }
  .radio-button-input-form {
    display: flex;
    flex-direction: column;
    /* overwrite */
    input[type=radio] {
      display: none;
    }
    .radio01 {
      color: #A5A5A5;
      user-select: none;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      position: relative;
      width: auto;
      margin: 16px 0 0 0;
      padding-left: 32px;
    }
    .radio01::before {
      background: #fff;
      border: 1px solid #A5A5A5;
      border-radius: 50%;
      content: '';
      display: block;
      height: 20px;
      left: 5px;
      margin-top: -10px;
      position: absolute;
      top: 50%;
      width: 20px;
    }
    .radio01::after {
      background: #12B6D4;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 10px;
      margin-top: -5px;
      opacity: 0;
      position: absolute;
      top: 48%;
      width: 10px;
    }
    input[type=radio]:checked + .radio01::after {
      opacity: 1;
    }
  }
  .remark-notes {
    color: #A5A5A5;
    font-size: 12px;
    .review-guideline-link {
      color: #12B6D4;
      text-decoration: underline;
    }
  }
  #submit-button {
    width: 100%;
  }
  .disabled-button {
    background-color: gray !important;
  }
}
</style>