<template>
  <button
    class="app-user-wide-button"
    :style="{
      backgroundColor: disabled
        ? 'gray'
        : backgroundColor,
      ...customStyle
    }"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'AppWideButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#12B6D4'
    },
    disabled: {
      type: Boolean,
      default: false 
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-wide-button {
  font-size: 14px;
  padding: 16px;
  width: 100%;
  min-height: 24px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer
}
</style>
