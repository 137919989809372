<template>
  <div class="app-user-header-sort-button">
    <Button
      v-for="bProps in buttonProps"
      :key="`header-sort-button-${bProps.key}`"
      :buttonStyle="{}"
      @on-button-click="() => bProps.action()"
    >
      <template v-slot:buttonContents>
        {{ bProps.label }}
        <component :is="bProps.sortDirection === 'down' ? 'icon-down' : 'icon-up'" />
      </template>
    </Button>
  </div>
</template>

<script>
import IconUp from '@/assets/image/icon_sort_up.svg?component'
import IconDown from '@/assets/image/icon_sort_down.svg?component'
import Button from '@/components/atoms/user/AppUserButton'

export default {
  name: 'AppUserHeaderSortButtons',
  components: {
    'icon-up': IconUp,
    'icon-down': IconDown,
    Button
  },
  props: {
    buttonProps: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
