import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import samplePlugin from './js/samplePlugin'
import VueCarousel from 'vue-carousel'
import { initialState } from '@/store/initialState'
import Swal from 'sweetalert2'
import VueLazyload from 'vue-lazyload'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import VueScrollTo from 'vue-scrollto'
import VueGtm from '@gtm-support/vue2-gtm';
import moment from 'moment'

Vue.use(VueGtm, { id: 'GTM-5G2RBQ2' })

Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)

// for favicon
import "./registerServiceWorker";
Vue.config.productionTip = false;

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

// Make BootstrapVue available throughout the project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// console.log(samplePlugin)
// Vue.use(samplePlugin)

// Make vue-carousel available throughout the project
Vue.use(VueCarousel)
// Set lazy loader
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('@/assets/image/place_default.png'),
  //error: require('@/assets/image/alt_thumb.png'),
  loading: require('@/assets/image/loading_image.svg'),
  attempt: 1
})
// image viewer
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})
 
// set DateTimePicke
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

// default
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

// hiding hint page at startup
Vue.config.productionTip = false
// sweetalert2 config
Vue.prototype.$alert = Swal.mixin({
  keydownListenerCapture: true, // モーダル上で表示されたときにEscキー押下を先にキャプチャーする
  heightAuto: false // bodyにheight:auto !importantを付与しない
})

// ロケール設定(moment)
moment.locale('ja')

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // clear localstrage for using vue-persistedstate
    // localStorage.setItem('initialState', JSON.stringify(initialState))
  }
}).$mount('#app')
