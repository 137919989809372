export default {
  namespaced: true,
  state: {
    showBackArrow: false,
    // storeをクリアすると検索バーが消えるパターンがあるのでデフォルトをtrueとする
    showKeywordInput: true,
    // storeをクリアすると検索バーが消えるパターンがあるのでデフォルトをtrueとする
    showSwitchButton: true,
    showFilterBadge: false,
    centeringLogo: false
  },
  mutations: {
    resetState(state) {
      state.showBackArrow = false
      // storeをクリアすると検索バーが消えるパターンがあるのでデフォルトをtrueとする
      state.showKeywordInput = true
      // storeをクリアすると検索バーが消えるパターンがあるのでデフォルトをtrueとする
      state.showSwitchButton = true
      state.showFilterBadge = false
      state.centeringLogo = false
    },
    setShowBackArrow: (state, value) => {
      state.showBackArrow = value
    },
    setShowKeywordInput: (state, value) => {
      state.showKeywordInput = value
    },
    setShowSwitchButton: (state, value) => {
      state.showSwitchButton = value
    },
    setShowFilterBadge: (state, value) => {
      state.showFilterBadge = value
    },
    setCenteringLogo: (state, value) => {
      state.centeringLogo = value
    },
  },
  getters: {
    showBackArrow: (state) => {
      return state.showBackArrow
    },
    showKeywordInput: (state) => {
      return state.showKeywordInput
    },
    showSwitchButton: (state) => {
      return state.showSwitchButton
    },
    showFilterBadge: (state) => {
      return state.showFilterBadge
    },
    centeringLogo: (state) => {
      return state.centeringLogo
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    setShowBackArrow: ({ commit }, value) => {
      commit('setShowBackArrow', value)
    },
    setShowKeywordInput: ({ commit }, value) => {
      commit('setShowKeywordInput', value)
    },
    setShowSwitchButton: ({ commit }, value) => {
      commit('setShowSwitchButton', value)
    },
    setShowFilterBadge: ({ commit }, value) => {
      commit('setShowFilterBadge', value)
    },
    setCenteringLogo: ({ commit }, value) => {
      commit('setCenteringLogo', value)
    },
  }
}
