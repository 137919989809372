export default {
  namespaced: true,
  state: {
    modalId: 'map',
    modalHistory: []
  },
  mutations: {
    resetState: (state, value) => {
      state.modalId = 'map',
      state.modalHistory = []
    },
    setModalId: (state, value) => {
      state.modalId = value
    },
    setModalHistory: (state, value) => {
      state.modalHistory = [
        value,
        ...state.modalHistory
      ]
      // 最大10件を保持
      if (state.modalHistory.length > 10) {
        state.modalHistory = [...state.modalHistory].splice(0, 10)
      }
    },
    replaceModalHistory: (state, value) => {
      if (!Array.isArray(value)) return
      state.modalHistory = value.length > 10
        ? value.splice(0, 10)
        : value
    },
  },
  getters: {
    modalId: (state) => {
      return state.modalId
    },
    modalHistory: (state) => {
      return state.modalHistory
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    setModalId: ({ commit }, value) => {
      commit('setModalId', value)
    },
    setModalHistory: ({ commit }, value) => {
      commit('setModalHistory', value)
    },
    replaceModalHistory: ({ commit }, value) => {
      commit('replaceModalHistory', value)
    },
  }
}
