<template>
  <div class="app-user-map-contents">
    <!-- MAP (out of control from z-index) -->
    <div class="map-provider" ref="map"></div>

    <!-- Top UI -->
    <div class="top-ui">
      <template v-if="showUiLayer">
        <slot name="topUi" />
      </template>
    </div>

    <!-- Middle UI -->
    <div class="middle-ui">
      <template v-if="showUiLayer">
        <slot name="middleUi" />
      </template>
    </div>

    <!-- Bottom UI -->
    <div :class="['bottom-ui', buttomUiClass]">
      <template v-if="showUiLayer">
        <slot name="bottomUi" v-bind:places="inBoundsData" />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 責務：マップ初期化、受け取ったplaceから bounds 内の対象を表示、マップイベント発行
 */
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import { IconLayer } from '@deck.gl/layers'
import { LoaderSingleton, getIcon } from '@/helper/map'
import { isInBounds } from '@/helper/user'
import { DEFAULT_POSITION, DEFAULT_ZOOM, CULSTER_ZOOM_LEVEL } from '@/constants/user'
import IconClusterLayer from './mapAssets/ClusterLayer.js'
import iconMapping from './mapAssets/location-icon-mapping.json'
import { mapGetters } from 'vuex'

const LAYER_ID = {
  CURRENT_LOCATION: 'current_location_layer',
  PLACE: 'place',
  ICON_CLUSTER: 'icon_cluster'
}
// プレイスレイヤとクラスタレイヤは同一レイヤでトグル関係
const LAYER_INDEX = {
  CURRENT_LOCATION: 0,
  PLACE_OR_ICON_CLUSTER: 1,
}
// 最大縮小レベル（クラスタリングの表示数が狂うため）
const MIN_ZOOM_LEVEL = 5

export default {
  name: 'AppUserMapContents',
  data() {
    return {
      inBoundsData: [],
      deck: null,
      layers: [],
      googleMapsOverlay: null,
      iconAtlas: require('@/assets/image/map/location-icon-atlas.png'),
    }
  },
  props: {
    mapCenter: {
      type: Object,
      default: () => {}
    },
    mapZoom: {
      type: Number,
      default: null
    },
    zoomControl: {
      type: Boolean,
      default: false
    },
    mapOptions: {
      type: Object,
      default: () => ({
        disableDefaultUI: true,
        clickableIcons: false,
        keyboardShortcuts: false,
        // zoomControl: false,
        streetViewControl: false,
        gestureHandling: 'auto'
      })
    },
    showUiLayer: {
      type: Boolean,
      default: false
    },
    panTo: {
      type: Object,
      default: null
    },
    verticalSwipeDirection: {
      type: String,
      default: ''
    },
    places: {
      type: Array,
      default: () => []
    },
    enabledCluster: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(
      'map',
      [
        'mapBounds',
        'currentLocation',
        'selectedMarker'
      ]
    ),
    buttomUiClass: function () {
      return {
        normal: this.verticalSwipeDirection === 'up',
        pushed: this.verticalSwipeDirection === 'down'
      }
    },
    deckData: function () {
      return this.inBoundsData.map(this.$_formatDataToDeckLayer)
    },
    targetMarker: function () {
      return this.selectedMarker
        ? this.selectedMarker
        : { placeId: null } // Null place for default
    },
    showCluster: function () {
      if (!this.enabledCluster) return false
      if (this.mapZoom < CULSTER_ZOOM_LEVEL) {
        // 選択されたマーカー情報をクリア
        this.$store.dispatch('map/setSelectedMarker', null)
        // Carousel を swipe down する
        this.$emit('vertical-direction-change', 'down')
        return true
      } else {
        return false
      }
    }
  },
  created: function () {
    if (!this.googleMapsLoader) this.$_createLoader()
  },
  mounted: async function () {
    await this.$nextTick()
    if (!this.map) await this.$_loadMap()
    this.$_moveToTargetMarker() 
  },
  watch: {
    currentLocation: {
      handler() {
        if (this.currentLocation) {
        this.$_updateCurrentLocationMarkerLayer()
        }
      },
      deep: true
    },
    targetMarker() {
      this.$_moveToTargetMarker() 
    },
    panTo: {
      handler() {
        this.$_moveMap(this.panTo)
      },
      deep: true
    },
    places() {
      this.inBoundsData = this.places.filter(p => {
        const rs = this.$_isInBounds(p)
        return rs
      })
    },
    async inBoundsData() {
      if (!this.map) await this.$_loadMap()
      this.$_updatePlaceMarkerLayer()
    },
  },
  methods: {
    $_isInBounds: function (place) {
      // coords, boundsがなければtrue
      // FIXME:
      // 登録時に何らかの原因で座標が登録されないことがあるので、登録・更新時に
      // エラーを出して弾くこと
      return (
        !place.coords ||
        !this.mapBounds ||
        isInBounds(place, this.mapBounds)
      )
    },
    $_moveToTargetMarker() {
      if (this.targetMarker && this.targetMarker.coords) {
        this.$_moveMap(this.targetMarker.coords)
      }
    },
    $_moveMap(to) {
      if (this.map && to) {
        // マーカークリック経由だとDeck上のオブジェクトで配列が入ってくるため
        const coords = Array.isArray(to)
          ? { lat: to[1], lng: to[0] }
          : to
        this.map.panTo(
          new this.google.maps.LatLng(
            coords.lat,
            coords.lng
          )
        )
      }
    },
    /**
     * google mapをロードするクラス生成
     */
    $_createLoader: function () {
      this.googleMapsLoader = new LoaderSingleton(
        process.env.VUE_APP_GOOGLE_MAP_KEY,
        {} // loader option
      )
    },
    /**
     * google mapをロード
     */
    $_loadMap: async function () {
      try {
        if (!this.$refs['map']) return

        if (!this.google) {
          this.google = await this.googleMapsLoader.load()
        }

        const mapOptions = {
          zoom: this.mapZoom || DEFAULT_ZOOM,
          center: this.mapCenter || DEFAULT_POSITION,
          zoomControl: this.zoomControl,
          minZoom: MIN_ZOOM_LEVEL,
          // zoomControlOptions: { //ズーム コントロール
          //   position: this.google.maps.ControlPosition.RIGHT_CENTER,
          // },
          ...this.mapOptions
        }

        this.map = new this.google.maps.Map(this.$refs['map'], mapOptions)
        this.google.maps.event.addListenerOnce(this.map, 'idle', this.$_loadEnd)
      } catch (error) {
        alert(`マップの読み込みに失敗しました。（${error}）`)
      }
    },
    /**
     * google map初期化完了
     */
    $_loadEnd: async function () {
      // if (!this.map) await this.$_loadMap()
      // Deck初期化
      // NOTE: リロード時に重複して生成されないよう存在チェック
      if (!this.googleMapsOverlay) await this.$_loadOverlay()
      if (this.places.length) this.$_updatePlaceMarkerLayer()
      // マップのイベント設定
      this.map.addListener('idle', this.$_boundsChangeEnd)
      this.$emit('map-loadend', { map: this.map, google: this.google })
    },
    /**
     * マップのbounds変更が完了したときの処理
     */
    $_boundsChangeEnd: function () {
      // center, boundsはそのままだと使いづらいので変換
      const rawCenter = this.map.getCenter()
      const center = { lat: rawCenter.lat(), lng: rawCenter.lng() }
      const rawBounds = this.map.getBounds()
      if(!rawBounds) return true
      const northEast = rawBounds.getNorthEast()
      const southWest = rawBounds.getSouthWest()
      const bounds = {
        north: northEast.lat(),
        south: southWest.lat(),
        west: southWest.lng(),
        east: northEast.lng(),
      }
      const zoom = this.map.getZoom()

      this.$store.dispatch('map/setMapBounds', bounds)
      this.$store.dispatch('map/setMapCenter', center)
      this.$store.dispatch('map/setMapZoom', zoom)

      // マップ上に表示するプレイスをフィルタリング
      this.inBoundsData = this.places.filter(p => {
        const rs = this.$_isInBounds(p)
        return rs
      })

      this.$emit('map-bounds-change-end')
    },
    /**
     * プレイスデータをdeck.glに乗せるフォーマットに変換
     * @param {Object} spacd スペースデータ
     */
    $_formatDataToDeckLayer (data) {
      const coords = data.coords || data.coordinates
      // FIXME: スペースとイベントで都道府県のキーが異なる
      const pref = data.pref || data.prefecture
      return {
        placeId: data.placeId,
        name: data.eventName || data.spaceName,
        address: pref + data.area + data.address,
        coordinates: [coords.lng, coords.lat],
        type: data.type
      }
    },
    /**
     * google mapにdeck.glレイヤー（ピン表示）を乗せる
     * @param {Array.<Object>} スペースデータ配列
     */
     $_loadOverlay: async function () {
      // 初期描画用アイコンレイヤー作成
      this.googleMapsOverlay = new GoogleMapsOverlay()
      // GoogleMaps上に描画
      await this.googleMapsOverlay.setMap(this.map)
      // 現在位置を既に持っていれば描画
      if (this.currentLocation) {
        this.$_updateCurrentLocationMarkerLayer()
      }
      this.$emit('map-loadend', { map: this.map, google: this.google })
    },
    $_isSelectedMarker(place) {
      return (
        place &&
        place.placeId &&
        place.placeId === this.targetMarker.placeId
      )
    },
    $_getPlaceMarkerLayer: function () {
      // マーカーIDが指定されている = マーカーをクリックした
      // -> 選択されたマーカーのスペース情報を末尾にして一番最後に描画させることで重畳のトップにだす
      // ※元の配列 this.deckData を破壊しない処理
      const data = this.targetMarker
        ? this.deckData
          .filter(d => !this.$_isSelectedMarker(d))
          .concat(this.deckData.filter(d => this.$_isSelectedMarker(d)))
        : [ ...this.deckData ]
      const layerProps = {
        data,
        pickable: true,
        getPosition: d => d.coordinates,
      }

      return this.showCluster
        ? new IconClusterLayer({
          ...layerProps,
          id: LAYER_ID.ICON_CLUSTER,
          sizeScale: 40,
          iconAtlas: this.iconAtlas,
          iconMapping,
          onClick: this.$_clickClusterIcon,
        })
        : new IconLayer({
          ...layerProps,
          id: LAYER_ID.PLACE,
          sizeScale: 13,
          getIcon: (d) => getIcon(d, this.targetMarker.placeId),
          getSize: d => { return this.$_isSelectedMarker(d) ? 3.5 : 3 },
          onClick: this.$_clickPlaceIcon,
        })
    },
    $_clickPlaceIcon(data) {
      // レイヤーを更新
      this.$_updatePlaceMarkerLayer()
      // 選択されたマーカー情報をストアに保存
      this.$store.dispatch('map/setSelectedMarker', data.object)
      // Carousel を swipe up する
      this.$emit('vertical-direction-change', 'up')
    },
    $_clickClusterIcon(data) {
      if (!data.objects) {
        // single place
        this.map.setCenter({
          lat: data.object.coordinates[1],
          lng: data.object.coordinates[0],
        })
        this.map.setZoom(CULSTER_ZOOM_LEVEL)
      } else {
        // multi places
        const bounds = new this.google.maps.LatLngBounds()
        for (let i = 0; i < data.objects.length; i++) {
          bounds.extend(new this.google.maps.LatLng(
            data.objects[i].coordinates[1],
            data.objects[i].coordinates[0],
          ))
        }
        this.map.fitBounds(bounds)
      }
    },
    $_updatePlaceMarkerLayer: async function () {
      if (!this.googleMapsOverlay) await this.$_loadOverlay()
      const placeLayer = this.$_getPlaceMarkerLayer()
      this.layers[LAYER_INDEX.PLACE_OR_ICON_CLUSTER] = placeLayer
      this.googleMapsOverlay.setProps({ layers: [...this.layers].filter(l => l) })
    },
    $_getCurrentLocationMarkerLayer: function () {
      // 現在地マーカーとプレイスマーカーはレイヤを分ける
      return new IconLayer({
          id: LAYER_ID.CURRENT_LOCATION,
          data: [{
            coordinates: [
              this.currentLocation.lng,
              this.currentLocation.lat,
            ],
            placeId: 'current_location'
          }],
          pickable: true,
          getIcon: d => ({
            url: require('@/assets/image/map/pin-position-info.png'),
            width: 47,
            height: 31,
            anchorY: 31 
          }),
          sizeScale: 13,
          getPosition: d => d.coordinates,
          getSize: d => 3,
        })
    },
    $_updateCurrentLocationMarkerLayer: function () {
      if (!this.googleMapsOverlay) return
      const currentLocationLayer = this.$_getCurrentLocationMarkerLayer()
      this.layers[LAYER_INDEX.CURRENT_LOCATION] = currentLocationLayer
      this.googleMapsOverlay.setProps({ layers: [...this.layers].filter(l => l) })
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-map-contents {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .map-provider {
    width: 100%;
    height: 100%;
  }
  .top-ui {
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0);
  }
  .middle-ui {
    position: fixed;
    right: 0;
    bottom: $footer-height + 130; // FIXME: BottomUIの高さも変数化すること
  }
  .bottom-ui {
    margin-bottom: 24px;
    width: 100vw;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0);
    transition: bottom 0.3s linear;
  }
  .normal {
    bottom: 0;
  }
  .pushed {
    bottom: -100px;
  }
}
</style>
