<template>
  <div
   class="chip-container"
   :class="{ wide: wide }"
   :style="{ color: chipColor, backgroundColor: bkColor }"
  >
    <p class="chip-title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'default'
    },
    textColor: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chipColor: function() {
      if (this.textColor) {
        return this.textColor
      } else if (this.status === 'confirmed' || this.status === 'approved') {
        return '#12B6D4'
      } else if (
        this.status === 'reject' ||
        this.status === 'date_ng' ||
        this.status === 'examination_ng' ||
        this.status === 'rejected'
      ) {
        return '#E56C58'
      } else if (this.status === 'held') {
        return '#12B6D4'
      } else if (this.status === 'canceled' || this.status === 'cancel_requested') {
        return '#E56C58'
      } else if (this.status === 'reviewing' || this.status === 'cancel_reviewing') {
        return '#95BB00'
      } else {
        return '#707070'
      }
    },
    bkColor: function() {
      if (this.backgroundColor) {
        return this.backgroundColor
      } else if (this.status === 'confirmed') {
        return '#ECF1F2'
      } else if (this.status === 'reject' || this.status === 'date_ng' || this.status === 'examination_ng') {
        return '#F4E6E4'
      } else if (this.status === 'held') {
        return '#ECF1F2'
      } else if (this.status === 'canceled' || this.status === 'cancel_requested') {
        return '#F4E6E4'
      } else if (this.status === 'reviewing' || this.status === 'cancel_reviewing') {
        return '#EAF0E7'
      } else {
        return '#ECF1F2'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chip-container {
  font-size: 12px;
  display: table;
  border-radius: 4px;
  text-align: center;
  padding: 0 0.5rem;
  flex-shrink: 0;
}
.wide {
  padding: 6px 8px;
  margin: 0 4px;
}
.chip-title {
  margin-bottom: 0;
  line-height: 1.75;
}
.chip-reject {
  color: #E56C58;
  background-color: #F4E6E4;
}
.chip-cancel {
  color: #E56C58;
  background-color: #F4E6E4;
}
.chip-confirmed {
  color: #12B6D4;
  background-color: #ECF1F2;
}
.chip-held {
  color: #12B6D4;
  background-color: #ECF1F2;
}
.chip-reviewing {
  color: #95BB00;
  background-color: #EAF0E7;
}
</style>