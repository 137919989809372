<template>
  <div class="app-user-about-posh">
    <!-- Poshspace Info -->
    <img v-lazy="topImg" width="100%"/>

    <div class="bk-white-container">
      <div class="base-text center" :style="{margin: '2rem 0'}">
        Poshspace(ポッシュスペース)は、<br />
        移動販売・キッチンカー事業者のための<br />
        <span class="yellow-marker" :style="{fontSize: '20px'}">
          &nbsp;&nbsp;出店スペース検索サイト
        </span>
        です。
      </div>

      <hr :style="{borderTop: '1px solid #707070'}"/>

      <div class="center title" :style="{fontSize: '20px'}">
        スペース探しはとにかく大変！<br />
        Poshspaceで<br />
        スペース探しをカンタンに
      </div>

      <div class="center">
        <img v-lazy="img2" class="center-image" />
      </div>

      <div class="base-text center" :style="{marginBottom: '2rem'}">
        出店場所（スペース）探しに苦労していませんか？<br />
        弊社もイチ移動販売事業者として<br />
        出店場所を探すことに多くの時間を費やしてきました。<br />
        出店のために沢山の書類を用意したのに<br />
        出店できなかったことも. . . .
      </div>

      <div class="base-text center" :style="{marginBottom: '2rem'}">
        キッチンカーの場合、<br />
        仕入れや仕込み、販売や宣伝活動など、<br />
        やらなければならない事はたくさんあると思います。
      </div>

      <div class="base-text center" :style="{marginBottom: '2rem'}">
        <span class="blue-text">
          『出店場所探しはなるべく時間をかけずに、<br />
          サービスの追求や営業など<br />
          本来やりたいことに注力してもらいたい…』<br />
        </span>
        そんな願いからPoshspaceは生まれました。
      </div>

      <div class="base-text center" :style="{marginBottom: '2rem'}">
        Poshspaceがあなたの活動を<br />
        少しでもサポートできれば嬉しいです。
      </div>

      <div class="center" :style="{marginBottom: '1rem'}">
        <img src="@/assets/image/about-posh/about_posh_3.png" />
      </div>
    </div>
    <div class="bk-gray-container">
      <div class="center title">
        全国のスペースとイベントを<br />
        同時にチェック！
      </div>

      <div class="center">
        <img src="@/assets/image/about-posh/about_posh_4.png" width="100%"/>
      </div>

      <div class="small-text" :style="{marginTop: '8px'}">
        一部画像引用：https://www.summersonic.com/news/220428-3/
      </div>

      <div class="base-text" :style="{marginTop: '1rem'}">
        Poshspaceは、出店場所（スペース）や最新のイベントの募集情報をマップで検索できます。
      </div>

      <div class="center title">
        マップでスピーディーに検索！
      </div>

      <div class="center">
        <img src="@/assets/image/about-posh/about_posh_5.png" width="100%"/>
      </div>

      <div class="base-text" :style="{marginTop: '1rem', paddingBottom: '2.5rem'}">
        マップなら自分の拠点近くの情報をひと目で確認。サクッと出店候補のスペースを探しましょう！<br />
        全国に情報があるので、現在地を取得すれば近所にステキなスペースが見つかるかも♪<br />
        キーワード検索やリスト表示も可能です！
      </div>
    </div>
    <div class="bk-blue-container">
      <div class="center title" :style="{padding:'2rem 0', color: '#fff', fontSize: '28px', letterSpacing: '2px'}">
        <p :style="{lineHeight: '1', marginBottom: '0'}">Poshspaceの</p>
        <p :style="{lineHeight: '1', marginBottom: '0'}"><span :style="{fontSize: '36px', color: 'yellow'}">3</span>つのポイント</p>
      </div>

      <div :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '18px',
        paddingBottom: '2rem'
      }">
        <div>
          <img src="@/assets/image/about-posh/about_posh_6.png" />
          <div class="base-text center" :style="{color: '#fff', paddingTop: '0.5rem', lineHeight: '1.25'}">
            出店実績を<br />
            基にした情報
          </div>
        </div>

        <div>
          <img src="@/assets/image/about-posh/about_posh_7.png" />
          <div class="base-text center" :style="{color: '#fff', paddingTop: '0.5rem', lineHeight: '1.25'}">
            最新イベント<br />
            情報をチェック 
          </div>
        </div>

        <div>
          <img src="@/assets/image/about-posh/about_posh_8.png" />
          <div class="base-text center" :style="{color: '#fff', paddingTop: '0.5rem', lineHeight: '1.25'}">
            人気の場所が<br />
            分かる
          </div>
        </div>
      </div>

      <div class="bk-white-sub-container">
        <div class="base-text center" :style="{fontSize: '20px'}">
          <span class="yellow-marker">&nbsp;&nbsp;①出店実績を基にした情報&nbsp;&nbsp;</span>
        </div>

        <div class="center">
          <img src="@/assets/image/about-posh/about_posh_9.png" width="100%"/>
        </div>

        <div class="base-text" :style="{marginTop: '8px', lineHeight: '1.75'}">
          実際の出店情報や、リアルタイムの出店情報も検索することができ、リアルな情報が得られます。募集ページからすばやく募集情報にアクセスできます。
        </div>
      </div>
      <div class="bk-white-sub-container">
        <div class="center">
          <div class="base-text center" :style="{fontSize: '20px'}">
            <span class="yellow-marker">&nbsp;&nbsp;②最新イベント情報をチェック&nbsp;&nbsp;</span>
          </div>
        </div>

        <div class="center">
          <img src="@/assets/image/about-posh/about_posh_10.png" width="100%"/>
        </div>

        <div class="small-text">
          一部画像引用：https://www.summersonic.com/news/220428-3/
        </div>

        <div class="base-text" :style="{marginTop: '8px', lineHeight: '1.75'}">
          イベント情報は日々更新！募集期間中のイベント情報が掲載されます。募集期間が終了する前にイベント情報掲載ページにアクセスして応募しましょう！
        </div>
      </div>
      <div class="bk-white-sub-container">
        <div class="center">
          <div class="base-text center" :style="{fontSize: '20px'}">
            <span class="yellow-marker">&nbsp;&nbsp;③人気の場所が分かる&nbsp;&nbsp;</span>
          </div>
        </div>

        <div class="center">
          <img src="@/assets/image/about-posh/about_posh_11.png" width="100%"/>
        </div>

        <div class="base-text" :style="{marginTop: '8px', lineHeight: '1.75'}">
          お気に入り数が多いほど注目度の高いスペースやイベントです。
          お気に入りボタンを押すと、「お気に入り一覧」に保存されるので、お気に入りスペースやイベントはどんどんリストアップしておきましょう！
        </div>
      </div>
    </div>
    <div class="bk-white-container">
      <div class="center title">
        出店情報募集中！
      </div>

      <div class="center blue-text" :style="{marginBottom: '1rem'}">
        Twitterで「#poshspace」をつけてツイート
      </div>

      <div class="center">
        <img src="@/assets/image/about-posh/about_posh_12.png" width="100%" />
      </div>

      <div class="base-text" :style="{margin: '8px 0', lineHeight: '1.75'}">
        Poshspaceではリアルな出店情報を募集しています。<br />
        応募方法はいつもの出店情報をツイートをする際に、「#poshspace」をつけるだけ！あなたのツイートがPoshspaceに掲載されるかも…
      </div>

      <div class="small-text" :style="{marginBottom: '3rem'}">
        ※掲載をお約束するものではございませんのでご了承ください。
      </div>

      <hr :style="{borderTop: '1px solid #707070'}"/>

      <div class="center title">
        情報のご提供・掲載のご依頼は<br />
        お問い合わせください！
      </div>

      <ul class="blue-text" :style="{fontSize: '14px', marginBottom: '2rem'}">
        <li>・Poshspaceに掲載されていないスペース、最新の<br />&nbsp;&nbsp;&nbsp;&nbsp;情報など</li>
        <li>・掲載を希望のスペースのオーナー様</li>
      </ul>

      <div class="base-text" :style="{marginBottom: '2rem', lineHeight: '1.75'}">
        多くのスペース、最新のイベント情報を掲載するよう心掛けておりますが、Poshspaceはまだまだ発展途上のため、情報が不足していたり、最新の情報でないこともあるかと思います。ぜひ皆様のお力をお貸しください！
      </div>

      <button id="inquiry-button" @click="$_openInquiryForm">
        お問い合わせ
      </button>

      <div
        v-if="showAds"
        :style="{
          width: '100%',
          margin: '32px 0'
        }"
      >
        <img width="100%" src="@/assets/image/ad_sample.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { openInquiryForm } from '@/helper/common'

export default {
  name: 'AppUserAboutPosh',
  data() {
    return {
      // あまり意味がなさそう？
      topImg: require('@/assets/image/about-posh/about_posh_top.png'),
      img2: require('@/assets/image/about-posh/about_posh_2.png')
    }
  },
  computed: {
    showAds: function () {
      return !location.href.includes('https://poshspace.jp')
    },
  },
  methods: {
    $_openInquiryForm: function () {
      openInquiryForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-about-posh {
  // control map size
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.bk-white-container {
  background-color: #fff;
  padding: 0 18px;
}
.bk-gray-container {
  background-color: #F5F5F5;
  padding: 0 18px;
}
.bk-blue-container {
  background-color: #12B6D4;
  padding: 0 18px 2rem 18px;
}
.bk-white-sub-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
}
.base-text {
  color: #707070;
  font-size: 14px;
  line-height: 2;
}
.small-text {
  color: #707070;
  font-size: 8px;
  line-height: 2;
}
.blue-text {
  color: #12B6D4;
  font-weight: bold;
}
.center {
  text-align: center;
}
.center-image {
  margin: 0 auto;
}
.yellow-marker {
  background:linear-gradient(transparent 50%, #ff6 30%)
}
.title {
  font-size: 22px;
  line-height: 2;
  font-weight: bold;
  color: #707070;
  padding: 1rem 0;
}
#inquiry-button {
  font-size: 16px;
  margin-bottom: 1rem;
  width: 100%;
  min-height: 24px;
  padding: 1.5vh;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
</style>