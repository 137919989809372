import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import localForage from 'localforage'
// import createPersistedState from 'vuex-persistedstate'
import { initialState } from '@/store/initialState'
import header from '@/store/modules/header'
import loadingMask from '@/store/modules/loadingMask'
import map from '@/store/modules/map'
import modal from '@/store/modules/modal'
import place from '@/store/modules/place'
import filter from '@/store/modules/filter'
import news from '@/store/modules/news'
import user from '@/store/modules/user'

Vue.use(Vuex)

// localStorageだと容量が足りなくなりそうなのでIndexedDBを利用
// FIXME:
// 本対応は「The quote has been exceeded.」エラーが発生したため対応
// あるべき状態はローカルにプレイスデータを全て持つのではなく、必要に応じて
// 必要なプレイスデータをロードする仕組みが必要
const vuexLocal = new VuexPersist({
  storage: localForage,
  asyncStorage: true,
})

export default new Vuex.Store({
  state: initialState,
  getters: {
    bodyContentsName: (state) => {
      return state.bodyContentsName
    },
    accessCount: (state) => {
      return state.accessCount
    },
    lastAccessDate: (state) => {
      return state.lastAccessDate
    },
    bookmarkedPlaceIds: (state) => {
      return state.bookmarkedPlaceIds
    },
    currentListPage:(state) => {
      return state.currentListPage
    },
    /* Calender */
    selectedDates: (state) => {
      return state.selectedDates
    },
    /* Common */
    scrollPosition: (state) => {
      return state.scrollPosition
    },
    distanceFilterDirection: (state) => {
      return state.distanceFilterDirection
    },
    likeFilterDirection: (state) => {
      return state.likeFilterDirection
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, initialState)
    },
    setBodyContentsName: (state, value) => {
      state.bodyContentsName = value
    },
    set_access_count: (state, value) => {
      state.accessCount = value
    },
    set_last_access_date: (state, value) => {
      state.lastAccessDate = value
    },
    set_bookmarked_place_ids(state, value) {
      state.bookmarkedPlaceIds = value
    },
    set_current_list_page:(state, value) => {
      state.currentListPage = value
    },
    /* Calender */
    set_selected_date(state, value) {
      state.selectedDates = value.sort((a, b) => {
        const dateA = new Date(a)
        const dateB = new Date(b)
        return (dateA > dateB ? 1 : -1)
      })
    },
    /* Common */
    set_scroll_position(state, value) {
      state.scrollPosition = value
    },
    set_distance_filter_direction(state, value) {
      state.distanceFilterDirection = value
    },
    set_like_filter_direction(state, value) {
      state.likeFilterDirection = value
    },
  },
  actions: {
    reset(context, value) {
      context.commit('reset')
      // 各モジュールリセット
      context.dispatch('header/resetState')
      context.dispatch('loadingMask/resetState')
      context.dispatch('map/resetState')
      context.dispatch('modal/resetState')
      context.dispatch('place/resetState')
      context.dispatch('filter/resetState')
      context.dispatch('news/resetState')
      context.dispatch('user/resetState')
    },
    setBodyContentsName: (context, value) => {
      context.commit('setBodyContentsName', value)
    },
    set_access_count: (context, value) => {
      context.commit('set_access_count', value)
    },
    set_last_access_date: (context, value) => {
      context.commit('set_last_access_date', value)
    },
    set_bookmarked_place_ids(context, value) {
      context.commit('set_bookmarked_place_ids', value)
    },
    set_current_list_page:(context, value) => {
      context.commit('set_current_list_page', value)
    },
    /* Calender */
    set_selected_date(context, value) {
      context.commit('set_selected_date', value)
    },
    /* Commmon */
    set_scroll_position(context, value) {
      context.commit('set_scroll_position', value)
    },
    set_distance_filter_direction(context, value) {
      context.commit('set_distance_filter_direction', value)
    },
    set_like_filter_direction(context, value) {
      context.commit('set_like_filter_direction', value)
    },
  },
  modules: {
    header,
    loadingMask,
    map,
    modal,
    place,
    filter,
    news,
    user
  },
  plugins: [vuexLocal.plugin], 
  // plugins: [createPersistedState()]
})
