import { storage, ref, getDownloadURL } from '@/firebase/config'
import { getDistanceFromCurrentLocation, isMatchedKeyword } from '@/helper/user'
import store from '@/store/index.js'
import { isSameDate } from '@/helper/util'
import moment from 'moment'

/**
 * 各フィルタ関数
 * 個別に使いたい場合は export する
 */
const $_isMatchKeyword = (place, keyword) => {
  return isMatchedKeyword(place, keyword)
}
const $_isMatchLikesCount = (place, likesCount) => {
  return place.likes >= likesCount
}
const $_isMatchReviewCount = (place, reviewCount) => {
  return place.review >= reviewCount
}
const $_isMatchDistance = (place, from, distance) => {
  const dist = getDistanceFromCurrentLocation(from, place)
  return dist <= distance
}
const $_isMatchPrefecture = (place, prefecture) => {
  return place.pref == prefecture
}
const $_isMatchHeldDate = (place, heldDates) => {
  if (place.type === 'space') alert ('it is not event obj.')

  const dates = $_parseCsvData(place.heldDates)
  const convertedTargetDates = heldDates.map(d => new Date(d))

  return dates
    .map(d => new Date(d))
    .some(
      d => (convertedTargetDates.some(cd => isSameDate(cd, d)))
    )
}

// 適切なネーミングをしてここから参照させろ
const $_parseCsvData = (data) => {
  // 2種類のダブルクォーテーションと改行が交じることへの対処(CSVデータがソースのため)
  return data.replace(/”/g, '').replace(/"/g, '').replace(/\n/g, '').split(",")
}

/**
 * 設定された検索条件毎にプレイスのフィルタリングコールバック取得
 * @param {Object} conditions store/filter の conditions と同形式
 * @return {Array.<Function>} コールバック
 */
export const getFilterCallBacks = (conditions) => {
  const callbacks = []

  if (conditions.keyword) {
    callbacks.push(
      (place) => $_isMatchKeyword(place, conditions.keyword)
    )
  }

  if (conditions.likesCount > 0) {
    callbacks.push(
      (place) => $_isMatchLikesCount(place, conditions.likesCount)
    )
  }

  // イベントのみの場合、クチコミ数チェックはスキップ
  if (
    store.getters['filter/isOnlySpaceSelected'] &&
    conditions.reviewCount > 0
  ) {
    callbacks.push(
      (place) => $_isMatchReviewCount(place, conditions.reviewCount)
    )
  }

  if (conditions.distance) {
    // 基点を取得(current location or map center)
    const from = store.getters['map/currentLocation'] || this.mapCenter
    callbacks.push(
      (place) => $_isMatchDistance(place, from, conditions.distance)
    )
  }

  if (conditions.prefecture) {
    callbacks.push(
      (place) => $_isMatchPrefecture(place, conditions.prefecture)
    )
  }

  // スペースのみの場合、開催日チェックはスキップ
  if (
    store.getters['filter/isOnlyEventSelected'] &&
    (conditions.heldDates.length)
   ) {
    callbacks.push(
      (place) => $_isMatchHeldDate(place, conditions.heldDates)
    )
  }
  return callbacks
}


/**
 * サムネイル取得
 * @param {String} placeId 
 * @returns 
 */
export const getThumbnail = async (placeType, placeId) => {
  try {
    const imageRef = ref(storage, `${placeType}_images/${placeId}/thumbnail_1.png`)
    return await getDownloadURL(imageRef)
  } catch (error) {
    // alert(`会場画像サムネイルの取得に失敗しました。${error.message}`)
    // console.log(`failed to get single thumbnail @getSpaceImages.${JSON.stringify(error)}`)
    // file-loader, url-loaderを介さないのでモジュールを渡す必要がある
    // return [require('@/assets/image/alt_thumb.png')]
    return require('@/assets/image/place_default.png')
  }
}

/**
 * スペースサムネイル取得(現在未使用)
 */
export const getSpaceImages = async (space) => {
  try {
    const existImages = getSpaceCardImages(space)
    const result = await Promise.all(existImages.map(async (img, i) => {
      const imageRef = ref(storage, `space_images/${space.placeId}/thumbnail_${i + 1}.png`)
      return await getDownloadURL(imageRef)
    }))
    return result
  } catch (error) {
    // alert(`会場画像の取得に失敗しました。${error.message}`)
    // console.log(`failed to get single thumbnail @getSpaceImages.${JSON.stringify(error)}`)
    // file-loader, url-loaderを介さないのでモジュールを渡す必要がある
    return [require('@/assets/image/alt_thumb.png')]
  }
}

/**
 * スペースオブジェクトから画像データを抽出(現在未使用)
 * @param {Object} data 
 * @returns 
 */
export const getSpaceCardImages = data => {
  // 外観、会場写真１、２、３の順で最初に見つかったイメージを全て返す
  return [
    data.exteriorImage,
    data.heldPlace1,
    data.heldPlace2,
    data.heldPlace3
  ].filter(img => img)
}

/**
 * イベントの掲載終了日超過チェック
 * @param {String} endOfPublicationDate 
 * @return {Boolean}
 */
export const expiredDisplayDate = endOfPublicationDate => {
  if (!endOfPublicationDate) return false

  // 掲載終了チェック
  const today = moment().hour(0).minute(0).seconds(0).toDate()
  const endDate = moment(
    endOfPublicationDate.replace(/”/g, '').replace(/"/g, '')
  ).hour(0).minute(0).seconds(0).toDate()
  //差日を求める（86,400,000ミリ秒＝１日）
  const termDay = (today - endDate) / 86400000;
  return termDay >= 1
}