<template>
  <div class="app-user-others-item-label" @click="$_clickLabel">
    <div class="label-wrapper" :style="{ height: `${height}px` }">
      <div class="title">{{ title }}</div>
      <div class="link"><IconLinkArrow /></div>
    </div>
  </div>
</template>

<script>
import IconLinkArrow from '@/assets/image/icon_reservation_link_arrow.svg?component'

export default {
  name: 'AppUserOthersItemLabel',
  components: {
    IconLinkArrow
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isRedirectLabel: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 72
    },
  },
  methods: {
    $_clickLabel: function () {
      this.isRedirectLabel
        ? this.$emit('card-redirect-click')
        : this.$emit('card-link-click')
    }
  },
}
</script>

<style lang="scss" scoped>
.app-user-others-item-label {
  background-color: #fff;
  .label-wrapper {
    padding: 8px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #C6C6C6;
    height: 72px;
    .title {
      color: #707070;
      font-size: 16px;
    }
    .link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>