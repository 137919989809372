import { initialState } from '@/store/initialState'
import { 
  storage,
  uploadBytesResumable,
  ref,
  auth,
  signOut,
  getDownloadURL
} from '@/firebase/config'
import { getDistance } from '@/helper/map'
import { putLikes } from '@/helper/firestore/likes'
import { updateLocalChunk } from '@/helper/common'
import { KANA_MAP } from '@/constants/common'
import { DEFAULT_POSITION } from '@/constants/user'
import store from '@/store/index.js'

export const getExt = fileName => {
  const position = fileName.lastIndexOf('.')
  return position < 0 ? '' : fileName.slice(position + 1)
}

export const uploadDocImages = async (shopId, name, file) => {
  try {
    const ext = getExt(file.name)
    const metadata = { contentType: file.type }
    const storageRef = ref(storage, `documents/${shopId}/${name}.${ext}`)
//    const uploadTask = uploadBytesResumable(storageRef, file, metadata)
    const uploadTask = await uploadBytesResumable(storageRef, file, metadata)
    const downloadUrl = await getDownloadURL(uploadTask.ref)
    return downloadUrl
  } catch (error) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    let message
    switch (error.code) {
      case 'storage/unauthorized':
        message = 'アクセス権限がありません'
        break
      case 'storage/canceled':
        message = 'アップロードがキャンセルになりました'
        break
      case 'storage/unknown':
        message = '不明なエラーが発生しました'
        console.log("Unknown error occurred, inspect error.serverResponse")
        break
      default:
        message = error.message || ''
    }
    alert(`書類画像のアップロードに失敗しました。${message}`)
  }
}

export const uploadSalesReportImages = async (reportId, name, file) => {
  try {
    const ext = getExt(file.name)
    const metadata = { contentType: file.type }
    const storageRef = ref(storage, `sales_reports/${reportId}/${name}`)
    const uploadTask = await uploadBytesResumable(storageRef, file, metadata)
    const downloadUrl = await getDownloadURL(uploadTask.ref)
    return downloadUrl
  } catch (error) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    let message
    switch (error.code) {
      case 'storage/unauthorized':
        message = 'アクセス権限がありません'
        break
      case 'storage/canceled':
        message = 'アップロードがキャンセルになりました'
        break
      case 'storage/unknown':
        message = '不明なエラーが発生しました'
        console.log("Unknown error occurred, inspect error.serverResponse")
        break
      default:
        message = error.message || ''
    }
    alert(`書類画像のアップロードに失敗しました。${message}`)
  }
}
export const resizeDocImage = (src, width) => {
  return new Promise((resolve, reject) => {
    ///===画像をリサイズ -> canvasへDraw -> blobe形式への変換処理（fileからではなくpreviewImageの画像を取得して使用する）
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const targetImg = new Image();
    targetImg.crossOrigin = 'Anonymous'
    targetImg.onload  = function(event) {
      //横の長さをwidthとして指定し、縦横比を維持した縮小後の高さを計算
      const ratio = width / this.width
      const dstWidth = width
      const dstHeight = this.height * ratio
      canvas.width = dstWidth
      canvas.height = dstHeight
      ctx.drawImage(this, 0, 0, this.width, this.height, 0, 0, dstWidth, dstHeight)
      resolve(canvas.toDataURL())
    }
    targetImg.onerror = (error) => {
      alert('画像のリサイズに失敗しました')
      reject(error)
    }
    targetImg.src = src
  })
}

export const getDistanceFromCurrentLocation = (currentLocation, destination) => {
  if (!currentLocation || !destination) return 0

  try {
    return getDistance(
      {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      },
      {
        latitude: destination.coords.lat,
        longitude: destination.coords.lng,
      }
    )
  } catch(error) {
    // alert(error)
  }
}

export const isTargetType = (target, showSpace, showEvent) => {
  const targetTypes = []
  if (showSpace) targetTypes.push('space')
  if (showEvent) targetTypes.push('event')
  return targetTypes.includes(target.type)
}

export const isMatchedKeyword = (target, keyword) => {
  const keywords = keyword
    .split(/\s/)
    .filter(k => k)
    .map(k => {
      const hankaku = hankaku2Zenkaku(k)
      const hankana = zenkana2Hankana(hankaku)
      return hankana.toLowerCase()
    })
  return (
    !keyword || // キーワードがなければtrue
    target.pref.includes(keyword) ||
    target.area.includes(keyword) ||
    (target.type === 'space' && keywords.every(k => target.spaceName.toLowerCase().includes(k))) ||
    (target.type === 'event' && keywords.every(k => target.eventName.toLowerCase().includes(k)))
  )
}

const hankaku2Zenkaku = str => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
 })
 .replace(/[‐－―]/g, '-') // ハイフンなど
 .replace(/[～〜]/g, '~') // チルダ
}

const zenkana2Hankana = str => {
  const reg = new RegExp('(' + Object.keys(KANA_MAP).join('|') + ')', 'g');
  return str
    .replace(reg, match => KANA_MAP[match])
    .replace(/゛/g, 'ﾞ')
    .replace(/゜/g, 'ﾟ');
}

export const isInBounds = (target, bounds) => {
  if (!bounds || !target) return false
  const coords = target.coords

  return (
    bounds.north >= target.coords.lat &&
    bounds.south <= target.coords.lat &&
    bounds.east >= target.coords.lng &&
    bounds.west <= target.coords.lng
  )
}

export const updateLikes = async chunk => {
  try {
    if (!chunk.placeId) throw new Error('Space ID is not provided.')
    if (!auth.currentUser.uid) throw new Error('User ID is not provided.')

    // 変更前にuser.likesに含まれている   -> 削除(-1)
    // 変更前にuser.likesに含まれていない -> 追加(1)
    const likedIds = store.getters['user/likes'] || []
    const uid = auth.currentUser.uid
    let count
    let newLikedIds
    if (likedIds.includes(chunk.placeId)) {
      count = -1
      newLikedIds = likedIds.filter(b => b !== chunk.placeId)
    } else {
      count = 1
      newLikedIds = [...likedIds, chunk.placeId]
    }

    // リモートを更新
    await putLikes(chunk, count, uid)
    // ストアのお気に入りID配列を更新
    store.dispatch('user/replaceLikes', newLikedIds)
    // ローカルChunkデータを更新
    await updateLocalChunk(
      chunk.placeId,
      {
        ...chunk,
        likes: Math.max(0, chunk.likes + count)
      }
    )
  } catch (e) {
    throw new Error(`user.updateLikes => ${e}`)
  }
}

export const distanceSort = (a, b) => {
  if (!a.coords || !b.coords) return 0
  const currentLocation = store.getters['map/currentLocation']
  const from = currentLocation || DEFAULT_POSITION
  const distA = getDistanceFromCurrentLocation(from, a)
  const distB = getDistanceFromCurrentLocation(from, b)
  return distA - distB
}