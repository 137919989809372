<template>
  <div class="app-user-header">
    <div class="left-arrow-wrapper"
      v-on:click="() => { this.$emit('left-arrow-click') }"
    >
      <IconLeftArrow />
    </div>
    <img
      :src="logoSrc"
      width="150"
      v-on:click="() => { this.$emit('logo-click') }"
    />
  </div>
</template>

<script>
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'

export default {
  name: 'AppUserHeader',
  components: {
    IconLeftArrow
  },
  data() {
    return {
      logoSrc: require('@/assets/image/logo/logo-mobile.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-user-header {
  padding: 0 1rem;
  width: 100%;
  text-align: center;
  .left-arrow-wrapper {
    position: absolute;
    left: 24px;
    top: 16px;
    display: flex;
    height: 36px;
    width: 60px;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
