var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"space-detail",attrs:{"modal-class":"common-modal","header-class":"'space-detail-modal-header'","dialog-class":"common-modal-dialog","content-class":"space-detail-modal-content","body-class":"common-modal-body","hide-header-close":"","hide-backdrop":"","hide-footer":"","scrollable":"","no-fade":""},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('Header',{on:{"left-arrow-click":() => {
        if (_vm.modalHistory && _vm.modalHistory[0] === 'list') {
          _vm.$store.dispatch('modal/setModalId', 'list')
        } else if (_vm.modalHistory && _vm.modalHistory[0] === 'likes') {
          _vm.$store.dispatch('modal/setModalId', 'likes')
        } else {
          _vm.$store.dispatch('modal/setModalId', 'map')
        }
        close()
      },"logo-click":() => {
        if (_vm.modalHistory && _vm.modalHistory[0] === 'list') {
          _vm.$store.dispatch('modal/setModalId', 'list')
        } else if (_vm.modalHistory && _vm.modalHistory[0] === 'likes') {
          _vm.$store.dispatch('modal/setModalId', 'likes')
        } else {
          _vm.$store.dispatch('modal/setModalId', 'map')
        }
        close()
      }}})]}}])},[(_vm.selectedPlace)?_c('SpaceDetail',{attrs:{"target":_vm.selectedPlace}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }