<template>
  <div class="app-user-header">
    <IconLeftArrow
      id="return-arrow"
      :style="{ visibility: showBackArrow ? 'visible' : 'hidden'}"
      v-on:click="$_goBack"
    />

    <div 
      class="header-inner"
      :style="{
        justifyContent: centeringLogo ? 'center' : 'flex-start',
      }"
    >
      <div class="top-bar">
        <LogoIcon
          class="logo"
          @click="() => {
            $store.dispatch('modal/setModalId', 'map')
          }"
        />
      </div>

      <input
        v-if="showKeywordInput"
        class="keyword-input"
        type="text"
        placeholder="スペース名、地名など"
        v-model="keyword"
        @input="$_setSearchKeyword"
      />

      <div
        v-if="showSwitchButton"
        v-on:click="$_switchDisplayStyle"
        id="display-change-button"
      >
        <component :is="isMapPage ? 'IconList' : 'IconMap'" />
      </div>

      <div
        v-if="showFilterBadge"
        class="sort-button-wrapper"
      >
        <AppUserHeaderSortButtons :buttonProps="buttonProps" />
      </div>
    </div>
  </div>
</template>

<script>
import AppUserHeaderSortButtons from '@/components/molecules/user/AppUserHeaderSortButtons'
import { TOP_PAGE_PATH } from '@/constants/user'
import LogoIcon from '@/assets/image/logo/logo_icon.svg?component'
import IconLeftArrow from '@/assets/image/icon_left_arrow.svg?component'
import IconList from '@/assets/image/icon_list.svg?component'
import IconMap from '@/assets/image/icon_map.svg?component'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserHeader',
  components: {
    AppUserHeaderSortButtons,
    LogoIcon,
    IconList,
    IconMap,
    IconLeftArrow
  },
  data() {
    return {
      isLogined: !!this.user,
      keyword: '',
      buttonProps: [],
      a: false
    }
  },
  computed: {
    ...mapGetters(
      'header',
      [
        'showBackArrow',
        'showKeywordInput',
        'showSwitchButton',
        'showFilterBadge',
        'centeringLogo'
      ]
    ),
    ...mapGetters('modal', [ 'modalId' ]),
    ...mapGetters('filter', { storeKeyword: 'keyword' }),
    user: function () {
      return this.$store.getters['user/user']
    },
    logoClass: function () {
      return this.isLogined ? 'logined-logo' : 'login-page-logo'
    },
    isMapPage: function () {
      return this.modalId === 'map'
    }
  },
  watch: {
    /**
     * 1. watching login state on Vuex and show/hide ui.
     */
    user(val, old) {
      const logedIn = !!val
      this.isLogined = logedIn
    },
    $route: async function(to, from) {
      this.$_setHeaderState()
    },
    storeKeyword() {
      this.keyword = this.storeKeyword
    },
    showSpace() {
      this.buttonProps = this.$_getFilterChipData()
    },
    showEvent() {
      this.buttonProps = this.$_getFilterChipData()
    },
    distanceFilterDirection() {
      this.buttonProps = this.$_getFilterChipData()
    },
    likeFilterDirection() {
      this.buttonProps = this.$_getFilterChipData()
    },
  },
  mounted: function () {
    // ストアに検索キーワードがあれば設定
    this.keyword = this.storeKeyword
    // Chipステータス設定
    this.buttonProps = this.$_getFilterChipData()
    // Headerステータス更新
    this.$_setHeaderState()
  },
  methods: {
    $_setHeaderState() {
      if (location.pathname === TOP_PAGE_PATH) {
        this.$store.dispatch('header/setShowBackArrow', false)
        this.$store.dispatch('header/setShowKeywordInput', true)
        this.$store.dispatch('header/setShowSwitchButton', true)
        this.$store.dispatch('header/setShowFilterBadge', false)
        this.$store.dispatch('header/setCenteringLogo', false)
      }
    },
    /**
     * 戻るボタン処理
     */
    $_goBack: function () {
      if (
        this.$route.path.includes('bookmark') ||
        this.$route.path.includes('news') ||
        this.$route.path.includes('account')
      ) {
        this.$router.push(TOP_PAGE_PATH)
      } else {
        this.$router.go(-1)
      }
    },
    /**
     * マップ/リストページの切り替え処理
     */
    $_switchDisplayStyle: function () {
      if (this.modalId === 'list') {
        this.$store.dispatch('modal/setModalId', 'map') 
      } else {
        this.$store.dispatch('modal/setModalId', 'list')
      }
    },
    /**
     * 検索文字入力時の処理
     */
    $_setSearchKeyword() {
      // キーワードなしの場合は '' を設定（constants/userの初期値参照）
      this.$store.dispatch('filter/setConditions', { keyword: this.keyword || '' })
    },
    $_getFilterChipData() {
      return [
        {
          title: 'スペース',
          color: '#fff',
          bkColor: '#12B6D4',
          borderColor: '#12B6D4',
          turnOn: this.showSpace,
          onClick: () =>{
            this.$store.dispatch('set_show_space', !this.showSpace)
          }
        },
        {
          title: 'イベント',
          color: '#fff',
          bkColor: '#95BB00',
          borderColor: '#95BB00',
          turnOn: this.showEvent,
          onClick: () =>{
            this.$store.dispatch('set_show_event', !this.showEvent)
          }
        },
        {
          title: '現在地',
          color: '#fff',
          bkColor: '#707070',
          borderColor: '#707070',
          withArrow: true,
          turnOn: (
            this.distanceFilterDirection === 'up'||
            this.distanceFilterDirection === 'down'
          ),
          sortDirection: this.distanceFilterDirection,
          onClick: () =>{
            if (this.distanceFilterDirection === 'off') {
              this.$store.dispatch('set_distance_filter_direction', 'down')
            } else if(this.distanceFilterDirection === 'down') {
              this.$store.dispatch('set_distance_filter_direction', 'up')
            } else {
              this.$store.dispatch('set_distance_filter_direction', 'off')
            }
          }
        },
        {
          title: 'お気に入り数',
          color: '#707070',
          bkColor: '#fff',
          borderColor: '#707070',
          withArrow: true,
          sortDirection: this.likeFilterDirection,
          onClick: () =>{
            if(this.likeFilterDirection === 'off') {
              this.$store.dispatch('set_like_filter_direction', 'down')
            } else if(this.likeFilterDirection === 'down') {
              this.$store.dispatch('set_like_filter_direction', 'up')
            } else {
              this.$store.dispatch('set_like_filter_direction', 'off')
            }
          }
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.app-user-header {
  height: 100%;
  width: 100%;
  .header-inner {
    display: flex;
    align-items: center;
    margin: 0 4px;
    height: 100%;
    .top-bar {
      display: contents;
      .logo {
        flex-shrink: 0;
        margin: 8px;
      }
    }
    .keyword-input {
      flex-grow: 1;
      margin: 12px 0;
      border-radius: 8px;
      border: solid 1px lightgray;
      padding: 12px 8px;
      font-size: 14px;
    }
    #display-change-button {
      margin: 0.75rem;
      border-radius: 50%;
      background-color: white;
      color: #0097b5;
      border: 1px solid #a0a0a0;
      display: inline-flex;
      padding: 0.5rem;
    }
    .sort-button-wrapper {
      margin: 0 0.75rem 8px 0.75rem;
    }
  }
  #return-arrow {
    position: absolute;
    top: 30px;
    left: 8px;
  }
}



</style>
