<template>
  <b-modal
    ref="failed-get-location"
    centered 
    :hide-header-close="true"
    :dialog-class="'failed-get-location-modal-dialog'"
    :content-class="'failed-get-location-modal-content'"
    :header-class="'failed-get-location-modal-header'"
    :body-class="'failed-get-location-modal-body'"
    :footer-class="'failed-get-location-modal-footer'"
    @close="() => $store.dispatch('modal/setModalId', 'map')"
  >
    <template #modal-header="{ close }">
      <IconModalCloseButton @click="close()" />
    </template>

    <IconOrientationMark />
    <div class="failed-get-location-title">
      位置情報の利用が<br />
      許可されていません
    </div>
    <div class="failed-get-location-text">
      位置情報を利用するためには<br />
      以下の設定方法に従い、<br />
      位置情報の利用を許可してください。
    </div>

    <template #modal-footer="{ close }">
      <button
        class="failed-get-location-modal-button"
        @click="() => { $emit('change-button-clicke') }"
      >
        設定方法を確認する
      </button>
      <button
        class="failed-get-location-modal-close-button"
        @click="close()"
      >
        あとで行う
      </button>
    </template>
  </b-modal>
</template>
  
<script>
import IconModalCloseButton from '@/assets/image/icon_modal_close_button.svg?component'
import IconOrientationMark from '@/assets/image/icon_orientation_mark.svg?component'

export default {
  name: 'AppUserFailedGetLocationModal',
  components: {
    IconModalCloseButton,
    IconOrientationMark
  },
  methods: {
    showModal() {
      this.$refs['failed-get-location'].show()
    },
    hideModal() {
      this.$refs['failed-get-location'].hide()
    },
  }
}
</script>
  
<style lang="scss">
.failed-get-location-modal-dialog {
  margin: 0.5rem !important;
}
.failed-get-location-modal-content {
  width: 85%;
  margin: 0 auto;
  padding: 56px 0;
  border-radius: 8px !important;
}
.failed-get-location-modal-header {
  position: relative;
  padding: 0 !important;
  height: 0 !important;
  svg {
    position: absolute;
    top: -72px;
    right: -12px;
  }
}
.failed-get-location-modal-body {
  text-align: center;
  padding-top: 0 !important;
}
.failed-get-location-modal-footer {
  padding: 0 !important;
  justify-content: center !important;
  border: none !important;
}
.failed-get-location-modal-button {
  width: 95%;
  min-height: 24px;
  padding: 1.5vh;
  margin: 8px 0;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
.failed-get-location-modal-close-button {
  width: 95%;
  min-height: 24px;
  padding: 1.5vh;
  margin: 8px 0;
  border-radius: 5px;
  border: none;
  background-color: #c6c6c6;
  color: white;
}
.failed-get-location-title {
  margin: 16px 0;
  line-height: 1.75;
  font-size: 18px;
  color: #707070;
}
.failed-get-location-text {
  margin-bottom: 16px;
  line-height: 1.75;
  font-size: 14px;
  color: #707070;
}
</style>