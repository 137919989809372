import { db, doc, updateDoc } from '@/firebase/config'
import common from './common'
import { CHUNK_DOCS_NAME } from '@/constants/firestore'

/**
 * Chunksコレクションの戻り値を１次配列に変換
 * @param {Array} data chunkドキュメント
 * @returns {Array.<Object>}
 */
const convertChunk = data => {
  return data
    .reduce((ac, cr) => {
      // ChunkID
      const docId = cr.docId

      // count, docIdを除去
      const value = Object.entries(cr)
      .filter(v => (
        typeof v[1] === 'object' && v[0] !== 'updateAt'
      ))

      // 各要素に PlaceID、ChunkID を埋め込む
      return ac
        .concat(...value.map(v => {
          v[1].placeId = v[0]
          v[1].docId = docId
          return v[1]
        }))
    }, [])
}

/**
 * 特定ドキュメント取得
 * @param {String} id 
 * @return {Array.<Object>} chunk配列
 */
const getChunk = async id => {
  try {
    const chunkDoc = await common.get(CHUNK_DOCS_NAME, id)
    const chunkArray = convertChunk([chunkDoc])
    // chunkのcreateAtがブランクになる場合があるので、取得時に補間
    // FIXME: FirestoreルールでcreateAtの書き込みを矯正する
    for (let i = 0; i < chunkArray.length; i++) {
      if (!chunkArray[i].createAt) {
        await common.interpolateCreateAt(chunkArray[i])
        // for Firestore limitation
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    }

    return chunkArray
  } catch (e) {
    throw new Error(`getChunk => ${e}`)
  }
}

/**
 * chunksコレクションの全ドキュメント取得
 * @return {Array.<Object>} chunk配列
 */
const getAllChunks = async () => {
  try {
    const chunkDocs = await common.get(CHUNK_DOCS_NAME)
    const chunkArray = convertChunk(chunkDocs)
    // chunkのcreateAtがブランクになる場合があるので、取得時に補間
    // FIXME: FirestoreルールでcreateAtの書き込みを矯正する
    for (let i = 0; i < chunkArray.length; i++) {
      if (!chunkArray[i].createAt) {
        await common.interpolateCreateAt(chunkArray[i])
        // for Firestore limitation
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    }

    return chunkArray
  } catch (e) {
    throw new Error(`getAllChunks => ${e}`)
  }
}

/**
 * 書き込み可能なChunkドキュメント（count < 50）のIDを取得
 * @return {Object.<String>} 書き込み可能なChunkドキュメント
 */
export const getAvailableChunkDocId = async () => {
  try {
    const ids = await common.getAvailableChunkDocIds()
    return ids[0]
  } catch (e) {
    throw new Error(`getAvailableChunkDocId => ${e}`)
  }
}

/**
 * Space/Event -> Chunk形式に変換
 * @param {Object} value スペース/イベントデータ
 * @return {Object} Chunkデータ
 */
const extractChunkData = (value) => {
  let result
  if (value.type === 'space') {  // constantに切れ
    result = {
      placeId: value.placeId,
      address: value.address,
      area: value.area,
      coords: value.coordinates,
      likes: value.likes,
      pref: value.prefecture,
      publish: value.public,
      spaceName: value.spaceName,
      type: 'space',  // constantに切れ
      addedBy: value.addedBy || 'admin',
    }
  } else if (value.type === 'event') {  // constantに切れ
    result = {
      placeId: value.placeId,
      address: value.address ,
      area: value.area ,
      coordinates: value.coordinates,
      endOfPublicationDate: value.endOfPublicationDate,
      eventName: value.eventName,
      heldDates: value.heldDates,
      likes: value.likes,
      pref: value.prefecture,
      publish: value.public,
      spaceName: value.spaceName,
      type: 'event',  // constantに切れ
      addedBy: value.addedBy || 'admin',
    }
  }

  // 1. POST時は登録日時、更新日時がないので、戻り値に含めてリモートに書き込みすると書き込み値エラーになる
  // 値がある場合のみ戻り値に含める
  // 2. createAt, updateAt(Timestamp)をそのまま渡すとマップとしてドキュメントに書き込まれるためDate型に変換
  if (value.createAt) result.createAt = new Date(value.createAt.toMillis())
  if (value.updateAt) result.updateAt = new Date(value.updateAt.toMillis())

  return result
}

// 対象プレイスのchunkフィールドを更新
const putChunk = async (chunkId, placeId, value) => {
  try {
    if (!chunkId || !value) throw new Error('invalid variable')
    const docRef = doc(db, CHUNK_DOCS_NAME, chunkId)
    // => placeIdが無ければ、chunkドキュメント全体を更新する
    if (placeId) {
      await updateDoc(docRef, { [placeId]: value })
    } else {
      await updateDoc(docRef, value)
    }
  } catch (e) {
    throw new Error(`putChunk => ${e}`)
  }
}

export  {
  getAllChunks,
  getChunk,
  putChunk,
  extractChunkData
}