<template>
  <div class="location-setting">
    <!-- Header -->
    <div class="account-list-title">
      <div class="account-list-title-text">位置情報の利用許可</div>
    </div>

    <!-- iPhone -->
    <div v-if="os === 'iOS'" :style="{padding: '20px'}">
      <div class="location-setting-item">
        iPhoneの場合
      </div>
      <!-- Images -->
      <div
        :style="{ display: 'flex', flexWrap: 'wrap' }"
      >
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/iOS_1.png" :style="{ width: '100%', padding: '8px' }" />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ①ご利用端末の「設定」アプリから「プライバシー」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/iOS_2.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ②「位置情報サービス」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/iOS_3.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ③「位置情報サービス」をオンにします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/iOS_4.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ④続けて、下部のリストの中からご利用中のブラウザをタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/iOS_5.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑤「位置情報の利用を許可」の中から「次回、または共有時に確認」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/iOS_6.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑥Poshspaceのマップ画面に戻り、画面右下の方位ボタンをタップし、次に表示されるポップアップで位置情報の利用を許可すると、設定が完了します。
          </p>
        </div>
      </div>
    </div>

    <!-- Android -->
    <div v-else-if="os === 'Android'" :style="{padding: '20px'}">
      <div class="location-setting-item">
        Android(Chrome)の場合
        <br />機種により登録方法が異なります。一例を掲載しますので、ご参照ください。<br />
      </div>
      <!-- Images -->
      <div
        :style="{ display: 'flex', flexWrap: 'wrap' }"
      >
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_1.png" :style="{ width: '100%', padding: '8px' }" />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ①右上の３点ボタンをタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_2.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ②「設定」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_3.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ③「サイトの設定」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_4.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ④「位置情報」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_5.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑤「位置情報」をタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_6.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑥リストの中から使用中のブラウザをタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_7.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑦「ブロック」の中からPoshspaceをタップします。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_8.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑧「許可する」を選択します。
          </p>
        </div>
        <div :style="{ width: '50%', textAlign: 'center' }">
          <img src="@/assets/image/location-setting/location_setting_android_9.png" :style="{ width: '100%', padding: '8px' }"  />
          <p :style="{ wordBreak: 'break-all', textAlign: 'left', padding: '0 8px', color: '#707070' }">
            ⑨Poshspaceのマップ画面に戻り、画面右下の方位ボタンをタップし、次に表示されるポップアップで位置情報の利用を許可すると、設定が完了します。
          </p>
        </div>
      </div>
    </div>

    <div :style="{padding: '20px'}">
      <button id="submit-button" @click="$store.dispatch('modal/setModalId', 'map')">
        トップへ
      </button>
    </div>
  </div>
</template>

<script>
import { getPageFlameState } from '@/helper/common'

export default {
  name: 'AppUserLocationSetting',
  data() {
    return {
      os: 'iOS' // default
    }
  },
  mounted: async function() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      this.os = 'Android'
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      this.os = 'iOS'
    }
  },
}
</script>

<style lang="scss" scoped>
.location-setting {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
/*
 * Header
 */
.account-list-title {
  display: flex;
  justify-content: flex-start;
  padding: 62px 20px 16px 20px;
  box-shadow: 0 4px 6px -4px rgba(0,0,0,0.6) inset;
}
.account-list-title-icon {
  margin-top: 12px;
  margin-right: 16px;
}
.account-list-title-text {
  color: #707070;
  font-size: 20px;
}
.location-setting-title {
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  margin: 16px 0;
}
.location-setting-title-text {
  color: #707070;
  font-size: 20px;
}
.location-setting-item {
  font-size: 16px;
  color: #707070;
  margin-bottom: 16px;
  line-height: 2;
}
.location-setting-item-title-sub {
  font-size: 13px;
  color: #A5A5A5;
  margin: 32px 0 12px 0;
}
#submit-button {
  font-size: 16px;
  width: 100%;
  min-height: 24px;
  padding: 1.5vh;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
</style>