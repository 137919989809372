<template>
  <b-modal
    ref="add-to-homescreen-modal"
    centered 
    :header-class="'add-to-homescreen-modal-header'"
    :content-class="'add-to-homescreen-modal-contents'"
    :body-class="'add-to-homescreen-modal-body'"
    :footer-class="'add-to-homescreen-modal-footer'"
    :hide-header-close="true"
  >
    <template #modal-header="{}">
      <IconModalCloseButton @click="() => $store.dispatch('modal/setModalId', 'map')" />
    </template>

    <AddToHomescreen />

    <template v-slot:modal-footer>
      <button
        id="submit-button"
        @click="() => { $emit('change-button-clicke') }"
      >
        ホーム画面への登録方法はこちら
      </button>
    </template>
  </b-modal>
</template>
  
<script>
import AddToHomescreen from '@/components/organisms/user/modals/contents/AppUserAddToHomescreen'
import IconModalCloseButton from '@/assets/image/icon_modal_close_button.svg?component'

export default {
  name: 'AppUserAddToHomescreenModal',
  components: {
    AddToHomescreen,
    IconModalCloseButton
  },
  methods: {
    showModal() {
      this.$refs['add-to-homescreen-modal'].show()
    },
    hideModal() {
      this.$refs['add-to-homescreen-modal'].hide()
    },
  }
}
</script>
  
<style lang="scss">
.add-to-homescreen-modal-header {
  position: relative;
  padding: 0 !important;
  svg {
    position: absolute;
    top: -16px;
    right: -12px;
  }
}
.add-to-homescreen-modal-body {
  padding: 2rem 1rem;
}
.add-to-homescreen-modal-contents {
  width: 85%;
  margin: 0 auto;
}
.add-to-homescreen-modal-footer {
  justify-content: center !important;
  border: none !important;
}
#submit-button {
  width: 95%;
  min-height: 24px;
  padding: 1.5vh;
  margin-bottom: 2vh;
  border-radius: 5px;
  border: none;
  background-color: #12b6d4;
  color: white;
}
</style>
